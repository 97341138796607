import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { Graphic, LoadingContainer } from '../../../../common/components';
import { Button, CircularProgress, Typography } from '../../../../theme/components';
import FeedbackLayout, { Content, ContentWrapper, Spacer } from '../../../../common/components/FeedbackLayout';
import { useTranslation } from 'react-i18next';
import welcomeimage from '../../../../../assets/images/welcome.svg';
import { useFreshChat } from '../../../../../config/freshChat';

const AddressVerification = () => {
  const { t } = useTranslation();

  const { openFreshChat, initializeFc } = useFreshChat();

  useEffect(() => {
    initializeFc();
  }, []);

  const onButtonClick = () => {
    openFreshChat();
  };

  const { loading } = useSelector((state: IRootState) => state.onboarding);
  return (
    <>
      {loading ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <FeedbackLayout>
          <Spacer />
          <ContentWrapper marginBottom={0}>
            <Graphic image={welcomeimage}></Graphic>
            <Typography variant="h2" gutterBottom>
              {t('onboarding_proof_of_address_intro_title')}
            </Typography>
            <Content>
              <Typography gutterBottom>{t('onboarding_proof_of_address_intro_description')}</Typography>

              <Button onClick={onButtonClick} data-testid={'modal-primary-button'}>
                {t('common_continue')}
              </Button>
            </Content>
          </ContentWrapper>
          <Spacer minHeight={60}></Spacer>
        </FeedbackLayout>
      )}
    </>
  );
};

export default AddressVerification;
