import { apiConfig } from '../../common/api/apiConfig';
import { fetchRequest, HTTPMethods } from '../../common/api/fetchRequest';
import { IErrorResponse } from '../../common/models';
import { IAuthResponse } from '../models/auth.models';

export const register: () => Promise<IAuthResponse | IErrorResponse> = async () => {
  return await fetchRequest(`${apiConfig.endpoints.auth}`, {
    method: HTTPMethods.POST,
    headers: { 'Content-Type': 'application/json' },
  });
};
