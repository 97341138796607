import { TFunction } from 'react-i18next';

// regex below to allow for accented letters, latin extended characters and Latvian characters but will exclude special characters such as !@#+/ (hyphen is included)
const regexString = /[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F \u1e00-\u1eff\u0407\' ]/;
const regexStringWithHyphen = /[^0-9a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F \u1e00-\u1eff\u0407\-\' ]/;

const noSpecialCharsValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  const regex = new RegExp(regexString);
  return runRegex(t, regex, fieldName)(value);
};

export const noSpecialCharsNameValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  const regex = new RegExp(regexStringWithHyphen);
  return runRegex(t, regex, fieldName)(value);
};

const runRegex = (t: TFunction<'translation'>, regex: RegExp, fieldName?: string) => (value: any) => {
  let error;
  if (regex.test(value)) {
    error = fieldName
      ? t('common_error_no_special_char_dynamic', { field: fieldName })
      : t('personal_details_error_no_special_chars');
  }
  return error;
};
export default noSpecialCharsValidator;
