import {
  CountryCode,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js/mobile';

export const mapCountryToCode = (countryCode: string) => {
  return getCountryCallingCode(countryCode as CountryCode);
};

export const parseNumber = (phoneNumber: string) => {
  return parsePhoneNumber(phoneNumber);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return isValidPhoneNumber(phoneNumber);
};

export const validateNumberLength = (phoneNumber: string) => {
  return validatePhoneNumberLength(phoneNumber);
};
