import React, { useEffect, useRef } from 'react';
import Button from '../../../../../../../theme/components/Button';
import { IGoogleSigninProps, IGoogleSigninResponse } from './types';
import { getGoogleClientId, GOOGLE_LOGIN_SCOPES } from '../../../../../../../common/constants';
import useLoadGoogleScript from './hooks/useLoadGoogleScript';
import GoogleIcon from '../../../../../../../theme/icons/GoogleIcon';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRudderAnalytics } from '../../../../../../../../config/rudderstack';

const useStyles = makeStyles(() => ({
  googleIcon: {
    height: 20,
    width: 20,
    marginRight: 8,
  },
}));

const GoogleSignInButton = ({ onSuccess, onError, isDisabled, isLoading }: IGoogleSigninProps) => {
  const clientRef = useRef<any>();
  const rudderAnalytics = useRudderAnalytics();
  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);
  const { t } = useTranslation();
  const { googleIcon } = useStyles();

  const [isScriptLoaded, isScriptLoadError] = useLoadGoogleScript();

  useEffect(() => {
    if (!isScriptLoaded) return;
    const GIS_CLIENT_ID = getGoogleClientId();
    clientRef.current = window.google?.accounts.oauth2.initCodeClient({
      client_id: GIS_CLIENT_ID,
      scope: GOOGLE_LOGIN_SCOPES,
      callback: (response: IGoogleSigninResponse) => {
        if (response.error) return onErrorRef.current?.(response);
        onSuccessRef.current?.(response as any);
      },
    });
  }, [isScriptLoaded]);

  const onClickHandler = () => {
    rudderAnalytics && rudderAnalytics.track('onboarding_web_social_signup_google_clicked');
    clientRef?.current?.requestCode?.();
  };

  return (
    <Button
      loading={isLoading}
      type="button"
      disabled={!isScriptLoaded || isScriptLoadError || isDisabled}
      variant="primary"
      fullWidth={true}
      onClick={onClickHandler}
    >
      <GoogleIcon className={googleIcon} /> {t('email_google_button')}
    </Button>
  );
};

export default GoogleSignInButton;
