import styled from '@emotion/styled';

interface Props {
  contentText: string;
  isMobile: boolean;
}

/*
   long selectors below to override certain items in the sdk UI
 */
const OnfidoMount = styled.div<Props>`
  position: relative;
  width: ${(props: Props) => (props.isMobile ? '100%' : 'unset')};
  height: 100%;
  button.onfido-sdk-ui-Theme-link {
    color: #ff3c64;
  }
  a.onfido-sdk-ui-Theme-link {
    color: #ff3c64;
  }
  button.onfido-sdk-ui-Theme-textButton {
    color: #ff3c64;
  }
  .onfido-sdk-ui-Modal-inner {
    min-height: ${(props: Props) => (props.isMobile ? '0px' : '650px')};
    border-radius: 12px;
    border: ${(props: Props) => (props.isMobile ? '0px' : '0.5px solid #d5d5d5')};
  }
  > div
    > div
    > div.onfido-sdk-ui-Theme-content.onfido-sdk-ui-Theme-scrollableContent
    > div
    > ul
    > li:nth-of-type(1)
    > button
    > div.onfido-sdk-ui-DocumentSelector-content
    > div
    > div {
    visibility: hidden;
    position: relative;
    &:after {
      content: '${(props: Props) => (props.contentText ? props.contentText : 'Fastest option')}';
      visibility: visible;
      color: #5fcd6f;
      font-size: 14px;
      position: absolute;
      left: 0;
    }
  }
`;

export { OnfidoMount };
