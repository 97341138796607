import React, { useEffect } from 'react';

type CookiePolicyCtx = {
  isUCInitialized: boolean;
  isRudderStackConsentGiven: boolean;
  isUCActionGiven: boolean;
};

export const CookiePolicyContext = React.createContext<CookiePolicyCtx>({
  isUCInitialized: false,
  isRudderStackConsentGiven: false,
  isUCActionGiven: false,
});

const CookiePolicyProvider: React.FC<{
  isUCInitialized: boolean;
  isRudderStackConsentGiven: boolean;
  isUCActionGiven: boolean;
}> = ({ children, isRudderStackConsentGiven, isUCInitialized, isUCActionGiven }) => {
  useEffect(() => {}, []);

  return (
    <CookiePolicyContext.Provider value={{ isRudderStackConsentGiven, isUCActionGiven, isUCInitialized }}>
      {children}
    </CookiePolicyContext.Provider>
  );
};

export default CookiePolicyProvider;
