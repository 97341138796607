import React from 'react';
import MuiAppBar, { AppBarProps } from '@material-ui/core/AppBar';
import { Toolbar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 101,
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      height: 76,
      paddingRight: theme.spacing(3),
    },
  },
}));

function AppBar({ children, ...props }: AppBarProps) {
  const { toolbar } = useStyles();
  return (
    <MuiAppBar color="transparent" {...props}>
      <Toolbar className={toolbar}>{children}</Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
