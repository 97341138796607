import * as Sentry from '@sentry/react';

export interface IAgreementOptions {
  name: string;
  type: string;
  options: string[];
}

export interface IAgreementsList {
  [key: string]: IAgreementOptions;
}

export const agreementOptions: IAgreementsList = {
  bux_client_agreement: {
    name: 'bux_zero_client_agreement',
    type: 'BUX_CLIENT_AGREEMENT',
    options: [
      'client_agreement_option_1',
      'client_agreement_option_2',
      'client_agreement_option_3',
      'client_agreement_option_4',
    ],
  },
  aacb_client_agreement: {
    name: 'aacb_client_agreement',
    type: 'AACB_CLIENT_AGREEMENT',
    options: ['client_agreement_option_5'],
  },
  pre_contractual_information: {
    name: 'pre_contractual_information',
    type: 'PRE_CONTRACTUAL_INFORMATION',
    options: ['client_agreement_option_5'],
  },
};

export const logError = (error: Error, file: string) => {
  console.error('Failed to load PDF', file);
  Sentry.captureException(error);
};
