import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const FormIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <g transform={`translate(${translateValue},${translateValue})`}>
      <path
        d="M4.75 4.5C4.75 3.5335 5.5335 2.75 6.5 2.75H17.5C18.4665 2.75 19.25 3.5335 19.25 4.5V19.5C19.25 20.4665 18.4665 21.25 17.5 21.25H6.5C5.5335 21.25 4.75 20.4665 4.75 19.5V4.5Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path d="M8.19048 6.98334H15.8095" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.40005 10.3333H16" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    </g>
  );
};

export default FormIcon;
