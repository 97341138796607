import styled from '@emotion/styled';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { FC, ReactNode, useLayoutEffect, useState } from 'react';

interface ILayout {
  minHeight: string;
}

const Layout = styled.main<ILayout>`
  display: grid;
  width: 100%;
  min-height: ${(props) => props.minHeight};
  grid-template-rows: 101px auto 30px;
  grid-template-columns: 100%;
  ${({ theme }) => `@media (max-width: ${theme.breakpoint.sm}px)`} {
    grid-template-rows: 76px auto 24px;
  }
`;

interface IGridLayout {
  children: ReactNode;
}

export const GridLayout: FC<IGridLayout> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [minHeight, setMinHeight] = useState<string>('100vh');

  useLayoutEffect(() => {
    if (isMobile) {
      const height = window.innerHeight;
      setMinHeight(`${height}px`);
    }
  }, [isMobile]);

  return <Layout minHeight={minHeight}>{children}</Layout>;
};
