import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import {
  ITransactionsStepOption,
  ITransactionsStepPayload,
  ITransactionValues,
  OnboardingSteps,
} from '../../../models/onboarding.models';
import { submitFormDataForOnboardingStep } from '../../../store';
import TransactionStepForm from './TransactionStepForm';
import * as Sentry from '@sentry/react';
import { LoadingContainer } from '../../../../common/components';
import { CircularProgress } from '../../../../theme/components';

const TransactionStep = () => {
  const dispatch = useDispatch();
  const [currentTIN, setCurrentTIN] = useState<ITransactionsStepOption | null>(null);
  const [identityNumberType, setIdentityNumberType] = useState<string>('');

  const {
    currentStep: { payload },
    error,
    loading: isLoading,
  } = useSelector((state: IRootState) => state.onboarding);

  const transactionsStepPayload = payload as ITransactionsStepPayload;

  useEffect(() => {
    if (!isLoading) {
      try {
        const skippedValues = transactionsStepPayload.skippedIdentityNumberTypes || [];
        const currentOptions = transactionsStepPayload._config?.options || [];
        if (Array.isArray(currentOptions)) {
          const remainingOptions = currentOptions.filter((option) => !skippedValues.includes(option.type));
          if (remainingOptions && remainingOptions.length > 0) {
            const firstOption = remainingOptions[0];
            setCurrentTIN(firstOption);
            setIdentityNumberType(firstOption.type);
          }
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [transactionsStepPayload]);

  const onSubmit = useCallback((value: ITransactionValues) => {
    dispatch(
      submitFormDataForOnboardingStep({
        href: OnboardingSteps.TRANSACTION_REPORTING.href,
        payload: value,
      }),
    );
  }, []);
  const onSkip = useCallback((value: ITransactionValues) => {
    const payloadSkippedValues = transactionsStepPayload.skippedIdentityNumberTypes || [];
    const skippedValues = [...Array.from(new Set([...payloadSkippedValues, value.identityNumberType]))];
    dispatch(
      submitFormDataForOnboardingStep({
        href: OnboardingSteps.TRANSACTION_REPORTING.href,
        payload: {
          skippedIdentityNumberTypes: skippedValues,
        },
      }),
    );
  }, []);
  const initialValues: ITransactionValues = {
    identityNumberType: identityNumberType,
    identityNumber: '',
  };
  return (
    <>
      {isLoading ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        currentTIN && (
          <TransactionStepForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onSkip={onSkip}
            error={error}
            isLoading={isLoading}
            transactionStepPayload={currentTIN}
          />
        )
      )}
    </>
  );
};

export { TransactionStep };
