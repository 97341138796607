import React, { FC, memo } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { group2, group4 } from '../animation-timeline-helper';

interface ITextBoxProps {
  text: string;
  isActive: boolean;
  isJustCompleted: boolean;
  xCoord: number;
  yCoord: number;
  index: number;
}

const fadeIn = keyframes`
  from {
   opacity: 0;
  }
  to {
   opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
   opacity: 1;
  }
  to {
   opacity: 0;
  }
`;

type CustomTextStylingType = {
  isActive: boolean;
  isJustCompleted: boolean;
  isFirstCircle: boolean;
};

const AnimatedCustomText = styled.text<CustomTextStylingType>`
  font-size: 12px;
  opacity: ${(props) => (props.isActive ? 0 : props.isJustCompleted ? 1 : 0)};
  animation: ${(props) =>
    props.isActive
      ? css`
          ${fadeIn} ${group4.length}s ${props.isFirstCircle ? '0s' : `${group4.delay}s`} ease-in forwards
        `
      : props.isJustCompleted
      ? css`
          ${fadeOut} ${group2.length}s ${group2.delay}s ease-out forwards
        `
      : undefined};
`;

const TextBox: FC<ITextBoxProps> = ({ xCoord, yCoord, text, index, isJustCompleted, isActive }) => {
  return (
    <AnimatedCustomText
      x={xCoord}
      y={yCoord}
      textAnchor="middle"
      dominantBaseline="middle"
      isJustCompleted={isJustCompleted}
      isActive={isActive}
      isFirstCircle={index === 0}
    >
      {text}
    </AnimatedCustomText>
  );
};

export default memo(TextBox);
