import { TabsClassKey, StyleRules } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiTabs: Partial<StyleRules<TabsClassKey, {}>> = {
  indicator: {
    display: 'none',
  },
};

export default MuiTabs;
