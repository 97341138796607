import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootState } from '../../../../../Store';
import { IAgreementData, ISignedAgreementsPayload, OnboardingSteps } from '../../../models/onboarding.models';
import { LoadingContainer } from '../../../../common/components';
import { Button, CircularProgress, Typography } from '../../../../theme/components';
import { Content, Spacer } from '../../../../common/components/FeedbackLayout';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AgreementBox from './AgreementBox';
import FormLayout, { ButtonsContainer } from '../../../../common/components/FormLayout';
import { createGuestAccount, submitFormDataForOnboardingStep } from '../../../store';
import { transformURLtoLocalURL } from './static';

export interface IAgreementsState extends IAgreementData {
  index: number;
}

const SignedAgreementStep = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [agreements, setAgreements] = useState<IAgreementsState[]>([]);
  const [agreementsRemaining, setAgreementsRemaining] = useState(true);

  const {
    currentStep: { payload },
    error,
    loading: isLoading,
  } = useSelector((state: IRootState) => state.onboarding);

  const signedAgreementsStepPayload = payload as ISignedAgreementsPayload;

  useEffect(() => {
    if (signedAgreementsStepPayload && signedAgreementsStepPayload.agreements) {
      const agreementsPayload = signedAgreementsStepPayload.agreements;
      const indexedAgreements = agreementsPayload.map((a, index) => {
        return {
          ...a,
          localUrl: transformURLtoLocalURL(a.url),
          index,
        };
      });
      setAgreements(indexedAgreements);
    }
  }, [signedAgreementsStepPayload]);

  useEffect(() => {
    const agreementsRemaining = agreements.length > 0 && agreements.filter((a) => !a.accepted);
    if (agreementsRemaining && agreementsRemaining.length < 1) {
      setAgreementsRemaining(false);
    }
  }, [agreements]);

  const markAgreementAsAccepted = (agreement: IAgreementsState) => {
    const acceptedAgreement = { ...agreement, accepted: true };
    const previousState = agreements.filter((ag) => ag.type !== agreement.type);
    const sortedState = [...previousState, acceptedAgreement].sort(
      (a: IAgreementsState, b: IAgreementsState) => a.index - b.index,
    );
    setAgreements(sortedState);
  };

  const finaliseAgreements = () => {
    const agreementsPayload: IAgreementData[] = agreements.map((a) => {
      return {
        type: a.type,
        url: a.url,
        accepted: a.accepted,
      };
    });

    if (!agreementsRemaining) {
      dispatch(
        submitFormDataForOnboardingStep({
          href: OnboardingSteps.SIGNED_AGREEMENTS.href,
          payload: {
            agreements: agreementsPayload,
          },
        }),
      );
    }
    dispatch(createGuestAccount());
  };

  return (
    <>
      {isLoading ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <FormLayout>
          <Typography variant="h2" gutterBottom>
            {t('signed_agreements_title')}
          </Typography>
          {error && (
            <>
              <Typography color="error" variant="subtitle2">
                {t('common_error_sorry_there_is_a_problem')}
              </Typography>
              <Typography color="error" variant="caption" component="p" gutterBottom>
                {t('common_error_please_try_again')}
              </Typography>
            </>
          )}
          <Content maxWidth={'500px'}>
            <Typography gutterBottom>{t('signed_agreements_desc')}</Typography>
            {agreements.map((agreement) => (
              <AgreementBox
                key={agreement.type}
                agreement={agreement}
                markAgreementAsAccepted={markAgreementAsAccepted}
              />
            ))}
            <Typography variant={'body2'} gutterBottom>
              {t('signed_agreements_note')}
            </Typography>
            <ButtonsContainer alignCenter={true}>
              <Button onClick={finaliseAgreements} data-testid={'step-continue-button'} disabled={agreementsRemaining}>
                {t('common_continue')}
              </Button>
            </ButtonsContainer>
            <Spacer minHeight={100}>
              <Typography variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
                {t('common_have_questions')} <ChatWithSupport />
              </Typography>
            </Spacer>
          </Content>
        </FormLayout>
      )}
    </>
  );
};

export default SignedAgreementStep;
