import React from 'react';
import { IEmailCapturePayload } from '../../../models/onboarding.models';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { EmailSocialCaptureStep } from './feature-flag/social-login/EmailSocialCaptureStep';
import { EmailOnlyStep } from './feature-flag/email-only/EmailOnlyStep';

export const EmailCaptureStep: React.FC = () => {
  const {
    currentStep: { payload },
  } = useSelector((state: IRootState) => state.onboarding);

  const emailPayload = payload as IEmailCapturePayload;

  return <>{emailPayload?._config?.socialNetworkSignUpUrl ? <EmailSocialCaptureStep /> : <EmailOnlyStep />}</>;
};
