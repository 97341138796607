import React, { FC, useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '../../../../theme/icons';
import Typography from '@material-ui/core/Typography';
import { DialogContent, makeStyles } from '@material-ui/core';
import { Button } from '../../../../theme/components';
import { ButtonsContainer } from '../../../../common/components/FormLayout';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import AgreeButtonSlider from './AgreeButtonSlider';
import { agreementOptions } from './config';
import PdfViewer from './PdfViewer';
import { useTranslation } from 'react-i18next';
import { IAgreementsState } from './SignedAgreementStep';

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
    overflowY: 'hidden',
  },
  modalContent: {
    background: '#F4F2F2',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .react-pdf': {
      '&__Document': {
        textAlign: 'center',
      },

      '&__Page': {
        marginBottom: '1rem',

        '&__canvas': {
          maxWidth: '100%',
          height: 'auto !important',
          margin: 'auto',
        },
      },
    },
  },
  dialogTitleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D6D5D5',
    padding: '17px 22px',
  },
  actionContainer: {
    justifyContent: 'center',
    borderTop: '1px solid #D6D5D5',
    minHeight: '100px',
  },
}));

interface IAgreementDocDialog {
  agreement: IAgreementsState;
  openAgreement: boolean;
  closeAgreement: () => void;
  agreeAgreement: () => void;
  agreementTitle: string;
}

const AgreementDocDialog: FC<IAgreementDocDialog> = ({
  openAgreement,
  agreement,
  closeAgreement,
  agreeAgreement,
  agreementTitle,
}) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [showAgreeButton, setShowAgreeButton] = useState(false);
  const [showAgreementContainer, setShowAgreementContainer] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { dialogContent, modalContent, dialogTitleBar, actionContainer } = useStyles();

  useEffect(() => {
    if (modalRef && modalRef.current) {
      setWindowHeight(modalRef.current.clientHeight);
    }
  }, [modalRef.current, showAgreementContainer]);

  const handleAgree = () => {
    setShowAgreementContainer(true);
  };

  return (
    <>
      <Dialog
        open={openAgreement}
        onClose={closeAgreement}
        aria-labelledby="agreement-dialog"
        aria-describedby="View Agreements"
        data-testid={'agreement-modal-dialog'}
        fullScreen={true}
      >
        <div className={dialogTitleBar}>
          <Typography variant="h6">{t(agreementTitle)}</Typography>
          <IconButton aria-label="close" onClick={closeAgreement}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={dialogContent} ref={modalRef}>
          <div className={modalContent}>
            <PdfViewer
              height={windowHeight}
              showAgreeButton={setShowAgreeButton}
              file={agreement.localUrl || agreement.url}
            />
          </div>
        </DialogContent>
        <DialogActions className={actionContainer}>
          {agreement.accepted ? (
            <ButtonsContainer alignCenter={true}>
              <Button onClick={closeAgreement}>{t('common_continue')}</Button>
            </ButtonsContainer>
          ) : showAgreeButton ? (
            <>
              {showAgreementContainer ? (
                <AgreeButtonSlider
                  handleContinue={agreeAgreement}
                  agreementOptions={agreementOptions[agreement.type.toLowerCase()]}
                />
              ) : (
                <ButtonsContainer alignCenter={true}>
                  <Button onClick={handleAgree} data-testid={'agreement-agree-button'}>
                    {t('signed_agreements_agree_action')}
                  </Button>
                </ButtonsContainer>
              )}
            </>
          ) : (
            <Typography variant="h6">{t('signed_agreements_scroll_action')}</Typography>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AgreementDocDialog;
