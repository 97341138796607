import { langCodes } from '../../../config/i18next';

export const getPathWithLocale = (path: string) => {
  const pathWithLocales = `/:lang(${langCodes.join('|')})${path}`;
  return [pathWithLocales, path];
};

export const getArrayPathsWithLocale = (paths: string[]) => {
  const pathsWithLocales = paths.map((path) => {
    return `/(${langCodes.join('|')})${path}`;
  });
  return [...pathsWithLocales, ...paths];
};
