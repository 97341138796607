import React, { Suspense, lazy } from 'react';
import { IFlag } from './Flag';
import { makeStyles } from '@material-ui/core';

const Flag = lazy(() => import('./Flag'));

const useStyles = makeStyles((theme) => ({
  lazyPlaceholder: {
    height: 25,
    width: 25,
    background: theme.palette.grey[500],
    borderRadius: 12,
  },
}));

const FlagLazy = ({ nationality }: IFlag) => {
  const { lazyPlaceholder } = useStyles();
  return (
    <Suspense fallback={<div className={lazyPlaceholder} />}>
      <Flag nationality={nationality} />
    </Suspense>
  );
};

export default FlagLazy;
