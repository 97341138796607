import React, { FC, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { debounce } from 'throttle-debounce';
import { Document, pdfjs } from 'react-pdf';
import PageRenderer, { IPassedData } from './PageRenderer';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { logError } from './config';
import { LoadingView } from './LoadingView';
import { CommonError } from '../../../../common/components/errors';

const baseUrl = window.location.origin;
pdfjs.GlobalWorkerOptions.workerSrc = `${baseUrl}/pdf.worker.min.js`;

interface IPdfViewer {
  height: number;
  showAgreeButton: (value: boolean) => void;
  file: string;
}

const PdfViewer: FC<IPdfViewer> = ({ height, showAgreeButton, file }) => {
  const listRef = React.createRef<any>();
  const canvasRef = useRef<HTMLDivElement>(null);

  const initialScale = 1.2;
  const [isMounted, setIsmounted] = useState(false);

  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(window.innerWidth);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const prevCurrentPage = useRef<number>(1);

  const [cachedPageDimensions, setCachedPageDimensions] = useState<null | Map<any, any>>(null);
  const prevCachedPageDimensions = useRef<null | Map<any, any>>();

  const [responsiveScale, setResponsiveScale] = useState<number>(1);
  const prevResponsiveScale = useRef<number>(1);

  const [pageNumbers] = useState<Map<any, any>>(new Map());
  const prevPageNumbers = useRef<Map<any, any>>();

  const pages = useRef<WeakMap<any, any>>(new WeakMap());

  const _callResizeHandler = debounce(50, handleResize);
  const _callOrientationChangeHandler = debounce(50, handleResize);

  useEffect(() => {
    //using refs to maintain the necessary state for the window resize event handlers
    prevCachedPageDimensions.current = cachedPageDimensions;
    prevCurrentPage.current = currentPage;
    prevPageNumbers.current = pageNumbers;
    prevResponsiveScale.current = responsiveScale;
  }, [cachedPageDimensions, currentPage, pageNumbers, responsiveScale]);

  useEffect(() => {
    setIsmounted(true);
    window.addEventListener('resize', _callResizeHandler);
    window.addEventListener('orientationchange', _callOrientationChangeHandler);
    return () => {
      setIsmounted(false);
      window.removeEventListener('resize', _callResizeHandler);
      window.removeEventListener('orientationchange', _callOrientationChangeHandler);
    };
  }, []);

  useEffect(() => {
    recomputeRowHeights();
  }, [responsiveScale]);

  useEffect(() => {
    if (isMounted && pdf) {
      cachePageDimensions(pdf);
    }
  }, [pdf, isMounted]);

  /**
   * Load all pages so we can cache all page dimensions.
   */
  function cachePageDimensions(pdf: PDFDocumentProxy) {
    const promises = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map((pageNumber) => {
      return pdf.getPage(pageNumber);
    });

    // Assuming all pages may have different heights. Otherwise we can just
    // load the first page and use its height for determining all the row
    // heights.
    Promise.all(promises).then((pages) => {
      if (!isMounted) {
        return;
      }

      const pageDimensions = new Map();

      for (const page of pages) {
        const w = page.view[2] * initialScale;
        const h = page.view[3] * initialScale;
        pageDimensions.set(page._pageIndex + 1, [w, h]);
      }
      setCachedPageDimensions(pageDimensions);
      _callResizeHandler();
    });
  }

  function recomputeRowHeights() {
    listRef.current?.resetAfterIndex(0);
  }

  function computeRowHeight(index: number) {
    const cachedPageDimensionsRef = prevCachedPageDimensions.current || cachedPageDimensions;
    const responsiveScaleRef = prevResponsiveScale.current;
    if (cachedPageDimensionsRef && responsiveScaleRef) {
      return cachedPageDimensionsRef.get(index + 1)[1] / responsiveScaleRef;
    }
    return 768; // Initial height
  }

  function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    setPdf(pdf);
    cachePageDimensions(pdf);
  }

  function updateCurrentVisiblePage({ visibleStopIndex }: any) {
    const newCurrentPage = visibleStopIndex + 1;
    const pages = pdf?.numPages;
    if (pages && pages <= 4) {
      if (newCurrentPage > 1) {
        showAgreeButton(true);
      }
    } else {
      if (newCurrentPage > 4) {
        showAgreeButton(true);
      }
    }
    setCurrentPage(newCurrentPage);
  }

  function computeResponsiveScale(pageNumber: number) {
    const node = pages.current.get(pageNumbers.get(pageNumber));
    if (!node || node.clientHeight === 0) {
      return;
    }
    if (!prevCachedPageDimensions.current) return;
    return prevCachedPageDimensions.current.get(pageNumber)[1] / node.clientHeight;
  }

  function handleResize() {
    // Recompute the responsive scale factor on window resize
    const newResponsiveScale = computeResponsiveScale(prevCurrentPage.current);
    if (newResponsiveScale && prevResponsiveScale.current !== newResponsiveScale) {
      setResponsiveScale(newResponsiveScale);
    }
    setContainerWidth(window.innerWidth);
  }

  const handleClick = ({ pageNumber }: { pageNumber: string }) => {
    listRef.current.scrollToItem(pageNumber);
  };

  const itemData: IPassedData = {
    scale: initialScale,
    pages,
    pageNumbers,
    numPages: pdf?.numPages,
    triggerResize: handleResize,
  };

  return (
    <Document
      file={file}
      loading={LoadingView}
      error={<CommonError />}
      onLoadSuccess={onDocumentLoadSuccess}
      inputRef={canvasRef}
      onLoadError={(error) => logError(error, file)}
      onItemClick={handleClick}
    >
      {cachedPageDimensions && prevResponsiveScale.current && (
        <>
          <VariableSizeList
            height={height}
            width={containerWidth}
            itemCount={pdf?.numPages || 1}
            itemSize={computeRowHeight}
            itemData={itemData}
            overscanCount={2}
            onItemsRendered={updateCurrentVisiblePage}
            ref={listRef}
          >
            {PageRenderer}
          </VariableSizeList>
        </>
      )}
    </Document>
  );
};

export default PdfViewer;
