import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const HouseIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <g transform={`translate(${translateValue},${translateValue})`}>
      <path
        d="M9 21H5C4.44772 21 4 20.5523 4 20V10.4538C4 10.1654 4.12448 9.89108 4.3415 9.70119L11.3415 3.57619C11.7185 3.24629 12.2815 3.24629 12.6585 3.57619L19.6585 9.70119C19.8755 9.89108 20 10.1654 20 10.4538V20C20 20.5523 19.5523 21 19 21H15"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15 21V15C15 14.4477 14.5523 14 14 14H10C9.44772 14 9 14.4477 9 15V21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
  );
};

export default HouseIcon;
