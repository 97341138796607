import React, { FC } from 'react';
import { Field, Form, FormikProvider } from 'formik';
import CountryAutocomplete, { Country, FlagAdornment } from '../../../../common/components/CountryAutocomplete';
import { Button, CircularProgress, Typography } from '../../../../theme/components';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { useTranslation } from 'react-i18next';
import { requiredValidator } from '../../../utils/validators';
import { filterUnmappedCountries, mapCountryCode, useFormikWithErrors } from '../../../helpers';
import Error from '../personaldetails/Error';
import { LoadingContainer } from '../../../../common/components';
import { IsubmittableCountryValue } from './TaxNumberStep';
import { IErrorResponse } from '../../../../common/models';

interface ISecondTaxCountryComponent {
  onSubmit: (values: IsubmittableCountryValue) => void;
  handleBackButton: () => void;
  listOfTaxCountries: string[];
  error: IErrorResponse | null;
  isLoading: boolean;
  isInitializing: boolean;
}

export const SelectSecondaryCountry: FC<ISecondTaxCountryComponent> = ({
  onSubmit,
  handleBackButton,
  listOfTaxCountries,
  error,
  isInitializing,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();

  const { touched, errors, values, ...formik } = useFormikWithErrors({
    initialValues: { secondaryTaxCountryValue: null },
    onSubmit,
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  return (
    <>
      {isLoading || isInitializing ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <FormLayout>
          <FormContent marginTop={6}>
            <FormikProvider value={{ touched, errors, values, ...formik }}>
              <Form>
                <FormFieldsWrapper marginTop={3}>
                  {error && <Error />}
                  <Fieldset>
                    <Typography variant="h4" component="legend" align="center">
                      {t('tax_secondary_title')}
                    </Typography>
                    <Field
                      component={CountryAutocomplete}
                      name="secondaryTaxCountryValue"
                      placeholder={t('personal_details_select_country')}
                      countries={listOfTaxCountries
                        .filter(filterUnmappedCountries(i18n))
                        .map(mapCountryCode(t))
                        .sort((a: Country, b: Country) => a.label?.localeCompare(b.label))}
                      validate={requiredValidator(t)}
                      fullWidth={true}
                      TextFieldProps={{
                        label: t('secondary_tax_country_label'),
                        name: 'secondaryTaxCountryValue',
                        error: (touched['secondaryTaxCountryValue'] && !!errors['secondaryTaxCountryValue']) || false,
                        helperText:
                          (touched['secondaryTaxCountryValue'] && errors['secondaryTaxCountryValue']) || false,
                        InputProps: {
                          startAdornment: values['secondaryTaxCountryValue'] ? (
                            <FlagAdornment nationality={values['secondaryTaxCountryValue'].value} />
                          ) : undefined,
                        },
                      }}
                    />
                  </Fieldset>
                </FormFieldsWrapper>
                <ButtonsContainer>
                  <Button variant="secondary" onClick={handleBackButton}>
                    {t('common_go_back')}
                  </Button>
                  <Button type="submit" loading={isLoading}>
                    {t('common_continue')}
                  </Button>
                </ButtonsContainer>
              </Form>
            </FormikProvider>
          </FormContent>
        </FormLayout>
      )}
    </>
  );
};

export default SelectSecondaryCountry;
