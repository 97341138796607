import { IFinanceDetailsPayload, ITopicOptionsConfig } from '../../../models/onboarding.models';
import i18n from '../../../../../config/i18next';

const getYesNoTopicOption = (): ITopicOptionsConfig[] => {
  return [
    {
      id: 'true',
      manual: false,
      displayName: i18n.t('common_yes'),
      displayNameTranslateKey: 'common_yes',
    },
    {
      id: 'false',
      manual: false,
      displayName: i18n.t('common_no'),
      displayNameTranslateKey: 'common_no',
    },
  ];
};

export enum QuestionFormNames {
  ownerOfACompany = 'ownerOfACompany',
  ownerDirectorSector = 'ownerDirectorSector',
  expectedInvestedFunds = 'expectedInvestedFunds',
  sourceOfFunds = 'sourceOfFunds',
  netWorth = 'netWorth',
  understandsLosingFund = 'understandsLosingFunds',
  understandsBalanceChanges = 'understandsBalanceChanges',
  investedInShares = 'investedInShares',
}

export enum QuestionAnswerValues {
  commercial_real_estate = 'commercial-real-estate',
  cash_intensive = 'cash-intensive',
}

const investedInSharesQuestion = {
  formName: QuestionFormNames.investedInShares,
  title: 'questionnaire_invested_in_shares',
  description: '',
  topicOptions: getYesNoTopicOption(),
  isRow: true,
  compact: true,
};
const understandsBalanceChangesQuestion = {
  formName: QuestionFormNames.understandsBalanceChanges,
  title: 'questionnaire_balance_change',
  description: '',
  topicOptions: getYesNoTopicOption(),
  isRow: true,
  compact: true,
};
const understandsLosingFundsQuestion = {
  formName: QuestionFormNames.understandsLosingFund,
  title: 'questionnaire_lose_deposit',
  description: '',
  topicOptions: getYesNoTopicOption(),
  isRow: true,
  compact: true,
};
const netWorthQuestion = (financePayload: IFinanceDetailsPayload) => ({
  formName: QuestionFormNames.netWorth,
  title: 'questionnaire_net_worth',
  description: 'questionnaire_net_worth_desc',
  topicOptions: financePayload._config.topicOptions.NET_WORTH,
  isRow: true,
});
const sourceOfFundsQuestion = (financePayload: IFinanceDetailsPayload) => ({
  formName: QuestionFormNames.sourceOfFunds,
  title: 'questionnaire_source_funds',
  description: 'questionnaire_source_funds_desc',
  topicOptions: financePayload._config.topicOptions.SOURCE_OF_FUNDS,
  isRow: true,
});
const expectedInvestedFundsQuestion = (financePayload: IFinanceDetailsPayload) => ({
  formName: QuestionFormNames.expectedInvestedFunds,
  title: 'questionnaire_expected_investment',
  description: '',
  topicOptions: financePayload._config.topicOptions.EXPECTED_INVESTED_FUNDS,
  isRow: true,
});
const ownerOfACompanyQuestion = {
  formName: QuestionFormNames.ownerOfACompany,
  title: 'questionnaire_owner_company',
  description: 'questionnaire_owner_company_desc',
  topicOptions: getYesNoTopicOption(),
  isRow: true,
  compact: true,
};
const ownerDirectorSectorQuestion = (financePayload: IFinanceDetailsPayload) => ({
  formName: QuestionFormNames.ownerDirectorSector,
  title: 'questionnaire_owner_director_sector',
  description: '',
  topicOptions: financePayload._config.topicOptions.OWNER_DIRECTOR_SECTOR,
});

export interface IQuestion {
  formName: string;
  title: string;
  description: string;
  topicOptions: ITopicOptionsConfig[];
  isRow?: boolean;
  compact?: boolean;
}

export const getListOfQuestions = (financePayload: IFinanceDetailsPayload): IQuestion[] => {
  return [
    investedInSharesQuestion,
    understandsBalanceChangesQuestion,
    understandsLosingFundsQuestion,
    netWorthQuestion(financePayload),
    sourceOfFundsQuestion(financePayload),
    expectedInvestedFundsQuestion(financePayload),
    ownerOfACompanyQuestion,
    ownerDirectorSectorQuestion(financePayload),
  ];
};
