import { TFunction } from 'react-i18next';
import { Field } from 'formik';
import { TextField } from '../../../../theme/components';
import {
  addressBuildingValidator,
  addressCityValidator,
  addressNumberAdditionValidator,
  addressNumberValidator,
  addressPostalCodeValidator,
  addressStreetValidator,
} from '../../../utils/validators';
import React, { useContext } from 'react';
import { AddressValidationContext } from './AddressValidationProvider';
import { addressProvinceValidator } from '../../../utils/validators/addressValidator';

const StreetField = (t: TFunction, customLabel?: string) => {
  const labelKey: any = customLabel ?? 'address_form_street';
  return (
    <Field
      component={TextField}
      name="street"
      id="street"
      label={t(labelKey)}
      placeholder={t(labelKey)}
      validate={addressStreetValidator(t, t(labelKey))}
      fullWidth={true}
    />
  );
};

const AddressNumberField = (t: TFunction, customLabel?: string, customWidth?: boolean) => {
  const fullWidth: boolean = customWidth !== undefined ? customWidth : false;
  const labelKey: any = customLabel ?? 'address_form_number';
  return (
    <Field
      component={TextField}
      name="number"
      id="number"
      label={t(labelKey)}
      placeholder={t(labelKey)}
      validate={addressNumberValidator(t, t(labelKey))}
      fullWidth={fullWidth}
    />
  );
};

const NumberAdditionField = (t: TFunction) => {
  return (
    <Field
      component={TextField}
      name="numberAddition"
      id="numberAddition"
      label={`${t('address_form_number_addition')} (${t('common_optional')})`}
      placeholder={`${t('address_form_number_addition')} (${t('common_optional')})`}
      validate={addressNumberAdditionValidator(t, t('address_form_number_addition'))}
    />
  );
};

const AddressBuildingField = (t: TFunction, customLabel?: string) => {
  const labelKey: any = customLabel ?? 'address_form_building';
  return (
    <Field
      component={TextField}
      name="building"
      id="building"
      label={`${t(labelKey)} (${t('common_optional')})`}
      placeholder={`${t(labelKey)} (${t('common_optional')})`}
      fullWidth={true}
      validate={addressBuildingValidator(t, t(labelKey))}
    />
  );
};

const PostCodeField = (t: TFunction, customLabel?: string, customWidth?: boolean) => {
  const { postcodeExample, postcodeValidationRule } = useContext(AddressValidationContext);
  const labelKey: any = customLabel ?? 'address_form_postal_code';
  const postcodeExampleStr: string = postcodeExample ?? '';
  const fullWidth: boolean = customWidth !== undefined ? customWidth : true;

  return (
    <Field
      component={TextField}
      name="postalCode"
      id="postalCode"
      label={t(labelKey)}
      placeholder={t(labelKey)}
      validate={addressPostalCodeValidator(t, postcodeExampleStr, t(labelKey), postcodeValidationRule)}
      fullWidth={fullWidth}
    />
  );
};

const CityField = (t: TFunction, customLabel?: string, customWidth?: boolean) => {
  const fullWidth: boolean = customWidth !== undefined ? customWidth : true;
  return (
    <Field
      component={TextField}
      name="city"
      id="city"
      label={t('address_form_city')}
      placeholder={t('address_form_city')}
      validate={addressCityValidator(t, t('address_form_city'))}
      fullWidth={fullWidth}
    />
  );
};

const ProvinceField = (t: TFunction) => {
  const { provinceExample, provinceValidationRule } = useContext(AddressValidationContext);
  const provinceExampleStr: string = provinceExample ?? '';
  return (
    <Field
      component={TextField}
      name="province"
      id="province"
      label={t('address_form_province')}
      placeholder={t('address_form_province')}
      validate={addressProvinceValidator(t, provinceExampleStr, provinceValidationRule)}
      fullWidth={true}
    />
  );
};

export {
  StreetField,
  AddressBuildingField,
  AddressNumberField,
  NumberAdditionField,
  CityField,
  PostCodeField,
  ProvinceField,
};
