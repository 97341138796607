import React, { FC, useRef } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { group2 } from '../animation-timeline-helper';

interface ITickIconProps {
  isCompleted: boolean;
  isJustCompleted: boolean;
  xCoord: number;
  yCoord: number;
  width: number;
  height: number;
  translateValue: number;
  id: string;
}

type TickIconStylingType = {
  isCompleted: boolean;
  isJustCompleted: boolean;
  strokeDashOffset: number;
};

const fadeIn = keyframes`
  from {
   opacity: 0;
  }
  to {
   opacity: 1;
  }
`;

const animateTick = (strokeDashOffset: number) => keyframes`
  from {
   stroke-dashoffset: ${strokeDashOffset};
  }
  to {
   stroke-dashoffset: 0;
  }
`;

const AnimatedTickSvg = styled.svg<TickIconStylingType>`
  opacity: ${(props) => (props.isCompleted && !props.isJustCompleted ? 1 : 0)};

  animation: ${(props) =>
    props.isJustCompleted
      ? css`
          ${fadeIn} ${group2.length}s ${group2.delay}s ease-in forwards
        `
      : undefined};
  & path {
    stroke: ${(props) => props.theme.color.amaranth};
    animation: ${(props) =>
      props.isJustCompleted
        ? css`
            ${animateTick(props.strokeDashOffset)} ${group2.length}s ${group2.delay}s ease-in-out forwards;
          `
        : undefined};
  }
`;

const TickIcon: FC<ITickIconProps> = ({
  id,
  xCoord,
  yCoord,
  height,
  width,
  translateValue,
  isCompleted,
  isJustCompleted,
}) => {
  const pathRef = useRef<SVGPathElement>(null);
  let lineLength = 0;
  if (pathRef && pathRef.current) {
    lineLength = pathRef.current.getTotalLength();
    pathRef.current.style.strokeDasharray = lineLength.toString();
    if (isCompleted) {
      pathRef.current.style.strokeDashoffset = '0';
    } else if (isJustCompleted) {
      pathRef.current.style.strokeDashoffset = lineLength.toString();
    }
  }
  return (
    <AnimatedTickSvg
      x={xCoord}
      y={yCoord}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      isCompleted={isCompleted}
      isJustCompleted={isJustCompleted}
      strokeDashOffset={lineLength}
    >
      <g transform={`translate(${translateValue},${translateValue})`}>
        <path
          ref={pathRef}
          id={'tick-small-16'}
          d="M3 8L6.5 11.5L13 5"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </AnimatedTickSvg>
  );
};

export default TickIcon;
