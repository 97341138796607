import React, { useContext, useState } from 'react';
import { Field, Form, FormikProvider } from 'formik';
import { Typography, Button, KeyboardDatePicker } from '../../../../theme/components';
import FormLayout, {
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
  ButtonsContainer,
} from '../../../../common/components/FormLayout';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikWithErrors } from '../../../helpers';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { PersonalDetailsContext } from './PersonalDetailsProvider';
import { Error } from '.';
import { StepComponent } from '../../StepRoute';
import subYears from 'date-fns/subYears';
import { dobValidator } from '../../../utils/validators';

export const DateOfBirthStep: React.FC<StepComponent> = ({ onPrevious, onNext, showPrevious = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { isLoading, error, initialValues, onSubmit } = useContext(PersonalDetailsContext);
  const dateSub18years = subYears(new Date(), 18);
  const [maxDate, setMaxDate] = useState<Date | undefined>(dateSub18years);

  const { touched, errors, setFieldTouched, setErrors, ...formik } = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit((values: any) => onNext(values)),
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
    validate: ({ dateOfBirth }: { dateOfBirth: Date }) => {
      const dobError = dobValidator(t, t('personal_details_dateofbirth'))(dateOfBirth);
      return !dobError ? {} : { dateOfBirth: dobError };
    },
    validateOnChange: false,
  });

  return (
    <FormLayout>
      <FormContent marginTop={6}>
        <FormikProvider value={{ touched, errors, setFieldTouched, setErrors, ...formik }}>
          <Form>
            <FormFieldsWrapper marginTop={3}>
              {error && <Error />}
              <Fieldset>
                <Typography variant="h4" component="legend" align="center">
                  {t('personal_details_dateofbirth_title')}
                </Typography>
                <Field
                  component={KeyboardDatePicker}
                  name="dateOfBirth"
                  id="dateOfBirth"
                  label={t('personal_details_dateofbirth')}
                  placeholder="DD/MM/YYYY"
                  initialFocusedDate={dateSub18years}
                  invalidDateMessage={`${t('email_error_please_use_format')}: DD/MM/YYYY`}
                  maxDate={maxDate}
                  maxDateMessage={t('personal_details_error_18years_or_older')}
                  openTo="year"
                  onOpen={() => setMaxDate(undefined)}
                  onClose={() => setMaxDate(dateSub18years)}
                  onAccept={() => setFieldTouched('dateOfBirth', true, false)}
                  onError={(message: string) => setErrors({ dateOfBirth: message })}
                  fullWidth
                  disableFuture
                />
              </Fieldset>
            </FormFieldsWrapper>
            <ButtonsContainer>
              {showPrevious && (
                <Button variant="secondary" onClick={onPrevious}>
                  {t('common_go_back')}
                </Button>
              )}
              <Button type="submit" loading={isLoading} id="web-onboarding-personal-details">
                {t('common_continue')}
              </Button>
            </ButtonsContainer>
          </Form>
        </FormikProvider>
        <Typography component="p" variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </FormContent>
    </FormLayout>
  );
};

export default DateOfBirthStep;
