import React, { useMemo } from 'react';
import {
  calculateXCoordinate,
  circumference,
  initialStartY,
  radius,
  smallRadius,
  textYSpacing,
} from './progress-bar-ui-helper';
import ProgressLine from './basic-shapes/ProgressLine';
import ProgressCircle from './basic-shapes/ProgressCircle';
import CustomIcon from './basic-shapes/CustomIcon';
import TickIcon from './basic-shapes/TickIcon';
import TextBox from './basic-shapes/TextBox';
import { findPart, IProgressPartConfig } from './progress-config';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { OnboardingSteps } from '../../../onboarding/models/onboarding.models';
import PersonIcon from './icons/PersonIcon';

interface IProgressBarPart {
  progressPartsConfig: IProgressPartConfig[];
  currentStepName: string;
  gap: number;
  index: number;
}

const ProgressBarPart: React.FC<IProgressBarPart> = ({ progressPartsConfig, currentStepName, index, gap }) => {
  const { t } = useTranslation();

  const [totalSubSteps, currentSubStepIndex, currentSubStep] = useMemo(() => {
    const currentCircle = findPart(progressPartsConfig, currentStepName);
    const allPartItems = currentCircle?.items;
    const allPartSteps =
      currentCircle?.items.reduce((allSteps, partSteps) => {
        if (partSteps && partSteps.steps) {
          allSteps.push(...partSteps.steps);
        }
        return allSteps;
      }, [] as Array<{ step: OnboardingSteps }>) || [];
    const totalSubSteps = allPartSteps?.length || 2;

    const currentSubStep = find(allPartItems, { steps: [{ step: { key: currentStepName } }] });
    const currentSubStepIndex = allPartSteps?.findIndex((s) => s.step.toString() === currentStepName);
    return [totalSubSteps, currentSubStepIndex, currentSubStep];
  }, [index, currentStepName]);

  return (
    <g>
      {progressPartsConfig.map((progressStage, key) => {
        const isLastElement = progressPartsConfig.length === key + 1;
        const isJustCompleted = key === index - 1 && currentSubStepIndex === 0;
        const isCompleted = key < index;
        const isActiveElement = key === index;
        const displayName: any = isCompleted
          ? progressStage?.items[progressStage?.items?.length - 1]?.name
          : isActiveElement
          ? (currentSubStep?.name as string)
          : progressStage?.items[currentSubStepIndex]?.name;

        const effectiveRadius = isActiveElement || isJustCompleted ? radius : smallRadius;
        const xStartCircle = calculateXCoordinate(key, gap, index);
        const SVGIconComponent = currentSubStep?.svgIcon || PersonIcon;

        const isBigCircle = isActiveElement || isJustCompleted;
        const lineStart = xStartCircle + effectiveRadius;
        const lineEnd = calculateXCoordinate(key + 1, gap, index) - (isLastElement ? effectiveRadius : 0);
        const id = currentSubStep?.id || '';
        return (
          <React.Fragment key={key}>
            {!isLastElement && (
              <ProgressLine
                x1Coord={lineStart - (isBigCircle ? radius : smallRadius)}
                x2Coord={lineEnd - smallRadius}
                yCoord={initialStartY}
                isCompleted={isCompleted}
                isJustCompleted={isJustCompleted}
                id={id}
              />
            )}
            <ProgressCircle
              xCoord={xStartCircle}
              yCoord={initialStartY}
              smallRadius={smallRadius}
              bigRadius={radius}
              isActive={isActiveElement}
              isCompleted={isCompleted}
              currentSubStep={currentSubStepIndex}
              totalSubSteps={totalSubSteps}
              circumference={circumference}
              isJustCompleted={isJustCompleted}
              index={key}
              id={id}
            />
            <CustomIcon
              id={id}
              xCoord={xStartCircle - effectiveRadius}
              yCoord={initialStartY - effectiveRadius}
              height={effectiveRadius * 2}
              width={effectiveRadius * 2}
              translateValue={radius / 2}
              SvgIcon={SVGIconComponent}
              isActive={isActiveElement}
              index={key}
              isJustCompleted={isJustCompleted}
            />
            {isCompleted && (
              <TickIcon
                id={`${id}-tick`}
                xCoord={xStartCircle - smallRadius}
                yCoord={initialStartY - smallRadius}
                height={smallRadius * 2}
                width={smallRadius * 2}
                translateValue={smallRadius / 2}
                isJustCompleted={isJustCompleted}
                isCompleted={isCompleted}
              />
            )}
            <TextBox
              text={t(displayName)}
              isActive={isActiveElement}
              isJustCompleted={isJustCompleted}
              xCoord={xStartCircle}
              yCoord={initialStartY + textYSpacing}
              index={key}
            />
          </React.Fragment>
        );
      })}
    </g>
  );
};

export default ProgressBarPart;
