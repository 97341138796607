import { Typography } from '@material-ui/core';
import { Graphic } from '..';
import FeedbackLayout, { Spacer, Content, ContentWrapper } from '../FeedbackLayout';
import warningimage from '../../../../assets/images/warning.svg';
import ChatWithSupport from '../ChatWithSupport';
import { useTranslation } from 'react-i18next';

const CommonError = () => {
  const { t } = useTranslation();

  return (
    <FeedbackLayout>
      <Spacer />
      <ContentWrapper>
        <Graphic image={warningimage}></Graphic>
        <Typography variant="h2" gutterBottom>
          {t('errors_common_title')}
        </Typography>
        <Content>
          <Typography>{t('errors_common_please_refresh')}</Typography>
          <Typography gutterBottom>
            {t('errors_common_problem_persists')} <ChatWithSupport />
          </Typography>
        </Content>
      </ContentWrapper>
      <Spacer disableMinHeight />
    </FeedbackLayout>
  );
};

export default CommonError;
