import React from 'react';
import { Typography } from '../../../../../../../theme/components';
import { useTranslation } from 'react-i18next';

const GoogleSignInError = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography color="error" variant="subtitle2">
        {t('common_error_sorry_there_is_a_problem')}
      </Typography>
      <Typography color="error" variant="caption" component="p">
        {t('errors_common_please_refresh')}
      </Typography>
    </>
  );
};

export default GoogleSignInError;
