import { DialogClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiDialog: Partial<StyleRules<DialogClassKey, {}>> = {
  paper: {
    borderRadius: theme.spacing(3),
  },
};

export default MuiDialog;
