import React from 'react';
import { Content, ContentWrapper } from '../../../../common/components/FeedbackLayout';
import { Graphic } from '../../../../common/components';
import almostdoneimage from '../../../../../assets/images/almostDoneWeb.svg';
import { Typography } from '../../../../theme/components';
import { ButtonsContainer } from '../../../../common/components/FormLayout';
import Button from '../../../../theme/components/Button';
import { useTranslation } from 'react-i18next';
import { LINK_TO_APP } from '../../../../common/constants';
import { useRudderAnalytics } from '../../../../../config/rudderstack';

const QRStepMobile = () => {
  const { t } = useTranslation();
  const rudderAnalytics = useRudderAnalytics();

  const openLinkToApp = () => {
    rudderAnalytics && rudderAnalytics.track('appstore_opened');
    window.open(LINK_TO_APP, '_blank');
  };

  return (
    <>
      <ContentWrapper>
        <Graphic image={almostdoneimage} />
        <Typography variant="h2" gutterBottom>
          {t('end_screen_title')}
        </Typography>
        <Content>
          <Typography gutterBottom style={{ marginBottom: 12 }}>
            {t('end_screen_desc_1')}
          </Typography>
          <Typography gutterBottom color="textSecondary">
            {t('end_screen_desc_2')}
          </Typography>
        </Content>
      </ContentWrapper>
      <ButtonsContainer flexDirection="column">
        <Button onClick={openLinkToApp} fullWidth={false} id="web-onboarding-finish-in-app">
          {t('common_finish_in_app_v2')}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default QRStepMobile;
