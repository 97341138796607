import { Box, useTheme, useMediaQuery, makeStyles, BoxProps } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  layout: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
}));

const FeedbackLayout = ({ children }: React.PropsWithChildren<unknown>) => {
  const { layout } = useStyles();
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
      className={layout}
    >
      {children}
    </Box>
  );
};

export const ContentWrapper = ({
  children,
  fullHeight = false,
  marginTop = '0rem',
  ...boxProps
}: React.PropsWithChildren<{ fullHeight?: boolean; marginTop?: string } & BoxProps>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box
      height={!fullHeight && !isMobile ? 'auto' : '100%'}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginTop={marginTop}
      marginBottom={3}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export const Content = ({ children, ...boxProps }: React.PropsWithChildren<unknown & BoxProps>) => {
  return (
    <Box
      display="flex"
      width="100%"
      maxWidth={400}
      margin="0 auto"
      flexDirection="column"
      alignItems="center"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export const Spacer = ({
  children,
  disableMinHeight,
  ...boxProps
}: React.PropsWithChildren<{ disableMinHeight?: boolean } & BoxProps>) => {
  return (
    <Box flex={1} height="100%" display="flex" alignItems="flex-end" {...boxProps}>
      {children}
    </Box>
  );
};

export default FeedbackLayout;
