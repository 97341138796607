import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from './config/i18next';
import { FreshChatProvider } from './config/freshChat';
import './index.css';
import App from './App';
import { getStore } from './Store';
import { ThemeProvider } from './modules/theme';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import './index.css';
import getEnvironment from './modules/onboarding/utils/getEnvironment';
const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://1bb1a9ff11cc46e28219094d4515d3a1@o320614.ingest.sentry.io/6096871',
  integrations: [new BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) })],
  environment: getEnvironment(),
  enabled: getEnvironment() !== 'local' && getEnvironment() !== 'development',
  release: process.env.REACT_APP_VERSION,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: getEnvironment() === 'production' ? 0.4 : 0.1,
});
const store = getStore();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <FreshChatProvider>
          <ThemeProvider>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </FreshChatProvider>
      </I18nextProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
