import { DialogTitleClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiDialogTitle: Partial<StyleRules<DialogTitleClassKey, {}>> = {
  root: {
    margin: 0,
    padding: theme.spacing(6),
    '& .closeButton': {
      position: 'absolute',
      right: theme.spacing(6),
      top: theme.spacing(6),
    },
    '& .closeIcon': {
      color: theme.palette.grey[100],
    },
  },
};

export default MuiDialogTitle;
