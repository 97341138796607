import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

const ClearIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 15.0078C5.72817 15.0078 5.50781 15.2282 5.50781 15.5C5.50781 15.7718 5.72817 15.9922 6 15.9922L14.5 15.9922C14.7718 15.9922 14.9922 15.7718 14.9922 15.5C14.9922 15.2282 14.7718 15.0078 14.5 15.0078L6 15.0078ZM10.7533 12.218L10.7533 4.59375C10.7533 4.32192 10.533 4.10156 10.2611 4.10156C9.98931 4.10156 9.76895 4.32192 9.76895 4.59375L9.76895 12.218L6.43282 8.88187C6.24061 8.68966 5.92897 8.68966 5.73676 8.88187C5.54455 9.07408 5.54455 9.38572 5.73676 9.57793L9.91311 13.7543C10.1053 13.9465 10.417 13.9465 10.6092 13.7543L14.7855 9.57793C14.9777 9.38572 14.9777 9.07408 14.7855 8.88187C14.5933 8.68966 14.2817 8.68966 14.0895 8.88187L10.7533 12.218Z"
      />
    </SvgIcon>
  );
};

export default ClearIcon;
