import React, { useEffect, useState } from 'react';
import { Content, ContentWrapper } from '../../../../common/components/FeedbackLayout';
import { Typography } from '../../../../theme/components';
import { QRCodeContainer, useQRModal } from './QRModal';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import styled from '@emotion/styled';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import { useRudderAnalytics } from '../../../../../config/rudderstack';

const DividerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
`;

const AppStoreImages = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

interface StyledProps {
  image: string | undefined;
}

const ImageContainer = styled.div<StyledProps>`
  height: 80px;
  width: 180px;
  background: url(${(props: StyledProps) => props.image}) no-repeat;
  background-size: cover;
`;

const useStyles = makeStyles((theme) => ({
  qrLink: {
    color: theme.palette.grey[100],
    fontSize: '18px',
    lineHeight: '24px',
    marginTop: '10px',
  },
  boldText: {
    fontWeight: 600,
  },
}));

const QRStepDesktop = () => {
  const { qrLink } = useStyles();
  const { t, i18n } = useTranslation();
  const modal = useQRModal();
  const rudderAnalytics = useRudderAnalytics();
  const [iosImage, setIosImage] = useState(undefined);
  const [androidImage, setAndroidImage] = useState(undefined);

  const toggleModal = () => {
    modal.isOpen ? modal.close() : modal.open();
  };

  useEffect(() => {
    const language = i18n.language ? i18n.language : 'en';
    import(`../../../../../assets/images/app-store-badges/android/${language}_get.svg`).then((img) => {
      setAndroidImage(img.default);
    });
    import(`../../../../../assets/images/app-store-badges/ios/${language}_ios.svg`).then((img) =>
      setIosImage(img.default),
    );
  }, [i18n.language]);

  const sendEvent = (store: string) => {
    rudderAnalytics && rudderAnalytics.track(`${store}_appstore_opened`);
  };

  return (
    <>
      <ContentWrapper>
        <Typography variant="h2" gutterBottom>
          {t('end_screen_title')}
        </Typography>
        <Content>
          <Typography gutterBottom style={{ marginBottom: 12 }}>
            {t('end_screen_desc_1')}
          </Typography>
          <Typography gutterBottom>{t('end_screen_desc_2')}</Typography>
        </Content>
        <Content>
          <AppStoreImages>
            {iosImage && (
              <Link
                href="https://apps.apple.com/app/id1478406784"
                target="_blank"
                rel="noreferrer"
                onClick={() => sendEvent('ios')}
              >
                <ImageContainer image={iosImage} data-testid={'ios-badge'} />
              </Link>
            )}
            {androidImage && (
              <Link
                href="https://play.google.com/store/apps/details?id=com.getbux.android.stocks"
                target="_blank"
                rel="noreferrer"
                onClick={() => sendEvent('android')}
              >
                <ImageContainer image={androidImage} data-testid={'android-badge'} />
              </Link>
            )}
          </AppStoreImages>

          <DividerContainer>
            <Divider style={{ width: '40%', marginRight: '10px', height: '2px' }} />
            <Typography style={{ fontSize: '18px' }}>{t('common_or')}</Typography>
            <Divider style={{ width: '40%', marginLeft: '10px', height: '2px' }} />
          </DividerContainer>
        </Content>
        <QRCodeContainer isModal={false} data-testid={'qr-code'} />
        <Link component="button" onClick={toggleModal} className={qrLink}>
          {t('continue_on_app_qr_how_to')}
        </Link>
      </ContentWrapper>
    </>
  );
};

export default QRStepDesktop;
