import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { findPart, getProgressPartsConfig } from './progress-config';
import AnimateHeight from 'react-animate-height';
import { useMediaQuery } from '@material-ui/core';
import { theme } from '../../../theme';
import { useTranslation } from 'react-i18next';

import { desktopGap, heightOfViewBox, mobileGap, widthOfViewBox } from './progress-bar-ui-helper';
import ProgressBoxContent from './ProgressBoxContent';
import ProgressBarPart from './ProgressBarPart';
import { OnboardingSteps } from '../../../onboarding/models/onboarding.models';

interface IProgressProps {
  currentStep: string;
}

const Layout = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.white};
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  position: relative;
`;

const TitleContainer = styled.div`
  opacity: 0.8;
`;

const Container = styled.div`
  opacity: 0.8;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  ${({ theme }) => `@media (max-width: ${theme.breakpoint.sm}px)`} {
    width: 100%;
  }
`;

const ProgressBox = styled.div`
  height: auto;
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 22px;
  background: ${({ theme }) => theme.color.white};
  padding: 20px;
  ${({ theme }) => `@media (max-width: ${theme.breakpoint.sm}px)`} {
    padding: 12px;
  }
`;

const ProgressBar = ({ currentStep: currentStepName }: IProgressProps) => {
  const progressBoxRef = useRef<HTMLDivElement>(null);

  const [progressParts, currentPart, currentPartIndex] = useMemo(() => {
    const progressParts = getProgressPartsConfig();
    const currentPart = findPart(progressParts, currentStepName);
    const currentPartIndex = progressParts.findIndex((s) => s.partName === currentPart?.partName);
    return [progressParts, currentPart, currentPartIndex];
  }, [currentStepName]);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [showProgressBox, setShowProgressBox] = useState(false);

  let gap = desktopGap;

  useEffect(() => {
    gap = isMobile ? mobileGap : desktopGap;
  }, [isMobile]);

  const { t } = useTranslation();

  const handleClickOutside = (event: any) => {
    if (progressBoxRef.current && !progressBoxRef.current.contains(event.target)) {
      setShowProgressBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Layout ref={progressBoxRef}>
      <TitleContainer>
        {currentPartIndex != undefined && currentPartIndex !== -1 && (
          <div>
            {t('part_x_of_n', { part: currentPartIndex + 1, total: progressParts.length })}
            <span>:</span>
            <span
              style={{
                fontWeight: 600,
                borderBottom: !isMobile ? '1px dashed #888' : '',
                marginLeft: '5px',
                cursor: 'pointer',
              }}
              onMouseOver={() => {
                if (!isMobile) setShowProgressBox(true);
              }}
              onMouseLeave={() => {
                setShowProgressBox(false);
              }}
            >
              {currentPart?.partName && t(currentPart?.partName)}
            </span>
          </div>
        )}
      </TitleContainer>
      <AnimateHeight
        duration={200}
        height={showProgressBox ? 'auto' : 0}
        delay={80}
        style={{
          flexShrink: 0,
          zIndex: 2,
          position: 'absolute',
          borderRadius: '22px',
          width: '100%',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          transition: 'box-shadow 1000ms',
          marginTop: '3px',
        }}
      >
        <ProgressBox>
          <ProgressBoxContent progressParts={progressParts} currentIndex={currentPartIndex} />
        </ProgressBox>
      </AnimateHeight>
      <Container>
        <svg
          viewBox={`0 0 ${widthOfViewBox(gap, progressParts.length || 0)} ${heightOfViewBox}`}
          xmlns="http://www.w3.org/2000/svg"
          role="presentation"
          aria-labelledby="progress_bar_title"
        >
          {currentPartIndex >= 0 && currentStepName !== OnboardingSteps.NOT_STARTED.toString() && (
            <ProgressBarPart
              progressPartsConfig={progressParts}
              gap={gap}
              index={currentPartIndex}
              currentStepName={currentStepName}
            />
          )}
        </svg>
      </Container>
    </Layout>
  );
};

export default ProgressBar;
