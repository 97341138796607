import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

const CheckboxIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="11.5" stroke={theme.palette.primary.main} fillOpacity="0%" />
    </SvgIcon>
  );
};

export default CheckboxIcon;
