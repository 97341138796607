import React, { useCallback, useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { TFunction, useTranslation } from 'react-i18next';
import { mapCountryCode } from '../../../helpers';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { IErrorResponse } from '../../../../common/models';
import { DEV_EMAIL_TO_BYPASS_VERIFICATION } from '../../../../common/constants';
import getEnvironment from '../../../utils/getEnvironment';

const DATE_FORMAT = 'yyyy-MM-dd';

const initialValues = {
  countryOfResidence: null as { label: string; value: string } | null,
  nationality: null as { label: string; value: string } | null,
  secondNationality: null as { label: string; value: string } | null,
  firstName: '',
  lastName: '',
  gender: '' as '' | 'male' | 'female',
  countryOfBirth: null as { label: string; value: string } | null,
  cityOfBirth: '',
  dateOfBirth: null as Date | null,
};

type SubmitableValues = {
  countryOfResidence?: string;
  nationality?: string;
  secondNationality?: string;
  firstName: string;
  lastName: string;
  gender: string;
  countryOfBirth?: string;
  cityOfBirth: string;
  dateOfBirth: string;
  email?: string;
};

type PersonalDetails = typeof initialValues & { email?: string };

type Payload = SubmitableValues & {
  _links: any;
  _config: any;
  [key: string]: any;
};

export const PersonalDetailsContext = React.createContext<{
  isLoading: boolean;
  error: IErrorResponse | null;
  initialValues: PersonalDetails;
  onSubmit: (cb: (values: SubmitableValues) => void) => (values: PersonalDetails) => void;
}>({ initialValues, onSubmit: () => () => {}, isLoading: false, error: null });

const PersonalDetailsProvider: React.FC = ({ children }) => {
  const {
    loading: isLoading,
    error,
    currentStep: { payload },
  } = useSelector((state: IRootState) => state.onboarding);
  const { t } = useTranslation();
  const [state, setState] = useState(initialValues);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _config, _links, ...submittableValues } = payload as Payload;
    setState((s) => ({ ...s, ...submitableValuesToRealValues(submittableValues, t) }));
  }, [payload]);

  const handleSubmit = useCallback(
    (cb: (values: SubmitableValues) => void) => (values: PersonalDetails) => {
      const newState = { ...state, ...values };
      setState(newState);
      cb(getSubmitableValues(newState));
    },
    [state],
  );

  return (
    <PersonalDetailsContext.Provider value={{ isLoading, error, initialValues: state, onSubmit: handleSubmit }}>
      {children}
    </PersonalDetailsContext.Provider>
  );
};

function getSubmitableValues(values: PersonalDetails) {
  const isDev = getEnvironment() === 'development' || getEnvironment() === 'local';
  if (isDev && DEV_EMAIL_TO_BYPASS_VERIFICATION && !values.email) {
    values.email = DEV_EMAIL_TO_BYPASS_VERIFICATION;
  }
  const submitableValues = {
    ...values,
    countryOfResidence: values.countryOfResidence?.value,
    nationality: values.nationality?.value,
    secondNationality: values.secondNationality?.value,
    countryOfBirth: values.countryOfBirth?.value,
    gender: values.gender.toUpperCase(),
    dateOfBirth: values.dateOfBirth ? format(values.dateOfBirth, DATE_FORMAT) : '',
    acceptTerms: true,
  };

  // omit second nationality from object if empty
  if (!submitableValues.secondNationality) {
    delete submitableValues.secondNationality;
  }

  return submitableValues;
}

function submitableValuesToRealValues(values: SubmitableValues, t: TFunction<'translation'>): PersonalDetails {
  return {
    ...values,
    countryOfResidence: values.countryOfResidence ? mapCountryCode(t)(values.countryOfResidence) : null,
    nationality: values.nationality ? mapCountryCode(t)(values.nationality) : null,
    secondNationality: values.secondNationality ? mapCountryCode(t)(values.secondNationality) : null,
    countryOfBirth: values.countryOfBirth ? mapCountryCode(t)(values.countryOfBirth) : null,
    gender: values.gender ? (values.gender.toLowerCase() as '') : '',
    dateOfBirth: values.dateOfBirth ? parse(values.dateOfBirth, DATE_FORMAT, new Date()) : null,
  };
}

export default PersonalDetailsProvider;
