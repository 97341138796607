import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormLayout, {
  Content as FormContent,
  FormFieldsWrapper,
  ButtonsContainer,
} from '../../../../common/components/FormLayout';
import { IErrorResponse } from '../../../../common/models';
import { Button, Typography } from '../../../../theme/components';

interface IAddressStepFormProps<T = any> {
  initialValues: T;
  onSubmit: (values: T) => void;
  onGoBack?: () => void;
  error: IErrorResponse | null;
  isLoading: boolean;
}

const AddressStepForm = ({
  children,
  initialValues,
  onSubmit,
  onGoBack,
  error,
  isLoading,
}: React.PropsWithChildren<IAddressStepFormProps>) => {
  const { t } = useTranslation();

  const formik = useFormik({ initialValues, onSubmit });

  useEffect(() => {
    if (error && !isLoading) {
      formik.setSubmitting(false);
      if (error.body && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key.replace('currentAddress.', '')] = t('common_error_please_check_field');
        });
        formik.setErrors(errors);
      }
    }
  }, [error, isLoading]);

  return (
    <FormLayout>
      <Typography variant="h2" gutterBottom={true}>
        {t('address_form_title')}
      </Typography>
      <FormContent>
        <FormikProvider value={formik}>
          <Form>
            <FormFieldsWrapper>
              {error && (
                <>
                  <Typography color="error" variant="subtitle2">
                    {t('common_error_sorry_there_is_a_problem')}
                  </Typography>
                  <Typography color="error" variant="caption" component="p" gutterBottom>
                    {t('common_error_please_try_again')}
                  </Typography>
                </>
              )}
              {children}
            </FormFieldsWrapper>
            <ButtonsContainer>
              {onGoBack && (
                <Button variant="secondary" onClick={onGoBack}>
                  {t('common_go_back')}
                </Button>
              )}
              <Button type="submit" loading={isLoading} id="web-onboarding-address">
                {t('common_continue')}
              </Button>
            </ButtonsContainer>
          </Form>
        </FormikProvider>
      </FormContent>
    </FormLayout>
  );
};

export default AddressStepForm;
