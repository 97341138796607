import React, { useState, useEffect } from 'react';
import { IErrorResponse } from '../../../../common/models';
import { IPhoneToken } from './PhoneVerificationStep';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Button, Link, TextField, Typography } from '../../../../theme/components';
import { requiredValidator } from '../../../utils/validators';
import { IphoneVerificationPayload } from '../../../models/onboarding.models';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { resendPhoneCodeVerification } from '../../../api/onboarding.api';
import {
  PHONE_VERIFICATION_ERROR,
  PHONE_VERIFICATION_INCORRECT,
  PHONE_VERIFICATION_MAX_EXCEED,
} from '../../../../common/constants';

interface IInputVerificationCodeFormProps {
  initialValues: IPhoneToken;
  onSubmit: (values: IPhoneToken) => void;
  handleBack: () => void;
  error: IErrorResponse | null;
  isLoading: boolean;
  payload: IphoneVerificationPayload;
}

const useStyles = makeStyles(() => ({
  inputStyle: {
    '& input': {
      textAlign: 'center',
    },
  },
  spacedStyles: {
    margin: '2rem 0',
  },
  linkStyle: {
    cursor: 'pointer',
  },
}));

const PhoneVerificationForm = ({
  initialValues,
  onSubmit,
  error,
  handleBack,
  isLoading,
  payload,
}: React.PropsWithChildren<IInputVerificationCodeFormProps>) => {
  const { inputStyle, spacedStyles, linkStyle } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [codeRequestAllowed, setCodeRequestAllowed] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const formik = useFormik({ initialValues, onSubmit });

  const isPhoneEditable = payload && payload._config?.phoneNumberEditable;

  useEffect(() => {
    if (error && error.errorCode) {
      processError(error.errorCode);
    }
  }, []);

  const handleResendCode = async () => {
    if (payload.numberOfSmsLeft && payload.numberOfSmsLeft > 0) {
      setCodeRequestAllowed(false);
      try {
        await resendPhoneCodeVerification();
      } catch (e: IErrorResponse | any) {
        e.errorCode ? processError(e.errorCode) : setErrorMessage(t('phone_verification_error_backend'));
        setShowErrorMessage(true);
      }
      setTimeout(() => {
        setCodeRequestAllowed(true);
        setShowErrorMessage(false);
      }, 10000);
    } else {
      setErrorMessage(t('phone_verification_error_sms_exceeded'));
      setShowErrorMessage(true);
    }
  };

  const processError = (errorCode: string) => {
    switch (errorCode) {
      case PHONE_VERIFICATION_ERROR:
        setErrorMessage(t('phone_verification_error_backend'));
        break;
      case PHONE_VERIFICATION_MAX_EXCEED:
        setErrorMessage(t('phone_verification_error_sms_exceeded'));
        break;
      case PHONE_VERIFICATION_INCORRECT:
        setErrorMessage(t('phone_verification_error_invalid'));
        break;
      default:
        setErrorMessage(t('phone_verification_error_backend'));
    }
  };

  return (
    <>
      <FormLayout>
        <Typography variant="h2" gutterBottom={true}>
          {t('phone_verification_title')}
        </Typography>
        <FormContent>
          <FormikProvider value={formik}>
            <Form>
              <FormFieldsWrapper>
                {(error || showErrorMessage) && (
                  <div>
                    <Typography color="error" variant="subtitle2">
                      {t('common_error_sorry_there_is_a_problem')}
                    </Typography>
                    <Typography color="error" variant="caption" component="p" gutterBottom>
                      {errorMessage || t('phone_verification_error_backend')}
                    </Typography>
                  </div>
                )}
                <Typography gutterBottom={true}>
                  {t('phone_verification_description', { phoneNumber: payload?.phoneNumber })}
                </Typography>
                <Typography className={spacedStyles}>
                  {t('phone_verification_didnt_receive')}{' '}
                  {codeRequestAllowed ? (
                    <Link className={linkStyle} onClick={handleResendCode}>
                      {t('phone_verification_button')}
                    </Link>
                  ) : (
                    <span>{t('phone_verification_code_sent')}</span>
                  )}
                </Typography>
                <Field
                  className={inputStyle}
                  component={TextField}
                  type={'tel'}
                  name="token"
                  id="token"
                  placeholder={t('phone_verification_placeholder')}
                  validate={requiredValidator(t)}
                  fullWidth={true}
                />
              </FormFieldsWrapper>
              <ButtonsContainer>
                {isPhoneEditable && (
                  <Button variant="secondary" onClick={handleBack} loading={isLoading}>
                    {t('common_go_back')}
                  </Button>
                )}
                <Button type="submit" fullWidth={!isPhoneEditable} loading={isLoading}>
                  {t('common_continue')}
                </Button>
              </ButtonsContainer>
            </Form>
          </FormikProvider>
          <Typography
            component="p"
            variant={isMobile ? 'body2' : 'subtitle1'}
            color="primary"
            style={{ marginTop: 36 }}
          >
            {t('common_have_questions')} <ChatWithSupport />
          </Typography>
        </FormContent>
      </FormLayout>
    </>
  );
};

export default PhoneVerificationForm;
