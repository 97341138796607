import { ComponentsProps } from '@material-ui/core/styles/props';
import { CheckboxIcon, RadioIcon, CheckboxCheckedIcon, RadioCheckedIcon, CheckboxIndeterminateIcon } from './icons';

const props: ComponentsProps = {
  MuiTextField: {
    margin: 'normal',
  },
  MuiLink: {
    underline: 'always',
  },
  MuiIcon: {
    color: 'primary',
  },
  MuiSvgIcon: {
    color: 'primary',
  },
  MuiRadio: {
    color: 'primary',
    icon: <RadioIcon />,
    checkedIcon: <RadioCheckedIcon />,
  },
  MuiCheckbox: {
    color: 'primary',
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />,
  },
  MuiTab: {
    disableRipple: true,
  },
};

export default props;
