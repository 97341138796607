import { TFunction } from 'react-i18next';
import { EMAIL_VALIDATION_REGEX } from '../../../common/constants/regexes';

const emailValidator = (t: TFunction<'translation'>, fieldName: string) => (value: any) => {
  let error;
  if (!value) {
    error = t('common_error_required_dynamic', { field: fieldName });
  } else if (!new RegExp(EMAIL_VALIDATION_REGEX, 'i').test(value)) {
    error = `${t('email_error_please_use_format')}: example@email.com`;
  }
  return error;
};

export default emailValidator;
