import { useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Graphic } from '../../../../common/components';
import { Typography, Button } from '../../../../theme/components';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { ButtonsContainer } from '../../../../common/components/FormLayout';
import FeedbackLayout, { Content, ContentWrapper, Spacer } from '../../../../common/components/FeedbackLayout';

import checkemailgraphic from '../../../../../assets/images/checkemail.svg';

export const EmailPollingTimeout = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <FeedbackLayout>
      <Spacer />
      <ContentWrapper marginBottom={0}>
        <Graphic image={checkemailgraphic}></Graphic>
        <Typography variant="h2" gutterBottom>
          {t('email_polling_expired_title')}
        </Typography>
        <Content>
          <Typography gutterBottom>{t('email_polling_expired_descriptive_text')}</Typography>
          <Typography style={{ marginBottom: '36px' }}>
            {t('email_polling_expired_help_text')} <ChatWithSupport lowerCase={true} />
          </Typography>
        </Content>

        <ButtonsContainer flexDirection="column" marginTop={isMobile ? 0 : 1}>
          <Button fullWidth={isMobile} onClick={handleRefresh}>
            {t('common_refresh_button')}
          </Button>
        </ButtonsContainer>
      </ContentWrapper>
      <Spacer disableMinHeight />
    </FeedbackLayout>
  );
};

export default EmailPollingTimeout;
