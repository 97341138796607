import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

import props from './props';
import { createTheme } from '@material-ui/core/styles';
import { Theme } from '@emotion/react';
import { Shadows } from '@material-ui/core/styles/shadows';
export { default as ThemeProvider } from './ThemeProvider';

// Main MuiTheme creation, (so it can be accessed from the overrides)
export const theme = createTheme({
  palette,
  breakpoints,
  typography,
  props,
  shadows: Array(25).fill('none') as Shadows,
  spacing: 4,
});

// Include the overrides
export const materialThemeWithOverrides = createTheme(
  {
    overrides: require('./overrides'),
  },
  theme,
);

// Emotion theme (legacy)
export const emotionTheme: Theme = {
  color: {
    amaranth: theme.palette.primary.main,
    amaranth50: theme.palette.primary.light,
    amaranth30: '#f6bcc8',
    paris: '#e6f0ea',
    black: theme.palette.common.black,
    black40: '#999',
    gray: theme.palette.grey[500],
    lightGray: theme.palette.grey[200],
    white: theme.palette.common.white,
    emerald: theme.palette.secondary.main,
    warning: theme.palette.error.main,
  },
  breakpoint: theme.breakpoints.values,
};
