import { TypographyClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiTypography: Partial<StyleRules<TypographyClassKey, {}>> = {
  gutterBottom: {
    marginBottom: theme.spacing(6),
  },
  body1: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: '22px',
    },
    '&$gutterBottom': {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
  },
  subtitle1: {
    '&$gutterBottom': {
      marginTop: 0,
    },
  },
  h2: {
    marginTop: theme.spacing(9),
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
      lineHeight: '32px',
      marginTop: theme.spacing(7),
    },
  },
  h4: {
    marginBottom: 14,
  },
};

export default MuiTypography;
