import { FormLabelClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiFormLabel: Partial<StyleRules<FormLabelClassKey, {}>> = {
  root: {
    whiteSpace: 'nowrap',
    color: theme.palette.text.secondary,
    '&$focused': {
      color: theme.palette.text.secondary,
    },
    '&$error': {
      color: theme.palette.error.main,
    },
  },
};

export default MuiFormLabel;
