import { createReducer } from 'typesafe-actions';
import { IAuthUserState } from '.';
import { IErrorResponse } from '../../common/models';
import { registerUser, setAuthError, setAuthResponse, setApplicationStarted } from './auth.actions';

const initialState: IAuthUserState = {
  isNewUser: true,
  applicationStarted: false,
  loading: true,
  userToken: '',
  userId: '',
  error: null,
};

export default createReducer(initialState)
  .handleAction(registerUser, (state: IAuthUserState) => ({
    ...state,
    loading: true,
  }))
  .handleAction(
    setAuthResponse,
    (
      state: IAuthUserState,
      { payload }: { payload: { expiresIn: number | null; userToken: string; userId: string } },
    ) => ({
      ...state,
      ...payload,
      loading: false,
    }),
  )
  .handleAction(setApplicationStarted, (state: IAuthUserState, { payload }: { payload: boolean }) => ({
    ...state,
    applicationStarted: payload,
  }))
  .handleAction(setAuthError, (state: IAuthUserState, { payload }: { payload: IErrorResponse }) => ({
    ...state,
    ...initialState,
    loading: false,
    error: payload,
  }));
