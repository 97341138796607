import React, { useEffect, useState } from 'react';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Field, Form, FormikProvider } from 'formik';
import { Error } from '../personaldetails';
import { Button, RadioGroup, RadioWithLabel, TextField, Typography } from '../../../../theme/components';
import { questionnaireValidator, questionnaireManualInputValidator } from '../../../utils/validators';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormikWithErrors, getCurrencyFormatter } from '../../../helpers';
import { IErrorResponse } from '../../../../common/models';
import { ITopicOptionsConfig } from '../../../models/onboarding.models';
import * as Sentry from '@sentry/react';
import ResponsiveSimpleModal from '../../../../theme/components/ResponsiveSimpleModal';
import { IQuestion } from './questionsList';
import { IInitialState } from './FinanceDetailsStep';

interface IFinanceQuestionProps {
  error: IErrorResponse | null;
  isLoading: boolean;
  onSubmit: (cb: (values: any) => void) => (values: any) => void;
  onNext: (dataToSubmit: any) => void;
  initialValues: IInitialState;
  showPrevious: boolean;
  onPrevious: () => void;
  question: IQuestion;
}

type styleProps = {
  isMobile: boolean;
  isCompact: boolean;
};
const useStyles = makeStyles(() => ({
  labelClass: {
    minWidth: (props: styleProps) => (!props.isMobile && !props.isCompact ? '200px' : '0'),
    maxWidth: (props: styleProps) => (props.isCompact ? '100px' : 'unset'),
  },
}));

const Question = ({
  error,
  isLoading,
  onSubmit,
  onNext,
  initialValues,
  showPrevious,
  onPrevious,
  question,
}: React.PropsWithChildren<IFinanceQuestionProps>) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isCompact = question && question.compact ? question.compact : false;
  const { labelClass } = useStyles({
    isMobile,
    isCompact,
  });

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [detailedInformation, setDetailedInformation] = useState<React.ReactNode>(null);
  const [showMoreInfoDialog, setShowMoreInfoDialog] = useState(false);
  const [customInput, setCustomInput] = useState({
    fieldName: '',
    showManualInput: false,
    inputLength: 75,
    hasManualInput: false,
    nameOfManualInput: '',
  });

  useEffect(() => {
    if (question.topicOptions) {
      const detailedPoints = question.topicOptions.filter((t) => t.detailedInformation);
      if (detailedPoints.length > 0) {
        setShowMoreInfo(true);
        const renderedPoints = detailedPoints.map((t) => {
          return (
            <React.Fragment key={t.id}>
              <h4>{t.displayName}</h4>
              <p>{t.detailedInformation}</p>
            </React.Fragment>
          );
        });
        setDetailedInformation(renderedPoints);
      } else {
        setShowMoreInfo(false);
      }
    } else {
      Sentry.captureMessage(`${question.formName} topic options are Missing`);
    }
  }, [question.formName]);

  useEffect(() => {
    if (question.topicOptions) {
      const manualInput = question.topicOptions.filter((t) => t.manual);
      if (manualInput.length > 0) {
        const option = manualInput[0];
        setCustomInput({
          fieldName: `${question.formName}_other`,
          hasManualInput: true,
          inputLength: option.maxLength || 75,
          showManualInput: initialValues[question.formName as keyof IInitialState] === option.id || false,
          nameOfManualInput: option.id,
        });
      } else {
        setCustomInput({
          ...customInput,
          hasManualInput: false,
          showManualInput: false,
        });
      }
    }
  }, [question.formName]);

  const onChange = (value: any) => {
    if (customInput.hasManualInput && value === customInput.nameOfManualInput) {
      setCustomInput({
        ...customInput,
        showManualInput: true,
      });
    } else if (customInput.hasManualInput && value !== customInput.nameOfManualInput) {
      setCustomInput({
        ...customInput,
        showManualInput: false,
      });
    }
    return;
  };

  const { touched, errors, handleChange, ...formik } = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit((values: IInitialState) => onNext(values)),
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  const getRangeLabel = (topic: ITopicOptionsConfig) => {
    try {
      if (topic.range) {
        const currencyFormatter = getCurrencyFormatter(topic.range.currency, i18n.language);
        return topic.range.low && topic.range.high
          ? `${currencyFormatter.format(parseInt(topic.range.low))} - ${currencyFormatter.format(
              parseInt(topic.range.high),
            )}`
          : topic.range.low
          ? `${t('questionnaire_range_more_than')} ${currencyFormatter.format(parseInt(topic.range.low))}`
          : `${t('questionnaire_range_less_than')} ${currencyFormatter.format(parseInt(topic.range?.high || '0'))}`;
      } else {
        return topic.displayName;
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <FormLayout>
        <Typography variant="h2" component="legend" align="center" style={{ marginBottom: 10 }}>
          {t(question.title)}
        </Typography>
        {question.description && (
          <Typography align="center" gutterBottom>
            {t(question.description)}
          </Typography>
        )}
        {showMoreInfo && (
          <Button variant={'text'} onClick={() => setShowMoreInfoDialog(true)}>
            {t('questionnaire_more_info')}
          </Button>
        )}
        <FormContent maxWidth={isMobile ? 400 : 500}>
          <FormikProvider value={{ touched, errors, handleChange, ...formik }}>
            <Form>
              {error && <Error />}
              <FormFieldsWrapper
                marginTop={3}
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  maxWidth: isCompact && !isMobile ? '200px' : 'unset',
                }}
              >
                <Fieldset>
                  <Field
                    component={RadioGroup}
                    name={question.formName}
                    aria-label={question.formName}
                    row={isMobile ? false : question.isRow}
                    validate={questionnaireValidator(t)}
                    style={{ marginTop: 24 }}
                    onChange={(e: React.FocusEvent<any>) => {
                      e.preventDefault();
                      handleChange(e);
                      onChange(e.target.value);
                    }}
                  >
                    {question.topicOptions.map((topic) => {
                      return (
                        <RadioWithLabel
                          key={topic.id}
                          value={topic.id}
                          label={
                            topic.range
                              ? getRangeLabel(topic)
                              : topic.displayNameTranslateKey
                              ? t(topic.displayNameTranslateKey)
                              : topic.displayName
                          }
                          className={labelClass}
                        />
                      );
                    })}
                  </Field>
                  {customInput.showManualInput && customInput.fieldName && (
                    <>
                      <Field
                        component={TextField}
                        disabled={!customInput.showManualInput}
                        name={customInput.fieldName}
                        validate={questionnaireManualInputValidator(
                          t,
                          customInput.inputLength,
                          customInput.showManualInput,
                        )}
                        fullWidth={true}
                        placeholder={t('questionnaire_other_answer')}
                      />
                    </>
                  )}
                  {touched && touched[question.formName] && errors && errors[question.formName] && (
                    <Typography variant="caption" color="error">
                      {errors[question.formName]}
                    </Typography>
                  )}
                </Fieldset>
              </FormFieldsWrapper>
              <ButtonsContainer
                maxWidth={isMobile ? 400 : 500}
                justifyContent={'space-evenly'}
                marginTop={isMobile ? 0 : 1}
              >
                {showPrevious && (
                  <Button variant="secondary" onClick={onPrevious} data-testid="question-goback">
                    {t('common_go_back')}
                  </Button>
                )}
                <Button type="submit" fullWidth={!showPrevious} loading={isLoading}>
                  {t('common_continue')}
                </Button>
              </ButtonsContainer>
            </Form>
          </FormikProvider>
        </FormContent>
      </FormLayout>
      <ResponsiveSimpleModal
        open={showMoreInfoDialog}
        secondaryButton={{ handle: () => setShowMoreInfoDialog(false), label: t('common_ok') }}
        handleClose={() => setShowMoreInfoDialog(false)}
        title={t('questionnaire_more_info')}
        body={detailedInformation}
      />
    </>
  );
};

export default Question;
