import React from 'react';
import { ListItemText, Typography, Box, ListItemIcon, InputAdornment } from '@material-ui/core';
import Autocomplete, { AutocompleteProps } from '../../theme/components/Autocomplete';
import FlagLazy from './FlagLazy';

export type Country = {
  value: string;
  label: string;
};

const renderOption = (option: Country) => (
  <>
    <ListItemIcon style={{ minWidth: 34, marginLeft: -6 }}>
      <FlagLazy nationality={option.value} />
    </ListItemIcon>
    <ListItemText primary={option.label} />
  </>
);

export function FlagAdornment({ nationality }: { nationality: string }) {
  return (
    <InputAdornment position="start" disableTypography={true} style={{ marginLeft: -8 }}>
      <FlagLazy nationality={nationality} />
    </InputAdornment>
  );
}

function CountryAutocomplete({
  placeholder,
  countries,
  ...props
}: Omit<AutocompleteProps<Country>, 'options' | 'renderOption' | 'noOptionsText'> & {
  countries: Country[];
}) {
  return (
    <Autocomplete
      {...props}
      placeholder={placeholder}
      options={countries}
      renderOption={renderOption}
      getOptionSelected={(option, value) => option.value === value.value}
      openOnFocus
      noOptionsText={
        <Box padding={1}>
          <Typography align="center" variant="body2" gutterBottom={true}>
            No results match your search. If you can’t find your country on the list, it’s not supported.
          </Typography>
        </Box>
      }
      style={{ minWidth: 300 }}
    />
  );
}

export default CountryAutocomplete;
