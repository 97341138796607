import { addSeconds } from 'date-fns';
import { all, put, takeEvery } from 'redux-saga/effects';
import { registerUser, setAuthError, setAuthResponse } from '.';
import BUXConfig from '../../common/buxConfig';
import { register } from '../api/auth.api';
import { IAuthResponse } from '../models/auth.models';
import { setApplicationStarted } from './auth.actions';
import * as Sentry from '@sentry/react';
import SentryError from '../../onboarding/utils/SentryError';
import { IErrorResponse } from '../../common/models';

function* listenRegisterUser() {
  yield takeEvery(registerUser, function* onRegisterUser() {
    try {
      const { userToken, userId, expired, applicationStarted } = BUXConfig.getTokenConfig();

      if (!userToken || !userId || expired) {
        // brand new user, or token expired -> request new token
        const response: IAuthResponse = yield register();
        const expiryDateTime = addSeconds(new Date(), response.expires_in);
        BUXConfig.setTokenConfig({
          userToken: response.access_token,
          userId: response.user_id,
          expiryDateTime,
          applicationStarted: false,
        });
        yield put(setApplicationStarted(false));
        yield put(
          setAuthResponse({
            userId: response.user_id,
            userToken: response.access_token,
          }),
        );
      } else {
        // existing token found and is valid, resuming application
        yield put(setApplicationStarted(applicationStarted));
        yield put(setAuthResponse({ userId, userToken }));
      }
    } catch (e) {
      Sentry.captureException(new SentryError(e as IErrorResponse));
      yield put(setAuthError(e as IErrorResponse));
    }
  });
}

export default function* registerSagas() {
  yield all([listenRegisterUser()]);
}
