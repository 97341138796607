import React, { useContext, useMemo } from 'react';
import { Field, Form, FormikProvider } from 'formik';
import CountryAutocomplete, { Country, FlagAdornment } from '../../../../common/components/CountryAutocomplete';
import { Typography, Button, TextField } from '../../../../theme/components';
import FormLayout, {
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
  ButtonsContainer,
} from '../../../../common/components/FormLayout';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { cityOfBirthValidator, requiredValidator } from '../../../utils/validators';
import { filterUnmappedCountries, mapCountryCode, useFormikWithErrors } from '../../../helpers';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { Error } from '.';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { PersonalDetailsContext } from './PersonalDetailsProvider';
import { StepComponent } from '../../StepRoute';

export const PlaceOfBirthStep: React.FC<StepComponent> = ({ onPrevious, onNext, showPrevious = true }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { isLoading, error, initialValues, onSubmit } = useContext(PersonalDetailsContext);
  const {
    currentStep: { payload },
  } = useSelector((state: IRootState) => state.onboarding);
  const countriesOfBirth = useMemo(() => {
    const {
      _config: { countriesOfBirth },
    } = payload as any;
    return countriesOfBirth;
  }, []);

  const { touched, errors, values, ...formik } = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit((values: any) => onNext(values)),
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  return (
    <FormLayout>
      <FormContent marginTop={6}>
        <FormikProvider value={{ touched, errors, values, ...formik }}>
          <Form>
            <FormFieldsWrapper marginTop={3}>
              {error && <Error />}
              <Fieldset>
                <Typography variant="h4" component="legend" align="center">
                  {t('personal_details_placeofbirth_title')}
                </Typography>
                <Typography align="center" gutterBottom>
                  {t('personal_details_placeofbirth_description')}
                </Typography>
                <Field
                  component={CountryAutocomplete}
                  name="countryOfBirth"
                  placeholder={t('personal_details_select_country')}
                  countries={countriesOfBirth
                    .filter(filterUnmappedCountries(i18n))
                    .map(mapCountryCode(t))
                    .sort((a: Country, b: Country) => a.label?.localeCompare(b.label))}
                  validate={requiredValidator(t)}
                  fullWidth={true}
                  TextFieldProps={{
                    label: t('personal_details_countryofbirth'),
                    name: 'countryOfBirth',
                    error: touched['countryOfBirth'] && !!errors['countryOfBirth'],
                    helperText: touched['countryOfBirth'] && errors['countryOfBirth'],
                    InputProps: {
                      startAdornment: values['countryOfBirth'] ? (
                        <FlagAdornment nationality={values['countryOfBirth'].value} />
                      ) : undefined,
                    },
                  }}
                />
                <Field
                  component={TextField}
                  name="cityOfBirth"
                  id="cityOfBirth"
                  label={t('personal_details_cityofbirth')}
                  placeholder={t('personal_details_cityofbirth')}
                  fullWidth={true}
                  validate={cityOfBirthValidator(t, t('personal_details_cityofbirth'))}
                />
              </Fieldset>
            </FormFieldsWrapper>
            <ButtonsContainer>
              {showPrevious && (
                <Button variant="secondary" onClick={onPrevious}>
                  {t('common_go_back')}
                </Button>
              )}
              <Button type="submit" loading={isLoading} id="web-onboarding-personal-details">
                {t('common_continue')}
              </Button>
            </ButtonsContainer>
          </Form>
        </FormikProvider>
        <Typography component="p" variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </FormContent>
    </FormLayout>
  );
};

export default PlaceOfBirthStep;
