async function loadScript(scriptSrc: string, id?: string) {
  return new Promise<void>((resolve) => {
    const script = document.createElement('script');
    id && (script.id = id);
    script.src = scriptSrc;
    script.async = true;
    script.onload = () => {
      resolve();
    };
    document.body.appendChild(script);
  });
}
export default loadScript;
