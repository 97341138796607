import { createAction } from 'typesafe-actions';
import { IOnboardingStep } from '.';
import { IErrorResponse } from '../../common/models';
import { ISubmitApplicationPayload, OnboardingSteps } from '../models/onboarding.models';

export const startUserApplication = createAction('onboarding/START_USER_APPLICATION')();

export const getExistingApplication = createAction('onboarding/GET_EXISTING_APPLICATION')();

export const getNextStepForApplication = createAction('onboarding/GET_NEXT_STEP_FOR_APPLICATION')<string>();

export const resumeApplication = createAction('onboarding/RESUME_APPLICATION')<IOnboardingStep>();

export const submitFormDataForOnboardingStep = createAction(
  'onboarding/SUBMIT_FORM_DATA_FOR_ONBOARDING_STEP',
)<ISubmitApplicationPayload>();

export const submitFormDataForFinanceQuestions = createAction(
  'onboarding/SUBMIT_FORM_DATA_FOR_FINANCE_QUESTIONS',
)<ISubmitApplicationPayload>();

export const pollForEmailVerification = createAction('onboarding/POLL_FOR_EMAIL_VERIFICATION')();

export const pollForVerification = createAction('onboarding/POLL_FOR_VERIFICATION')<{ path: string }>();

export const handleOnboardingError = createAction('onboarding/HANDLE_ONBOARDING_ERROR')<IErrorResponse>();

export const submitUserEmail = createAction('onboarding/SUBMIT_USER_EMAIL')<ISubmitApplicationPayload>();

export const submitPhotoId = createAction('onboarding/SUBMIT_PHOTO_ID')<ISubmitApplicationPayload>();

export const submitSelfie = createAction('onboarding/SUBMIT_SELFIE')<ISubmitApplicationPayload>();

export const gotoStep = createAction('onboarding/GOTO_STEP')<OnboardingSteps>();

export const gotoStepAndGetData = createAction('onboarding/GOTO_STEP_AND_GET_DATA')<OnboardingSteps>();

export const registerSentrySessionId = createAction('onboarding/SENTRY_SESSION_ID')();

export const setSentrySessionId = createAction('onboarding/SET_SENTRY_SESSION_ID')<{
  anonymousId: string;
}>();

export const submitSkipTaxStep = createAction('onboarding/SUBMIT_SKIP_TAX_STEP')();

export const fetchCustomData = createAction('onboarding/FETCH_CUSTOM_DATA')<{ key: string; url: string }>();

export const saveFetchedCustomData = createAction('onboarding/SAVE_FETCHED_CUSTOM_DATA')<{
  key: string;
  data: unknown;
}>();

export const markPOIAttempted = createAction('onboarding/MARK_POI_ATTEMPTED')();

export const submitPhoneVerification = createAction(
  'onboarding/SUBMIT_PHONE_VERIFICATION',
)<ISubmitApplicationPayload>();

export const createGuestAccount = createAction('stocks/CREATE_STOCKS_GUEST_ACCOUNT')();
