import React, { useState } from 'react';
import { IErrorResponse } from '../../../../common/models';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Button, TextField, Typography } from '../../../../theme/components';
import { phoneNumberValidator } from '../../../utils/validators';
import { IPhoneNumber } from './PhoneNumberStep';
import { IphoneNumberPayload } from '../../../models/onboarding.models';
import { mapCountryToCode, parseNumber, validateNumberLength, validatePhoneNumber } from './phoneFieldUtils';
import { ParseError } from 'libphonenumber-js';
import * as Sentry from '@sentry/react';

interface IInputPhoneNumberFormProps {
  onSubmit: (values: IPhoneNumber) => void;
  error: IErrorResponse | null;
  isLoading: boolean;
  payload: IphoneNumberPayload;
}

const useStyles = makeStyles(() => ({
  inputStyle: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const PhoneNumberForm = ({
  onSubmit,
  error,
  isLoading,
  payload,
}: React.PropsWithChildren<IInputPhoneNumberFormProps>) => {
  const { inputStyle } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const countryOfResidence = (payload && payload._config?.countryOfResidence) || 'NL';
  const [errors, setErrors] = useState({ phoneNumber: '' });

  const countryCode = mapCountryToCode(countryOfResidence.toUpperCase());
  const initialValues: IPhoneNumber = {
    phoneNumber: (payload && payload.phoneNumber) || `+${countryCode}`,
  };

  const submitData = (values: IPhoneNumber, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    try {
      const formattedNumber = parseNumber(values.phoneNumber);
      //run some validations
      setErrors({ phoneNumber: '' });
      if (validatePhoneNumber(formattedNumber.number)) {
        onSubmit({
          phoneNumber: formattedNumber.number,
        });
      } else {
        const detailedError = validateNumberLength(formattedNumber.number);
        if (detailedError) {
          parseSubmitErrors(detailedError);
        } else {
          Sentry.captureMessage(`Phone number parsing failed -- undefined detailed error ${values.phoneNumber}`);
          setErrors({ phoneNumber: t('phone_number_error_incorrect') });
        }
        setSubmitting(false);
      }
    } catch (error) {
      if (error instanceof ParseError) {
        parseSubmitErrors(error.message);
      } else {
        Sentry.captureMessage(`Phone number parsing failed -- not a parse submit error ${values.phoneNumber}`);
        setErrors({ phoneNumber: t('phone_number_error_incorrect') });
      }
      setSubmitting(false);
    }
  };

  const parseSubmitErrors = (errorMessage: string) => {
    switch (errorMessage) {
      case 'INVALID_COUNTRY':
        Sentry.captureMessage(`Phone number error - no country code`);
        setErrors({
          phoneNumber: t('phone_number_error_dialling_code', { countryCode: countryCode }),
        });
        break;
      case 'NOT_A_NUMBER':
        setErrors({ phoneNumber: t('phone_number_error_numbers_only') });
        break;
      case 'TOO_LONG':
        setErrors({ phoneNumber: t('phone_number_error_too_long') });
        break;
      case 'TOO_SHORT':
        setErrors({ phoneNumber: t('phone_number_error_too_short') });
        break;
      default:
        Sentry.captureMessage(`Phone number error - default error ${errorMessage}`);
        setErrors({ phoneNumber: t('phone_number_error_incorrect') });
    }
  };

  const formik = useFormik({ enableReinitialize: true, initialValues, onSubmit: submitData, initialErrors: errors });

  return (
    <>
      <FormLayout>
        <Typography variant="h2" gutterBottom={true}>
          {t('personal_details_phone_title')}
        </Typography>
        <FormContent>
          <FormikProvider value={formik}>
            <Form>
              <FormFieldsWrapper>
                {error && (
                  <>
                    <Typography color="error" variant="subtitle2">
                      {t('common_error_sorry_there_is_a_problem')}
                    </Typography>
                    <Typography color="error" variant="caption" component="p" gutterBottom>
                      {t('common_error_please_try_again')}
                    </Typography>
                  </>
                )}
                <Typography gutterBottom={true}>{t('personal_details_phone_description')}</Typography>
                <Field
                  className={inputStyle}
                  component={TextField}
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  autoComplete="tel"
                  placeholder="+31 12345678"
                  validate={phoneNumberValidator(t, t('phone_title'))}
                  fullWidth={true}
                />
              </FormFieldsWrapper>
              <ButtonsContainer flexDirection="column" marginTop={isMobile ? 0 : 1}>
                <Button fullWidth={isMobile} type="submit" loading={isLoading}>
                  {t('common_continue')}
                </Button>
              </ButtonsContainer>
            </Form>
          </FormikProvider>
        </FormContent>
      </FormLayout>
    </>
  );
};

export default PhoneNumberForm;
