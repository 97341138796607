import React, { FC, useState } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from '../../../../theme/components';
import { agreementOptions } from './config';
import { useTranslation } from 'react-i18next';
import AgreementDocDialog from './AgreementDocDialog';
import { IAgreementsState } from './SignedAgreementStep';

type styleProps = {
  isMobile: boolean;
  agreementAccepted: boolean;
};

const useStyles = makeStyles(() => ({
  outsideBox: {
    boxShadow: (props: styleProps) => (props.agreementAccepted ? '0px 0px 0px 2px #5FCD6F' : '0px 0px 0px 2px #D6D5D5'),
    borderRadius: '5px',
    width: '100%',
    minHeight: '101px',
    margin: '22px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: (props: styleProps) =>
        props.agreementAccepted ? '0px 0px 0px 4px #5FCD6F' : '0px 0px 0px 4px #D6D5D5',
    },
    '&::after': {
      content: (props: styleProps) => (props.agreementAccepted ? '""' : 'none'),
      width: '28px',
      height: '28px',
      background: 'url(/static/images/tick.svg) center no-repeat',
      backgroundSize: '100%',
      borderRadius: '100%',
      display: 'block',
      position: 'absolute',
      top: '-11px',
      right: '-11px',
    },
  },
  insideBox: {
    padding: '20px',
  },
  titleStyle: {
    fontWeight: 600,
  },
  readButtonStyle: {
    textAlign: 'left',
    textDecoration: 'underline',
    transition: 'color 0.1s ease-in-out',
    '&:hover': {
      color: '#ff9db1',
    },
  },
  acceptedButtonStyle: {
    textAlign: 'left',
    color: '#5FCD6F',
  },
}));

interface IAgreementBox {
  agreement: IAgreementsState;
  markAgreementAsAccepted: (agreement: IAgreementsState) => void;
}

const AgreementBox: FC<IAgreementBox> = ({ agreement, markAgreementAsAccepted }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [openAgreement, setOpenAgreement] = useState(false);
  const { outsideBox, insideBox, titleStyle, readButtonStyle, acceptedButtonStyle } = useStyles({
    isMobile,
    agreementAccepted: agreement.accepted,
  });

  const agreementTitle = agreementOptions[agreement.type.toLowerCase()]?.name;

  const handleOpenAgreement = () => {
    setOpenAgreement(true);
  };

  const handleAgreementAccepted = () => {
    setOpenAgreement(false);
    markAgreementAsAccepted(agreement);
  };

  return (
    <>
      <div className={outsideBox} onClick={handleOpenAgreement}>
        <div className={insideBox}>
          <Typography variant="h6" gutterBottom align={'left'} className={titleStyle}>
            {agreement.index + 1}. {t(agreementTitle)}
          </Typography>
          {agreement.accepted ? (
            <Typography variant={isMobile ? 'body2' : 'subtitle1'} className={acceptedButtonStyle}>
              {t('signed_agreements_accepted')}
            </Typography>
          ) : (
            <Typography variant={isMobile ? 'body2' : 'subtitle1'} color="primary" className={readButtonStyle}>
              {t('signed_agreements_read_action')}
            </Typography>
          )}
        </div>
      </div>
      <AgreementDocDialog
        openAgreement={openAgreement}
        agreement={agreement}
        closeAgreement={() => setOpenAgreement(false)}
        agreeAgreement={handleAgreementAccepted}
        agreementTitle={agreementTitle}
      />
    </>
  );
};

export default AgreementBox;
