import React, { FC, useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import styled from '@emotion/styled';
import { Content, ContentWrapper } from '../../../../common/components/FeedbackLayout';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../../theme/icons';

import qrcode from '../../../../../assets/images/QRCode.svg';

export const QRCodeContainer = styled.div`
  height: ${(props: { isModal: boolean }) => (props.isModal ? '210px' : '160px')};
  width: ${(props: { isModal: boolean }) => (props.isModal ? '210px' : '160px')};
  background: url(${qrcode});
  background-size: contain;
`;

const ModalContent = styled.div`
  width: 552px;
  max-height: 480px;
`;

const InfoText = styled.div`
  margin-top: 40px;
`;

const useStyles = makeStyles(() => ({
  qrHelperText: {
    textAlign: 'center',
    fontSize: '16px',
  },
  dialogContent: {
    padding: 0,
  },
  contentMargins: {
    margin: '0 24px 48px 24px',
  },
}));

interface IQRModal {
  open: boolean;
  handleClose: () => void;
}

const QRModal: FC<IQRModal> = ({ open, handleClose }) => {
  const { qrHelperText, dialogContent, contentMargins } = useStyles();
  const { t } = useTranslation();

  const closeModal = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="dialog-qr-code"
      aria-describedby="dialog which presents qr code for scanning"
    >
      <DialogTitle>
        <IconButton aria-label="close" onClick={closeModal} className="closeButton">
          <CloseIcon className="closeIcon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <ModalContent>
          <ContentWrapper marginTop={'0rem'} className={contentMargins}>
            <Content>
              <Typography variant="h4" align="center" gutterBottom>
                {t('continue_on_app_qr_how_to')}
              </Typography>

              <QRCodeContainer data-testid={'qr-code-modal'} isModal={true} />

              <InfoText>
                <Typography className={qrHelperText}> {t('continue_on_app_scan_qr_how_to_one')}</Typography>
                <Typography className={qrHelperText}> {t('continue_on_app_scan_qr_how_to_two')}</Typography>
                <Typography className={qrHelperText}> {t('continue_on_app_scan_qr_how_to_three')}</Typography>
              </InfoText>
            </Content>
          </ContentWrapper>
        </ModalContent>
      </DialogContent>
    </Dialog>
  );
};

const QRModalContext = React.createContext<{ open: boolean; setOpen: (open: boolean) => void }>({
  open: false,
  setOpen: () => null,
});

const QRModalProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [open, setOpen] = useState(false);
  return (
    <QRModalContext.Provider value={{ open, setOpen }}>
      {children}
      <QRModal open={open} handleClose={() => setOpen(false)} />
    </QRModalContext.Provider>
  );
};

export function useQRModal() {
  const { open, setOpen } = useContext(QRModalContext);
  return {
    open: () => setOpen(true),
    isOpen: open,
    close: () => setOpen(false),
  };
}

export default QRModalProvider;
