import { theme } from '..';

const MuiPickersToolbarText: any = {
  toolbarTxt: {
    color: theme.palette.common.white,
    fontWeight: 400,
  },
};

export default MuiPickersToolbarText;
