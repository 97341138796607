import { TFunction } from 'react-i18next';
import { lengthValidator, noSpecialCharsValidator, requiredValidator, regexValidator } from './index';

const addressStreetValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return requiredValidator(t, fieldName)(value) || lengthValidator(t, 2, 50, fieldName)(value);
};

const addressNumberValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return requiredValidator(t, fieldName)(value) || lengthValidator(t, 0, 10, fieldName)(value);
};

const addressNumberAdditionValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return lengthValidator(t, 0, 5, fieldName)(value);
};

const addressCityValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return requiredValidator(t, fieldName)(value) || lengthValidator(t, 0, 50, fieldName)(value);
};

const addressProvinceValidator =
  (t: TFunction<'translation'>, provinceExample: string, provinceValidationRule?: string) => (value: any) => {
    return (
      (provinceValidationRule && regexValidator(t, provinceValidationRule, provinceExample)(value)) ||
      noSpecialCharsValidator(t)(value) ||
      lengthValidator(t, 0, 50)(value)
    );
  };

const addressPostalCodeValidator =
  (t: TFunction<'translation'>, postcodeExample: string, fieldName?: string, postcodeValidationRule?: string) =>
  (value: any) => {
    return (
      requiredValidator(t, fieldName)(value) ||
      noSpecialCharsValidator(t, fieldName)(value) ||
      (postcodeValidationRule && regexValidator(t, postcodeValidationRule, postcodeExample)(value)) ||
      lengthValidator(t, 4, 8, fieldName)(value)
    );
  };

const addressBuildingValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return lengthValidator(t, 0, 50, fieldName)(value);
};

export {
  addressStreetValidator,
  addressNumberValidator,
  addressNumberAdditionValidator,
  addressCityValidator,
  addressPostalCodeValidator,
  addressBuildingValidator,
  addressProvinceValidator,
};
