import { IErrorResponse } from '../../common/models';

class SentryError extends Error {
  private errorCode: string;
  private body: { [key: string]: string } | undefined;
  constructor(error: IErrorResponse, ...params: any) {
    super(...params);
    this.name = error?.message || error?.errorCode;
    this.errorCode = error?.errorCode;
    this.body = error?.body || undefined;
  }
}

export default SentryError;
