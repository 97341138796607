import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormLayout, { ButtonsContainer, Content as FormContent } from '../../../../common/components/FormLayout';
import { Button, CircularProgress, Typography } from '../../../../theme/components';
import SelectSecondaryCountry from './SelectSecondaryCountry';
import { IsubmittableCountryValue } from './TaxNumberStep';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { fetchCustomData } from '../../../store';
import { LoadingContainer } from '../../../../common/components';

interface ISecondTaxComponent {
  onSkipSecondaryCountry: () => void;
  onSubmitSecondaryTaxCountry: (values: IsubmittableCountryValue) => void;
}

const SecondTaxCountryQuestion: FC<ISecondTaxComponent> = ({ onSkipSecondaryCountry, onSubmitSecondaryTaxCountry }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tax_countries, error, loading: isLoading } = useSelector((state: IRootState) => state.onboarding);
  const [isInitializing, setIsInitializing] = useState(true);
  const [showCountries, setShowCountries] = useState(false);

  useEffect(() => {
    setIsInitializing(true);
    if (!tax_countries) {
      dispatch(fetchCustomData({ url: 'tax_number/countries', key: 'tax_countries' }));
    } else {
      setIsInitializing(false);
    }
  }, [tax_countries]);

  const listOfTaxCountries = tax_countries as string[];

  const handleYesAnswer = () => {
    setShowCountries(() => true);
  };

  const handleBackButton = () => {
    setShowCountries(false);
  };

  return (
    <>
      {isLoading || isInitializing ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : showCountries ? (
        <SelectSecondaryCountry
          onSubmit={onSubmitSecondaryTaxCountry}
          handleBackButton={handleBackButton}
          listOfTaxCountries={listOfTaxCountries}
          error={error}
          isLoading={isLoading}
          isInitializing={isInitializing}
        />
      ) : (
        <FormLayout>
          <Typography variant="h2" gutterBottom={true}>
            {t('tax_secondary_title')}
          </Typography>
          <FormContent marginTop={6}>
            <Typography gutterBottom={true}>{t('tax_document_disclaimer')}</Typography>
            <ButtonsContainer>
              <ButtonsContainer>
                <Button onClick={handleYesAnswer}>{t('common_yes')}</Button>
                <Button onClick={onSkipSecondaryCountry}>{t('common_no')}</Button>
              </ButtonsContainer>
            </ButtonsContainer>
          </FormContent>
        </FormLayout>
      )}
    </>
  );
};

export default SecondTaxCountryQuestion;
