import { differenceInYears, isValid } from 'date-fns';
import { TFunction } from 'react-i18next';

const dobValidator = (t: TFunction<'translation'>, fieldName: string) => (value: Date) => {
  let error;
  if (!value) {
    error = t('common_error_required_dynamic', { field: fieldName });
  } else if (!isValid(value)) {
    error = `${t('common_error_please_check_field')} ${t('common_invalid_input_message')}`;
  } else if (isValid(value)) {
    const age = differenceInYears(new Date(), value);
    if (age < 18) {
      error = t('personal_details_error_18years_or_older');
    }
  }
  return error;
};

export default dobValidator;
