import React, { FC } from 'react';
import { IIconProps } from '../icons/IconPropsType';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { group1, group4 } from '../animation-timeline-helper';

interface ICustomIconProps {
  SvgIcon: FC<IIconProps>;
  isActive: boolean;
  isJustCompleted: boolean;
  xCoord: number;
  yCoord: number;
  width: number;
  height: number;
  translateValue: number;
  index: number;
  id: string;
}

type CustomIconStylingType = {
  isActive: boolean;
  isJustCompleted: boolean;
  isFirstCircle: boolean;
};

const fadeIn = keyframes`
  from {
   opacity: 0;
  }
  to {
   opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
   opacity: 1;
  }
  to {
   opacity: 0;
  }
`;

const AnimatedCustomSvg = styled.svg<CustomIconStylingType>`
  opacity: ${(props) => (props.isActive ? 0 : props.isJustCompleted ? 1 : 0)};
  animation: ${(props) =>
    props.isActive
      ? css`
          ${fadeIn} ${group4.length}s ${props.isFirstCircle ? '0s' : `${group4.delay}s`} ease-in forwards
        `
      : props.isJustCompleted
      ? css`
          ${fadeOut} ${group1.length}s ${group1.delay}s ease-in forwards
        `
      : undefined};
`;

const CustomIcon: FC<ICustomIconProps> = ({
  id,
  xCoord,
  yCoord,
  height,
  width,
  translateValue,
  SvgIcon,
  isActive,
  isJustCompleted,
  index,
}) => {
  return (
    <AnimatedCustomSvg
      x={xCoord}
      y={yCoord}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      isActive={isActive}
      isJustCompleted={isJustCompleted}
      isFirstCircle={index === 0}
    >
      <SvgIcon translateValue={translateValue} />
    </AnimatedCustomSvg>
  );
};

export default CustomIcon;
