import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Box, CircularProgress, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Transition } from 'react-transition-group';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    padding: 0,
    cursor: 'default',
    width: '100%',
    minWidth: 0,
    transition: 'width 0.8s ease',
    visibility: 'hidden',
  },
  label: {
    width: 'auto',
  },
});

type CustomButtonVariants = 'primary' | 'secondary' | 'text';

type CustomButtonColors = 'amaranth' | 'emerald';

type ButtonProps = Omit<MuiButtonProps, 'variant' | 'color'> & {
  variant?: CustomButtonVariants;
  color?: CustomButtonColors;
  loading?: boolean;
};

function Button({
  variant,
  color,
  loading,
  style,
  fullWidth = false,
  ...props
}: ButtonProps & { fullWidth?: boolean }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box display="flex" justifyContent="center" width={fullWidth ? '100%' : 'auto'} style={style}>
      <Box position="relative" display="flex" justifyContent="center" width={fullWidth ? '100%' : 'auto'}>
        <MuiButton
          {...props}
          variant={mapVariant(variant)}
          color={mapColor(color)}
          style={{ visibility: loading ? 'hidden' : 'visible', width: fullWidth ? '100%' : 'auto' }}
        />
        <Transition in={loading} addEndListener={() => {}}>
          {(state) => (
            <MuiButton
              variant={mapVariant(variant)}
              color={mapColor(color)}
              classes={classes}
              style={
                state === 'entering'
                  ? {
                      visibility: 'visible',
                      width: isMobile ? 44 : 48,
                    }
                  : {}
              }
            >
              <CircularProgress color="inherit" size={20} />
            </MuiButton>
          )}
        </Transition>
      </Box>
    </Box>
  );
}

function mapColor(color?: CustomButtonColors) {
  switch (color) {
    case 'emerald':
      return 'secondary';
    default:
      return 'primary';
  }
}

function mapVariant(variant?: CustomButtonVariants) {
  switch (variant) {
    case 'secondary':
      return 'outlined';
    case 'text':
      return 'text';
    default:
      return 'contained';
  }
}

export default Button;
