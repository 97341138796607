import React from 'react';
import FormLayout, { ButtonsContainer, Content } from '../../../../common/components/FormLayout';
import { Button, Link, Typography } from '../../../../theme/components';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  card: {
    margin: '12px',
    maxWidth: (props: { isMobile: boolean }) => (props.isMobile ? '260px' : '160px'),
    background: '#e6f0ea',
  },
  cardAction: {
    height: '100%',
    padding: '10px',
  },
  media: {
    height: '120px',
    padding: '5px',
  },
  title: {
    fontSize: 18,
  },
});

const articleLinks: Record<string, any> = {
  threeStepsBeforeInvesting: {
    en: 'https://getbux.com/blog/3-steps-before-investing/',
    es: 'https://getbux.com/es/blog/3-pasos-antes-de-invertir/',
    fr: 'https://getbux.com/fr/blog/3-etapes-avant-dinvestir/',
    de: 'https://getbux.com/de/blog/3-schritte-ehe-du-investierst/',
    nl: 'https://getbux.com/nl/blog/drie-to-dos-voordat-je-gaat-beleggen/',
    it: 'https://getbux.com/it/blog/3-passaggi-prima-di-investire',
  },
  whyInvestNow: {
    en: 'https://getbux.com/blog/why-invest-now/',
    es: 'https://getbux.com/es/blog/por-que-invertir-ahora/',
    fr: 'https://getbux.com/fr/blog/pourquoi-investir-des-maintenant/',
    de: 'https://getbux.com/de/blog/warum-solltest-du-jetzt-investieren/',
    nl: 'https://getbux.com/nl/blog/waarom-zou-je-moeten-beleggen/',
    it: 'https://getbux.com/it/blog/etf-o-azioni-cosa-scegliere',
  },
  etfOrStocks: {
    en: 'https://getbux.com/blog/etf-or-stocks-which-to-choose/',
    es: 'https://getbux.com/es/blog/etf-o-acciones-que-escogemos/',
    fr: 'https://getbux.com/fr/blog/etf-ou-action-quoi-choisir/',
    de: 'https://getbux.com/de/blog/etf-oder-aktien-was-passt-zu-dir/',
    nl: 'https://getbux.com/nl/blog/etfs-of-aandelen-wat-past-bij-jou/',
    it: 'https://getbux.com/it/blog/perch%C3%A9-investire',
  },
};

const getArticleLink = (articleTitle: string, lng: string) => {
  const link = articleLinks[articleTitle][lng];
  return link || articleLinks[articleTitle].en;
};

const CardComponent = ({
  title,
  img,
  articleLink,
}: React.PropsWithChildren<{ title: string; img: string; articleLink: string }>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile });
  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.cardAction} href={articleLink} target={'_blank'} rel={'noreferrer'}>
        <CardMedia className={classes.media} image={img} title={t(title)} />
        <CardContent>
          <Typography gutterBottom variant="h4" className={classes.title}>
            {t(title)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ExitScreen = ({ returnToPrevious }: React.PropsWithChildren<{ returnToPrevious: () => void }>) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const communityLinkProps = {
    href: 'https://community.getbux.com/',
    target: '_blank',
    rel: 'noreferrer',
  };

  const classes = useStyles({ isMobile });

  return (
    <>
      <FormLayout>
        <Content marginTop={6} maxWidth={isMobile ? 400 : 600} alignItems={'flex-start'}>
          <Typography variant="h2" component="legend" align="left" style={{ marginBottom: 10 }}>
            {t('exit_screen_title')}
          </Typography>
          <Typography align="left" gutterBottom>
            {t('exit_screen_desc')}
          </Typography>

          <div className={classes.cardContainer}>
            <CardComponent
              title={'exit_screen_card1'}
              img={'/static/images/card1.svg'}
              articleLink={getArticleLink('threeStepsBeforeInvesting', i18n.language)}
            />
            <CardComponent
              title={'exit_screen_card2'}
              img={'/static/images/card2.svg'}
              articleLink={getArticleLink('whyInvestNow', i18n.language)}
            />
            <CardComponent
              title={'exit_screen_card3'}
              img={'/static/images/card3.svg'}
              articleLink={getArticleLink('etfOrStocks', i18n.language)}
            />
          </div>

          <Typography align="left" gutterBottom>
            <Trans i18nKey="exit_screen_footer">
              You can also visit the <Link {...communityLinkProps}>BUX Community Forum</Link> where people share tips
              and strategies about investing.
            </Trans>
          </Typography>
          <Typography align="left" gutterBottom>
            {t('exit_screen_return')}
          </Typography>
          <ButtonsContainer justifyContent={'start'}>
            <Button variant="secondary" style={{ marginLeft: 'unset' }} onClick={returnToPrevious}>
              {t('common_go_back')}
            </Button>
          </ButtonsContainer>
        </Content>
      </FormLayout>
    </>
  );
};

export default ExitScreen;
