import React from 'react';
import { AddressTypes } from '../../../models/onboarding.models';
import AddressForm from './AddressForm';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { AddressValidationContext } from './AddressValidationProvider';

export const AddressStep: React.FC<{ onPrevious: () => void; onNext: (dataToSubmit: any) => void }> = ({
  onPrevious,
  onNext,
}) => {
  const {
    loading: isLoading,
    error,
    currentStep: { payload },
  } = useSelector((state: IRootState) => state.onboarding);

  let type, postcodeValidationRule, postcodeExample, cityExample, provinceExample, provinceValidationRule;
  let countryType: keyof typeof AddressTypes = AddressTypes.DEFAULT;
  if (payload && (payload as any)._config) {
    ({
      _config: { type, postcodeValidationRule, postcodeExample, cityExample, provinceExample, provinceValidationRule },
    } = payload as any);
    const typeKey = type as keyof typeof AddressTypes;
    countryType = AddressTypes[typeKey];
  }

  return (
    <AddressValidationContext.Provider
      value={{ postcodeValidationRule, postcodeExample, cityExample, provinceExample, provinceValidationRule }}
    >
      <AddressForm
        onSubmitFn={onNext}
        onGoBack={onPrevious}
        isLoading={isLoading}
        error={error}
        country={countryType}
      />
    </AddressValidationContext.Provider>
  );
};
