import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { setApplicationStarted } from '../../auth/store';
import BUXConfig from '../../common/buxConfig';
import {
  BOS_APPLICATION_EXISTS_ERROR,
  BOS_APPLICATION_NOT_FOUND,
  EMAIL_POLLING_TIME_OUT_MESSAGE,
  MAX_POLL_PHOTO_ID,
} from '../../common/constants';
import { v4 as uuidv4 } from 'uuid';

import {
  createStockGuestAccount,
  getApplicationDataForStep,
  getEmailVerificationStatus,
  getUserApplication,
  getVerificationStatus,
  putApplicationDataForStep,
  skipTaxStep,
  startApplication,
} from '../api/onboarding.api';
import emailVerificationPoller from '../helpers/emailVerificationPoller';
import LinkToStepMapper from '../helpers/LinkToStepMapper';
import {
  IApplicationResponse,
  IEmailVerificationResponse,
  ILinks,
  IVerificationPayload,
  OnboardingSteps,
} from '../models/onboarding.models';
import {
  createGuestAccount,
  fetchCustomData,
  getExistingApplication,
  getNextStepForApplication,
  gotoStepAndGetData,
  handleOnboardingError,
  pollForEmailVerification,
  pollForVerification,
  registerSentrySessionId,
  resumeApplication,
  saveFetchedCustomData,
  setSentrySessionId,
  startUserApplication,
  submitFormDataForFinanceQuestions,
  submitFormDataForOnboardingStep,
  submitPhoneVerification,
  submitPhotoId,
  submitSelfie,
  submitSkipTaxStep,
  submitUserEmail,
} from './onboarding.actions';
import { IRootState } from '../../../Store';
import * as Sentry from '@sentry/react';
import SentryError from '../utils/SentryError';
import { retrieveSentrySessionId, storeSentrySessionId } from '../utils/localstorage';

function* listenStartUserApplication() {
  yield takeEvery(startUserApplication, function* onStartUserApplication() {
    try {
      const response: IApplicationResponse = yield startApplication();
      if (response._links.next) {
        const { href } = response._links.next;
        BUXConfig.setTokenConfig({ applicationStarted: true });
        yield put(setApplicationStarted(true));
        yield put(getNextStepForApplication(href));
      }
    } catch (e: any) {
      if (e.errorCode === BOS_APPLICATION_EXISTS_ERROR) {
        Sentry.captureMessage('Application Exists', { fingerprint: [BOS_APPLICATION_EXISTS_ERROR] });
        yield put(getExistingApplication());
      } else if (e.errorCode === BOS_APPLICATION_NOT_FOUND) {
        // try again if there is an issue with the token first time, this is a random issue that can occur after clearing cache
        Sentry.captureException(new SentryError(e), {
          fingerprint: [BOS_APPLICATION_NOT_FOUND, 'start_user_application'],
        });
        yield put(startUserApplication());
      } else {
        console.error(e);
        Sentry.captureException(new SentryError(e));
        yield put(handleOnboardingError(e));
      }
    }
  });
}

function* listenGetExistingApplication() {
  yield takeEvery(getExistingApplication, function* onGetExistingApplication() {
    try {
      const response: IApplicationResponse = yield getUserApplication();
      if (response._links.next) {
        const { href } = response._links.next;
        yield put(getNextStepForApplication(href));
      }
    } catch (e: any) {
      if (e.errorCode === BOS_APPLICATION_NOT_FOUND) {
        Sentry.captureException(new SentryError(e), {
          fingerprint: [BOS_APPLICATION_NOT_FOUND, 'get_existing_user_application'],
        });
        yield put(startUserApplication());
      } else {
        Sentry.captureException(new SentryError(e), { fingerprint: ['get_existing_user_application'] });
        console.error(e);
        yield put(handleOnboardingError(e));
      }
    }
  });
}

function* listenGetApplicationDataForStep() {
  yield takeEvery(getNextStepForApplication, function* ongetApplicationDataForStep({ payload: href }) {
    try {
      const next: unknown = yield getApplicationDataForStep(href);
      const nextStepItem = LinkToStepMapper[href] ? LinkToStepMapper[href] : OnboardingSteps.CONTINUE_ON_APP;
      const nextStep = { step: nextStepItem, payload: next };
      yield put(resumeApplication(nextStep));
    } catch (e: any) {
      Sentry.captureException(new SentryError(e));
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenGetPreviousApplicationStep() {
  yield takeEvery(gotoStepAndGetData, function* onGetPreviousApplicationStep({ payload: step }) {
    try {
      const href = step.href;
      const previous: unknown = yield getApplicationDataForStep(href);
      const nextStep = { step, payload: previous };
      yield put(resumeApplication(nextStep));
    } catch (e: any) {
      Sentry.captureException(new SentryError(e));
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenSubmitUserEmail() {
  yield takeEvery(submitUserEmail, function* onSubmitUserEmail({ payload: { href, payload } }) {
    yield put(resumeApplication({ step: OnboardingSteps.VERIFY_EMAIL, payload }));
    try {
      yield putApplicationDataForStep(href, payload);
      yield put(pollForEmailVerification());
    } catch (e: any) {
      // disable sentry here due to high requests of "The email address you have selected is already in use, please check your email or contact customer support"
      // Sentry.captureException(new SentryError(e), { fingerprint: [OnboardingSteps.VERIFY_EMAIL.toString()] });
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenSubmitFormDataForOnboardingStep() {
  yield takeEvery(
    submitFormDataForOnboardingStep,
    function* onSubmitFormDataForOnboardingStep({ payload: { href, payload, method } }) {
      try {
        const response: ILinks = yield putApplicationDataForStep(href, payload, method);
        if (response._links.next) {
          const { href } = response._links.next;
          yield put(getNextStepForApplication(href));
        }
      } catch (e: any) {
        Sentry.captureException(new SentryError(e));
        console.error(e);
        yield put(handleOnboardingError(e));
      }
    },
  );
}

function* listenSubmitFormDataForFinanceQuestions() {
  yield takeEvery(
    submitFormDataForFinanceQuestions,
    function* onSubmitFormDataForFinanceQuestions({ payload: { href, payload } }) {
      try {
        //due to backend design/bug, we need to send a PUT request then a POST request to finalize the finance questions step
        yield putApplicationDataForStep(href, payload, 'put');
        const response: ILinks = yield putApplicationDataForStep(href, payload, 'post');
        if (response._links.next) {
          const { href } = response._links.next;
          yield put(getNextStepForApplication(href));
        }
      } catch (e: any) {
        Sentry.captureException(new SentryError(e));
        console.error(e);
        yield put(handleOnboardingError(e));
      }
    },
  );
}

function* listenPollForEmailVerification() {
  yield takeEvery(pollForEmailVerification, function* onPollForEmailVerification() {
    try {
      const response: IEmailVerificationResponse = yield emailVerificationPoller(getEmailVerificationStatus, 2000);
      const { href } = response._links.next;
      yield put(getNextStepForApplication(href));
    } catch (e: any) {
      if (e.message === EMAIL_POLLING_TIME_OUT_MESSAGE) {
        yield put(handleOnboardingError({ errorCode: '408', message: e }));
      } else {
        Sentry.captureException(new SentryError(e));
        console.error(e);
        yield put(handleOnboardingError(e));
      }
    }
  });
}

function* listenForSubmitPhotoId() {
  yield takeEvery(submitPhotoId, function* onSubmitPhotoId({ payload: { href, payload } }) {
    try {
      yield putApplicationDataForStep(href, payload);
      yield put(resumeApplication({ step: OnboardingSteps.PROOF_OF_ID_VERIFICATION, payload }));
    } catch (e: any) {
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenForSubmitSelfie() {
  yield takeEvery(submitSelfie, function* onSubmitSelfie({ payload: { href, payload } }) {
    try {
      yield putApplicationDataForStep(href, payload, 'post');
      yield put(resumeApplication({ step: OnboardingSteps.SELFIE_VERIFICATION, payload }));
    } catch (e: any) {
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenPollForVerification() {
  yield takeEvery(pollForVerification, function* onPollForVerification({ payload: { path } }) {
    try {
      const response: IVerificationPayload = yield getVerificationStatus(path);
      const selectPollingAttempt = (state: IRootState) => state.onboarding.pollingAttempt;
      const attemptNumber: number = yield select(selectPollingAttempt);
      if (response._inProgress && attemptNumber <= MAX_POLL_PHOTO_ID) {
        yield delay(5000);
        yield put(pollForVerification({ path }));
      }
      if (!response._inProgress) {
        yield put(getExistingApplication());
      }
    } catch (e: any) {
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenRegisterSessionIdForSentry() {
  yield takeEvery(registerSentrySessionId, function* onNewUser() {
    try {
      const anonIdSelect = (state: IRootState) => state.onboarding.anonymousId;
      // check state first then localstorage
      const existingAnonIdState: string | null = yield select(anonIdSelect);
      if (!existingAnonIdState) {
        const existingAnonIdStateLocalStorage: string | null = yield call(retrieveSentrySessionId);
        if (!existingAnonIdStateLocalStorage) {
          const anonymousId = uuidv4();
          yield call(storeSentrySessionId, anonymousId);
          yield put(setSentrySessionId({ anonymousId }));
        } else {
          yield put(setSentrySessionId({ anonymousId: existingAnonIdStateLocalStorage }));
        }
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  });
}

function* listenSubmitSkipTaxStep() {
  yield takeEvery(submitSkipTaxStep, function* onSubmitSkipTaxStep() {
    try {
      const response: ILinks = yield skipTaxStep(OnboardingSteps.TAX_NUMBER.href);
      if (response._links.next) {
        const { href } = response._links.next;
        yield put(getNextStepForApplication(href));
      } else {
        yield put(getExistingApplication());
      }
    } catch (e: any) {
      Sentry.captureException(new SentryError(e));
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenFetchCustomData() {
  yield takeLatest(fetchCustomData, function* onFetchCustomData({ payload: { url, key } }) {
    try {
      const response: unknown = yield getApplicationDataForStep(url);

      yield put(saveFetchedCustomData({ data: response, key }));
    } catch (e: any) {
      Sentry.captureException(new SentryError(e));
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* listenSubmitPhoneVerificationCode() {
  yield takeLatest(submitPhoneVerification, function* onSubmitPhoneVerification({ payload: { href, payload } }) {
    try {
      yield putApplicationDataForStep(href, payload, 'post');
      yield put(resumeApplication({ step: OnboardingSteps.PHONE_VERIFICATION_SUCCESS, payload }));
    } catch (e: any) {
      Sentry.captureException(new SentryError(e));
      console.error(e);
      yield put(handleOnboardingError(e));
    }
  });
}

function* createStocksGuestAccount() {
  yield takeEvery(createGuestAccount, function* onCreateStocksGuestAccount() {
    try {
      yield createStockGuestAccount();
    } catch (e: any) {
      Sentry.captureException(new SentryError(e));
      console.error(e);
    }
  });
}

export default function* registerSagas() {
  yield all([
    listenStartUserApplication(),
    listenGetExistingApplication(),
    listenGetApplicationDataForStep(),
    listenGetPreviousApplicationStep(),
    listenSubmitUserEmail(),
    listenForSubmitPhotoId(),
    listenSubmitFormDataForOnboardingStep(),
    listenSubmitFormDataForFinanceQuestions(),
    listenPollForEmailVerification(),
    listenPollForVerification(),
    listenRegisterSessionIdForSentry(),
    listenSubmitSkipTaxStep(),
    listenFetchCustomData(),
    listenForSubmitSelfie(),
    listenSubmitPhoneVerificationCode(),
    createStocksGuestAccount(),
  ]);
}
