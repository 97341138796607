import { materialThemeWithOverrides, emotionTheme } from './';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

function ThemeProvider({ children }: React.PropsWithChildren<unknown>) {
  return (
    <EmotionThemeProvider theme={emotionTheme}>
      <MuiThemeProvider theme={materialThemeWithOverrides}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </EmotionThemeProvider>
  );
}

export default ThemeProvider;
