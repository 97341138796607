import { LoadingContainer } from '../../../../common/components';
import { CircularProgress } from '../../../../theme/components';

export const LoadingView = () => {
  return (
    <LoadingContainer data-testid="loading-view">
      <CircularProgress />
    </LoadingContainer>
  );
};
