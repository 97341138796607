import React, { useCallback, useEffect } from 'react';
import FeedbackLayout, { ContentWrapper, Spacer } from '../../../../common/components/FeedbackLayout';
import { Graphic } from '../../../../common/components';
import welcomegraphic from '../../../../../assets/images/welcome.svg';
import { Button, Typography } from '../../../../theme/components';
import { ButtonsContainer } from '../../../../common/components/FormLayout';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { useQRModal } from '../continueonapp/QRModal';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { LINK_TO_APP } from '../../../../common/constants';
import { getExistingApplication } from '../../../store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRudderAnalytics } from '../../../../../config/rudderstack';

const EmailAlreadyExists = () => {
  const { t } = useTranslation();
  const rudderAnalytics = useRudderAnalytics();
  const modal = useQRModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const toggleModal = () => {
    if (!isMobile) {
      !modal.isOpen ? modal.open() : modal.close();
    } else {
      window.open(LINK_TO_APP, '_blank');
    }
  };

  useEffect(() => {
    rudderAnalytics && rudderAnalytics.track('onboarding_web_email_exists');
  }, []);

  const handleUseDifferentEmail = useCallback(() => dispatch(getExistingApplication()), []);
  return (
    <FeedbackLayout>
      <Spacer />
      <ContentWrapper marginBottom={0}>
        <Graphic image={welcomegraphic} />
        <Typography variant="h2" gutterBottom>
          {t('email_exists_title')}
        </Typography>
        <ButtonsContainer flexDirection="column" marginTop={isMobile ? 0 : 1}>
          <Button fullWidth={isMobile} onClick={toggleModal} id="web-onboarding-email-exists-finish-in-app">
            {t('common_continue_in_app')}
          </Button>
          <Button
            variant={isMobile ? 'secondary' : 'text'}
            onClick={handleUseDifferentEmail}
            fullWidth={isMobile}
            id="web-onboarding-email-exists-use-different-email"
          >
            {t('email_exists_use_different')}
          </Button>
        </ButtonsContainer>
      </ContentWrapper>
      <Spacer minHeight={60}>
        <Typography variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </Spacer>
    </FeedbackLayout>
  );
};

export default EmailAlreadyExists;
