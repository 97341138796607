import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { IAuthUserState, authReducers, authSagas } from './modules/auth/store';
import { onboardingReducers, IOnboardingState, onboardingSagas } from './modules/onboarding/store';
import * as Sentry from '@sentry/react';

export interface IRootState {
  auth: IAuthUserState;
  onboarding: IOnboardingState;
}

export function getStore() {
  const rootReducer = combineReducers({
    auth: authReducers,
    onboarding: onboardingReducers,
  });

  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = composeWithDevTools({});
  const rootSaga = function* () {
    yield all([authSagas(), onboardingSagas()]);
  };

  // anonymize some data and remove payload.
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: () => {
      return null;
    },

    stateTransformer: (state: IRootState) => {
      if (state.onboarding.currentStep.payload) {
        return null;
      }

      const transformedState: IRootState = {
        ...state,
        auth: {
          ...state.auth,
          userId: 'anonymous',
          userToken: 'null',
        },
        onboarding: {
          ...state.onboarding,
          currentStep: {
            ...state.onboarding.currentStep,
            payload: {},
          },
        },
      };
      return transformedState;
    },
  });

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer));

  sagaMiddleware.run(rootSaga);

  return store;
}
