import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const PhoneIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <g transform={`translate(${translateValue},${translateValue})`}>
      <path
        d="M7.5 1.75H16.5C17.4665 1.75 18.25 2.5335 18.25 3.5V20.5C18.25 21.4665 17.4665 22.25 16.5 22.25H7.5C6.5335 22.25 5.75 21.4665 5.75 20.5V3.5C5.75 2.5335 6.5335 1.75 7.5 1.75Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <line x1="6.75" y1="5.25" x2="17.25" y2="5.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="6.75" y1="19.25" x2="17.25" y2="19.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    </g>
  );
};

export default PhoneIcon;
