import { TFunction } from 'react-i18next';

const noDigitsValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  let error;
  const regex = new RegExp(/^([^0-9]*)$/);
  if (!regex.test(value)) {
    error = fieldName ? t('common_error_no_digits_dynamic', { field: fieldName }) : `${t('common_error_no_digits')}`;
  }
  return error;
};

export default noDigitsValidator;
