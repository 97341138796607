import { apiConfig } from './apiConfig';
import BUXConfig from '../buxConfig';

export enum HTTPMethods {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

interface IFetchRequestOptions {
  method: HTTPMethods;
  data?: any;
  headers?: { [key: string]: string };
}

/**
 * Unwrap response from fetch request
 * For 204 no content just resolve empty object
 * For successful http statuses unwrap json and resolve promise
 * For unsuccessful http statuses unwrap json and reject promise
 * @param response
 */
async function getResponse(response: any) {
  if (response.status === 204) {
    return Promise.resolve({});
  } else if (response.status >= 200 && response.status < 300) {
    const json = await response.json();
    return Promise.resolve(json);
  } else {
    const error = await response.json();
    return Promise.reject(error);
  }
}

/**
 * Fetch wrapper for requests
 * Attaches Auth header and JSON.stringifys the body
 * Unwraps the response
 * @param url
 * @param options
 */
export const fetchRequest = async (url: string, options?: IFetchRequestOptions) => {
  const { userToken } = BUXConfig.getTokenConfig();
  const lang = localStorage.getItem('i18nextLng');

  const requestOptions: any = {
    method: options?.method ? options.method : HTTPMethods.GET,
    body: JSON.stringify(options?.data),
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Accept-Language': lang,
      ...options?.headers,
    },
  };

  try {
    const response = await fetch(`${apiConfig.api.baseUrl}${url}`, requestOptions);
    return await getResponse(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
