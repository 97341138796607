import React, { useState } from 'react';
import { Button, TextField, Typography } from '../../../../theme/components';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IErrorResponse } from '../../../../common/models';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { transactionCodeValidator } from '../../../utils/validators';
import { ITransactionsStepOption, ITransactionValues, KeyBoardType } from '../../../models/onboarding.models';
import ResponsiveSimpleModal from '../../../../theme/components/ResponsiveSimpleModal';

interface ITransactionStepFormProps {
  initialValues: ITransactionValues;
  onSubmit: (values: ITransactionValues) => void;
  onSkip: (values: ITransactionValues) => void;
  error: IErrorResponse | null;
  isLoading: boolean;
  transactionStepPayload: ITransactionsStepOption;
}

const useStyles = makeStyles(() => ({
  inputStyle: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const TransactionStepForm = ({
  initialValues,
  onSubmit,
  onSkip,
  error,
  isLoading,
  transactionStepPayload,
}: React.PropsWithChildren<ITransactionStepFormProps>) => {
  const { inputStyle } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const formik = useFormik({ initialValues, onSubmit });
  const [showSkipModal, setShowSkipModal] = useState(false);

  const handleShowSkipConfirmation = () => {
    setShowSkipModal(true);
  };

  const closeModal = () => {
    setShowSkipModal(false);
  };

  const confirmSkip = () => {
    onSkip(initialValues);
  };

  return (
    <>
      <>
        <FormLayout>
          <Typography variant="h2" gutterBottom={true}>
            {t('transactions_question', { transactionName: transactionStepPayload.displayName })}
          </Typography>
          <FormContent>
            <FormikProvider value={formik}>
              <Form>
                <FormFieldsWrapper>
                  {error && (
                    <>
                      <Typography color="error" variant="subtitle2">
                        {t('common_error_sorry_there_is_a_problem')}
                      </Typography>
                      <Typography color="error" variant="caption" component="p" gutterBottom>
                        {t('common_error_please_try_again')}
                      </Typography>
                    </>
                  )}
                  <Typography gutterBottom={true}>{t('transactions_disclaimer')}</Typography>
                  <Field
                    className={inputStyle}
                    type={transactionStepPayload.keyboardType === KeyBoardType.NUMERIC ? 'tel' : 'text'}
                    component={TextField}
                    name="identityNumber"
                    id="identityNumber"
                    placeholder={transactionStepPayload.example}
                    validate={transactionCodeValidator(
                      t,
                      transactionStepPayload.example,
                      transactionStepPayload.validationRule,
                    )}
                    fullWidth={true}
                  />
                </FormFieldsWrapper>
                <ButtonsContainer flexDirection="column" marginTop={isMobile ? 0 : 1}>
                  <Button fullWidth={isMobile} type="submit" loading={isLoading}>
                    {t('common_continue')}
                  </Button>
                  {transactionStepPayload.skippable && (
                    <Button
                      variant={isMobile ? 'secondary' : 'text'}
                      onClick={handleShowSkipConfirmation}
                      fullWidth={isMobile}
                    >
                      {t('transactions_no_document')}
                    </Button>
                  )}
                </ButtonsContainer>
              </Form>
            </FormikProvider>
          </FormContent>
        </FormLayout>
        <ResponsiveSimpleModal
          open={showSkipModal}
          primaryButton={{ handle: confirmSkip, label: t('common_confirm') }}
          secondaryButton={{ handle: closeModal, label: t('common_go_back_variation') }}
          handleClose={closeModal}
          title={t('common_confirmation_title')}
          subtitle={t('transactions_no_document_confirm', { transactionName: transactionStepPayload.displayName })}
        />
      </>
    </>
  );
};

export default TransactionStepForm;
