import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const ProofIdIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <g transform={`translate(${translateValue},${translateValue})`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.6923C14.6764 12.6923 16.8461 10.5226 16.8461 7.84615C16.8461 5.1697 14.6764 3 12 3C9.32354 3 7.15384 5.1697 7.15384 7.84615C7.15384 10.5226 9.32354 12.6923 12 12.6923Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M3 20.9999C3 20.9999 6.56601 15.4614 12.0565 15.4614C17.5471 15.4614 21 20.9999 21 20.9999"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  );
};

export default ProofIdIcon;
