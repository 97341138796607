import React, { FC } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

import FeedbackLayout, { Spacer } from '../../../../common/components/FeedbackLayout';
import { Typography } from '../../../../theme/components';
import { theme } from '../../../../theme';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import QRStepMobile from './QRStepMobile';
import QRStepDesktop from './QRStepDesktop';

export const ContinueOnAppStep: FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <>
      <FeedbackLayout>
        <Spacer />
        {isMobile ? <QRStepMobile /> : <QRStepDesktop />}
        <Spacer minHeight={60}>
          <Typography variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
            {t('common_have_questions')} <ChatWithSupport />
          </Typography>
        </Spacer>
      </FeedbackLayout>
    </>
  );
};

export default ContinueOnAppStep;
