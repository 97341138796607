import getEnvironment from '../../onboarding/utils/getEnvironment';

export const MAX_POLL_PHOTO_ID = 30;
export const DEV_EMAIL_TO_BYPASS_VERIFICATION = 'ada.lovelace@getbux.com';

export const GOOGLE_LOGIN_SCOPES =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

export const getGoogleClientId = () => {
  const env = getEnvironment();
  switch (env) {
    case 'local':
    case 'development':
      return '947135347717-j7lmgm6ffi5skjb64qh34jocc0sqlc04.apps.googleusercontent.com';
    case 'acceptance':
      return '956583292147-uj1e5ni50g6rkijoiqkp9qi9n7qq83h4.apps.googleusercontent.com';
    case 'production':
      return '951022323128-i1nkhje8k28at07tb59miutq0mhjmnjd.apps.googleusercontent.com';
    default:
      return '951022323128-i1nkhje8k28at07tb59miutq0mhjmnjd.apps.googleusercontent.com';
  }
};
