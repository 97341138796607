/*
    Drawing Parameters.

    For ideal centering of the icons inside the circles, keep the radius as 24, as
    the custom icons are designed with a bounding box of 24px in figma.
 */

// circle

export const smallRadius = 16;
export const radius = 24;
export const circumference = Math.PI * (radius * 2);

// gaps (spacing between circles)
export const mobileGap = 24;
export const desktopGap = 48;

// initial coordinates
export const extraSideMargin = 10;
export const textYSpacing = 40;
export const initialStartX = radius * 2 + extraSideMargin;
export const initialStartY = radius * 2;

/*
    ViewBox sizing:
        - width: calculate total gaps and total progress circles radius and side margins
        - height: take the initial start Y and multiply it by a factor of 2.5 (can be changed)
 */
const smallCirclesWidth = (numOfElements: number) => (numOfElements - 1) * smallRadius * 2;
const bigCircleWidth = radius * 2;
const spacesBetweenCircles = (gap: number, numOfElements: number) => (numOfElements - 1) * gap;
const sideMargins = initialStartX + extraSideMargin;
export const widthOfViewBox = (gap: number, numOfElements: number): number =>
  smallCirclesWidth(numOfElements) + bigCircleWidth + spacesBetweenCircles(gap, numOfElements) + sideMargins;
export const heightOfViewBox: number = initialStartY * 2.5;

export const calculateXCoordinate = (key: number, gap: number, currentStepIndex: number): number => {
  let startX = initialStartX;

  const currentStepIndexSafe = currentStepIndex ? currentStepIndex : key;

  if (currentStepIndexSafe === key && currentStepIndexSafe === 0) {
    return startX;
  } else if (key < currentStepIndexSafe) {
    const completedCircles = key * smallRadius * 2;
    const totalGaps = key * gap;
    startX += completedCircles + totalGaps;
    return startX;
  } else if (key === currentStepIndexSafe) {
    const completedCircles = key * smallRadius * 2;
    const totalGaps = key * gap;
    startX += completedCircles + totalGaps;
    return startX;
  } else if (currentStepIndexSafe < key) {
    const completedCircles = key * smallRadius * 2 - radius * 2;
    const activeCircle = radius * 2;
    const totalGaps = key * gap;
    startX += completedCircles + activeCircle + totalGaps;
    return startX;
  } else {
    return startX;
  }
};
