import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52935 24.5303C8.23646 24.8232 7.76159 24.8232 7.46869 24.5303C7.1758 24.2374 7.1758 23.7626 7.46869 23.4697L14.9384 16L7.4687 8.53033C7.1758 8.23744 7.1758 7.76256 7.4687 7.46967C7.76159 7.17678 8.23646 7.17678 8.52936 7.46967L15.999 14.9393L23.4687 7.46967C23.7616 7.17678 24.2365 7.17678 24.5294 7.46967C24.8222 7.76257 24.8222 8.23744 24.5294 8.53033L17.0597 16L24.5294 23.4697C24.8222 23.7626 24.8222 24.2374 24.5294 24.5303C24.2365 24.8232 23.7616 24.8232 23.4687 24.5303L15.999 17.0607L8.52935 24.5303Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default CloseIcon;
