import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    UC_UI: {
      updateLanguage: (lng: string) => void;
      isInitialized: () => boolean;
    };
  }
}

const useUserCentrics = () => {
  const [isUCInitialized, setIsUCInitialized] = useState<boolean>(false);
  const [isUCActionGiven, setIsUCActionGiven] = useState<boolean>(false);
  const [isRudderStackConsentGiven, setIsRudderStackConsentGiven] = useState<boolean>(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const ucEventListener = (event: any) => {
      setIsUCInitialized(true);
      const actions = ['onAcceptAllServices', 'onUpdateServices', 'onDenyAllServices'];
      if (actions.includes(event?.detail?.action)) {
        setIsUCActionGiven(true);
      }
      if (event?.detail?.event == 'consent_status') {
        // check for consent status of service "RudderStack"
        setIsRudderStackConsentGiven(event.detail['RudderStack'] === true);
      }
    };

    window.addEventListener('ucEvent', ucEventListener);
    return () => {
      window.removeEventListener('ucEvent', ucEventListener);
    };
  }, []);

  const updateLanguage = (lng: string) => {
    if (window.UC_UI && window.UC_UI.isInitialized()) {
      window.UC_UI.updateLanguage(lng);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateLanguage(i18n.language);
    }, 500);
  }, [isUCInitialized, i18n.language]);

  return { isUCInitialized, isRudderStackConsentGiven, isUCActionGiven };
};

export default useUserCentrics;
