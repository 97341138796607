import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../../../assets/images/bux-by-abn-logo.svg';
import { AppBar } from '../../theme/components';
import { useMediaQuery, useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.common.white,
  },
}));

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { appBar } = useStyles();

  return isMobile ? null : (
    <AppBar className={appBar}>
      <img style={{ height: '60px' }} src={logo} />
      <span style={{ flex: 1 }} />
      <LanguageSwitcher />
    </AppBar>
  );
}

export default Header;
