import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '../../theme/components';
import { useFreshChat } from '../../../config/freshChat';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
  cursor: pointer;
`;

interface IChatWithSupport {
  lowerCase?: boolean;
}

export const ChatWithSupport: FC<IChatWithSupport> = ({ lowerCase = false }) => {
  const { t } = useTranslation();
  const { openFreshChat } = useFreshChat();

  const onButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openFreshChat();
  };

  return (
    <StyledLink onClick={onButtonClick}>
      {lowerCase ? t('common_chat_with_support').toString().toLowerCase() : t('common_chat_with_support')}
    </StyledLink>
  );
};

export default ChatWithSupport;
