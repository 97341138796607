import { TFunction } from 'react-i18next';

const genderValidator = (t: TFunction<'translation'>) => (value: any) => {
  let error;
  if (!value) {
    error = t('personal_details_error_select_gender');
  }
  return error;
};

export default genderValidator;
