import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from '../translations';

export const languages = [
  { lang: 'en', displayName: 'EN', title: 'English' },
  { lang: 'de', displayName: 'DE', title: 'Deutsch' },
  { lang: 'nl', displayName: 'NL', title: 'Nederlands' },
  { lang: 'fr', displayName: 'FR', title: 'Français' },
  { lang: 'es', displayName: 'ES', title: 'Español' },
  { lang: 'it', displayName: 'IT', title: 'Italiano' },
];

export const langCodes = languages.map((l) => l.lang);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: langCodes,
    fallbackLng: 'en',
    returnObjects: true,
    debug: false,
    detection: {
      order: ['querystring', 'path', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
      lookupQuerystring: 'lng',
      lookupSessionStorage: 'i18nextLng',
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
