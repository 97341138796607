import isFuture from 'date-fns/isFuture';
import parseJSON from 'date-fns/parseJSON';
import { BUX_COOKIE } from '../cookieconsent/constants';
import { getCookie } from '../cookieconsent/helpers/cookies';

interface BUXConfig {
  userToken: string;
  userId: string;
  expiryDateTime: Date;
  isNewUser: boolean;
  applicationStarted: boolean;
  expired?: boolean;
}

const getTokenConfig = (): BUXConfig => {
  const buxCookie = decodeURIComponent(getCookie(BUX_COOKIE));

  if (buxCookie) {
    const parsedConfig = JSON.parse(buxCookie, (key, value) => {
      if (key === 'expiryDateTime') {
        return parseJSON(value);
      }
      return value;
    });
    return { ...parsedConfig, expired: !isFuture(parsedConfig.expiryDateTime) };
  }
  return {} as BUXConfig;
};

const setTokenConfig = (newValues: Partial<BUXConfig>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expired, ...existingValues } = getTokenConfig();
  const config: BUXConfig = { ...existingValues, ...newValues };
  const cookieValue = encodeURIComponent(JSON.stringify(config));

  document.cookie = `${BUX_COOKIE}=${cookieValue}; expires=${config.expiryDateTime.toUTCString()}; path=/; samesite`;
};

const BUXConfig = {
  getTokenConfig,
  setTokenConfig,
};

export default BUXConfig;
