import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Maison Neue, sans-serif',
  fontWeightRegular: 400,
  allVariants: { letterSpacing: 0 },
  caption: { fontSize: 12, lineHeight: '18px' },
  subtitle1: { fontSize: 18, fontWeight: 'normal', lineHeight: '24px' },
  subtitle2: { fontSize: 12, fontWeight: 'bold', lineHeight: '18px' },
  body1: { fontSize: 20, lineHeight: '28px' },
  body2: { fontSize: 16, lineHeight: '22px' },
  h2: { fontSize: 34, fontWeight: 'bold', lineHeight: '40px' },
  h4: { fontSize: 26, fontWeight: 'bold', lineHeight: '32px' },
};

export default typography;
