/**
 * Polling function which will poll the passed in request for
 * the max number of tries every interval
 */

import { EMAIL_POLLING_TIME_OUT_MESSAGE } from '../../common/constants';
import { IEmailVerificationResponse } from '../models/onboarding.models';
import * as Sentry from '@sentry/react';
import SentryError from '../utils/SentryError';
import { IErrorResponse } from '../../common/models';

const DEFAULT_MAX_TRIES = 15;

const emailVerificationPoller = async (
  request: () => Promise<IEmailVerificationResponse>,
  intervalInMilliseconds: number,
  maxTries = DEFAULT_MAX_TRIES,
) => {
  let tries = 0;
  const polling = async (resolve: any, reject: any) => {
    tries++;
    try {
      const result: IEmailVerificationResponse = await request();
      const { href } = result._links.next;

      // due to some race condition on BE we can't just check verified but also need to make sure _links and _inprogress is also updated
      if (result.verified && !result._inProgress && href.indexOf('personal_details') !== -1) {
        return resolve(result);
      } else if (tries === maxTries) {
        return reject(new Error(EMAIL_POLLING_TIME_OUT_MESSAGE));
      } else {
        setTimeout(polling, intervalInMilliseconds, resolve, reject);
      }
    } catch (e) {
      Sentry.captureException(new SentryError(e as IErrorResponse));
      return reject(e);
    }
  };

  return new Promise(polling);
};

export default emailVerificationPoller;
