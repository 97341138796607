import { FormikConfig, useFormik } from 'formik';
import { useEffect } from 'react';

type Config = FormikConfig<any> & {
  showErrors: boolean;
  getErrors: () => { [key: string]: string };
};

function useFormikWithErrors({ showErrors, getErrors, ...formikConfig }: Config) {
  const formik = useFormik(formikConfig);
  useEffect(() => {
    if (showErrors) {
      formik.setSubmitting(false);
      const errors = getErrors();
      formik.setErrors(errors);
    }
  }, [showErrors]);
  return formik;
}

export default useFormikWithErrors;
