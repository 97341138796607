import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { OnboardingSteps } from '../models/onboarding.models';
import { gotoStep, gotoStepAndGetData, submitFormDataForOnboardingStep } from '../store';

export type StepComponent = {
  onPrevious: () => void;
  onNext: (dataToSubmit: any) => void;
  showPrevious?: boolean;
};

const StepRoute: React.FC<{
  path: string | string[];
  currentStep: OnboardingSteps;
  component: React.FC<StepComponent>;
  previousStep?: OnboardingSteps;
  nextStep?: OnboardingSteps;
}> = ({ component: StepComponent, path, currentStep, previousStep, nextStep }) => {
  const dispatch = useDispatch();

  const handleGoToStep = useCallback(
    (step: OnboardingSteps, dataToSubmit?: any) => {
      if (currentStep.href !== step.href && dataToSubmit) {
        dispatch(
          submitFormDataForOnboardingStep({
            href: currentStep.href,
            payload: dataToSubmit,
          }),
        );
      } else if (currentStep.href !== step.href && !dataToSubmit) {
        dispatch(gotoStepAndGetData(step));
      } else {
        dispatch(gotoStep(step));
      }
    },
    [currentStep],
  );

  return (
    <Route path={path}>
      <StepComponent
        onPrevious={() => previousStep && handleGoToStep(previousStep)}
        onNext={(dataToSubmit) => nextStep && handleGoToStep(nextStep, dataToSubmit)}
        showPrevious={!!previousStep}
      />
    </Route>
  );
};

export default StepRoute;
