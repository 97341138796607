import React, { useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import { DEV_EMAIL_TO_BYPASS_VERIFICATION } from '../../../../../common/constants';
import { ButtonsContainer, FormFieldsWrapper } from '../../../../../common/components/FormLayout';
import { Button, TextField } from '../../../../../theme/components';
import { emailValidator } from '../../../../utils/validators';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import getEnvironment from '../../../../utils/getEnvironment';
import { useTranslation } from 'react-i18next';
import { ISubmitApplicationPayload, OnboardingSteps } from '../../../../models/onboarding.models';
import { submitUserEmail } from '../../../../store';
import { useRudderAnalytics } from '../../../../../../config/rudderstack';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  emailField: {
    marginBottom: theme.spacing(3),
  },
}));

const CommonEmailInput: React.FC<{ featureFlagOn?: boolean }> = ({ featureFlagOn }) => {
  const { emailField } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const rudderAnalytics = useRudderAnalytics();
  const dispatch = useDispatch();

  const isDev = getEnvironment() === 'development' || getEnvironment() === 'local';

  const handleSubmit = useCallback((values: unknown) => {
    const data: ISubmitApplicationPayload = {
      href: OnboardingSteps.EMAIL.href,
      payload: values,
    };
    rudderAnalytics && rudderAnalytics.track('email_submitted');
    dispatch(submitUserEmail(data));
  }, []);
  return (
    <Formik
      initialValues={{
        email: (isDev && DEV_EMAIL_TO_BYPASS_VERIFICATION) || '',
      }}
      onSubmit={(values: any) => handleSubmit(values)}
    >
      <Form noValidate>
        <FormFieldsWrapper marginTop={3}>
          <Field
            component={TextField}
            id="email"
            name="email"
            label={t('common_email_address')}
            placeholder="email@example.com"
            type="email"
            fullWidth={true}
            validate={emailValidator(t, t('common_email_address'))}
            className={emailField}
          />
        </FormFieldsWrapper>
        <ButtonsContainer marginTop={9} alignCenter>
          <Button type="submit" fullWidth={isMobile} variant="secondary" id="web-onboarding-enter-email">
            {featureFlagOn ? t('email_continue_button') : t('common_continue')}
          </Button>
        </ButtonsContainer>
      </Form>
    </Formik>
  );
};

export default CommonEmailInput;
