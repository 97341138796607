import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const QuestionnaireIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <g transform={`translate(${translateValue},${translateValue})`}>
      <path
        d="M20.5 6.40374C21.25 6.40374 21.25 6.40331 21.25 6.40288L21.25 6.40199L21.25 6.40011L21.25 6.39596L21.2498 6.38615L21.2491 6.36067C21.2483 6.34118 21.247 6.31658 21.2446 6.2876C21.2399 6.2299 21.2308 6.15327 21.2136 6.06372C21.1798 5.88756 21.1109 5.6442 20.9667 5.39397C20.6499 4.84441 20.0362 4.3833 19.0354 4.3833H4.46353C3.34446 4.3833 2.25 5.19165 2.25 6.40374V19.0184C2.25 20.2305 3.34446 21.0389 4.46353 21.0389H19.0354C20.0362 21.0389 20.6499 20.5778 20.9667 20.0282C21.1109 19.778 21.1798 19.5346 21.2136 19.3584C21.2308 19.2689 21.2399 19.1923 21.2446 19.1346C21.247 19.1056 21.2483 19.081 21.2491 19.0615L21.2498 19.036L21.25 19.0262L21.25 19.0221L21.25 19.0202L21.25 19.0193C21.25 19.0188 21.25 19.0184 20.5 19.0184H21.25V6.40374H20.5Z"
        stroke="#999999"
        strokeWidth="1.5"
      />
      <path
        d="M15.7111 10.2499C14.3519 10.2499 13.25 11.3518 13.25 12.7111C13.25 14.0703 14.3519 15.1722 15.7111 15.1722H21.0833C21.7277 15.1722 22.25 14.6498 22.25 14.0055V11.4166C22.25 10.7723 21.7277 10.2499 21.0833 10.2499H15.7111Z"
        fill="#FCFCFC"
        stroke="#999999"
        strokeWidth="1.5"
      />
    </g>
  );
};

export default QuestionnaireIcon;
