import React, { useMemo, useState, useEffect } from 'react';
import { RudderAnalyticsProvider } from '../../config/rudderstack';
import BUXConfig from '../common/buxConfig';
import CookiePolicyProvider from '../cookieconsent/components/CookiePolicyProvider';
import QRModalProvider from '../onboarding/components/steps/continueonapp/QRModal';
import combineProviders from './helpers/combineProviders';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useUserCentrics from '../cookieconsent/helpers/useUserCentrics';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import itLocale from 'date-fns/locale/it';
import nlLocale from 'date-fns/locale/nl';
import esLocale from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';

const providers = [RudderAnalyticsProvider, QRModalProvider];

const CombinedProviders = combineProviders(...providers);

export const AppContext = React.createContext({ applicationInProgressOnInit: false });

const localeMap: { [key: string]: any } = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
  nl: nlLocale,
  it: itLocale,
  es: esLocale,
};

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const { isUCInitialized, isRudderStackConsentGiven, isUCActionGiven } = useUserCentrics();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState('en');

  const applicationInProgressOnInit = useMemo(() => {
    const { applicationStarted, expired } = BUXConfig.getTokenConfig();
    return !expired && (applicationStarted || false);
  }, []);

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  return (
    <AppContext.Provider value={{ applicationInProgressOnInit }}>
      <CookiePolicyProvider
        isUCInitialized={isUCInitialized}
        isUCActionGiven={isUCActionGiven}
        isRudderStackConsentGiven={isRudderStackConsentGiven}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
          <CombinedProviders>{children}</CombinedProviders>
        </MuiPickersUtilsProvider>
      </CookiePolicyProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
