import { Content, ContentWrapper } from './modules/common/components/Layout';
import Onboarding from './modules/onboarding/components/Onboarding';
import Header from './modules/common/components/Header';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AppProvider from './modules/context/AppContext';
import Welcome from './modules/onboarding/components/Welcome';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from './modules/common/components/errors';
import { GridLayout } from './modules/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './Store';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FreshChatLoadingSpinner from './modules/common/components/FreshChatLoadingSpinner';
import { handleOnboardingError, registerSentrySessionId } from './modules/onboarding/store';

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const history = useHistory();
  const { applicationStarted } = useSelector((state: IRootState) => state.auth);
  const {
    loading,
    currentStep: { step },
    anonymousId,
  } = useSelector((state: IRootState) => state.onboarding);

  useEffect(() => {
    if (!loading) {
      const lng = i18n.language;
      const locale = lng === 'en' ? '' : `/${lng}`;
      const stepPath = step ? step.path : '';
      const path = `${locale}${stepPath}`;
      history.push({ pathname: path, search: location.search });
    }
  }, [step, loading, history, i18n.language, location.search]);

  useEffect(() => {
    setTimeout(() => {
      const errorCode = new URLSearchParams(location.search).get('error');
      if (errorCode) {
        dispatch(handleOnboardingError({ errorCode, message: '' }));
      }
    }, 10);
  }, [location.search]);

  useEffect(() => {
    anonymousId ? Sentry.setUser({ id: anonymousId }) : dispatch(registerSentrySessionId());
  }, [anonymousId]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <GridLayout>
        <Header />
        <ContentWrapper>
          <Content>
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              <AppProvider>
                {!applicationStarted ? <Welcome /> : <Onboarding />}
                <FreshChatLoadingSpinner />
              </AppProvider>
            </Sentry.ErrorBoundary>
          </Content>
        </ContentWrapper>
      </GridLayout>
    </HelmetProvider>
  );
}

export default Sentry.withProfiler(App);
