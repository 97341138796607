import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { useTheme } from '@material-ui/core';

const GoogleIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 21 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2685 5.33718C13.5688 4.66168 12.3416 3.85336 10.5224 3.85336C7.96043 3.85336 5.78597 5.59183 5.00007 7.99466L4.99989 7.99451L4.9999 7.99463C4.80613 8.61471 4.68777 9.27906 4.68777 9.96562C4.68777 10.652 4.80611 11.3164 5.01059 11.9364L5.01083 11.9364C5.78597 14.3392 7.96043 16.0776 10.5224 16.0776C11.9648 16.0776 13.0627 15.668 13.8808 15.0812L13.8809 15.081C15.1726 14.1509 15.7539 12.7668 15.8615 11.8477H10.5223V8.14941H19.6185C19.7584 8.76949 19.823 9.36743 19.823 10.1868C19.823 13.2208 18.768 15.7786 16.9381 17.5171L16.9382 17.5171C15.3342 19.0452 13.1382 19.931 10.5224 19.931C6.73323 19.931 3.46076 17.6943 1.86758 14.4389L1.86737 14.4391C1.21075 13.0881 0.833984 11.5712 0.833984 9.96562C0.833984 8.36005 1.21075 6.84303 1.86737 5.49211L1.86762 5.49199C3.46081 2.23665 6.73326 0 10.5224 0C13.1382 0 15.3235 0.985496 17.0028 2.59107L14.2685 5.33718Z"
        fill={theme.palette.common.white}
      />
    </SvgIcon>
  );
};

export default GoogleIcon;
