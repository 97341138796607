export function getCookie(cname: string) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function setCookie(
  cname: string,
  value?: string,
  days?: number,
  domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.getbux.com',
  path = '/',
) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = ';expires=' + date.toUTCString();
  }
  document.cookie = `${cname}=${value || ''}${expires};domain=${domain};path=${path}`;
}

export function checkCookieValue(cname: string, value: string) {
  const cookie = getCookie(cname);
  if (cookie === value) {
    return true;
  }
  return false;
}

export function checkCookieNotNull(cname: string) {
  const value = getCookie(cname);
  if (typeof value !== 'undefined' && value !== null && value !== '') {
    return true;
  }
  return false;
}
