import { Typography } from '@material-ui/core';
import { Graphic } from '..';
import FeedbackLayout, { Spacer, ContentWrapper, Content } from '../FeedbackLayout';
import maintenanceimage from '../../../../assets/images/maintenance.svg';
import ChatWithSupport from '../ChatWithSupport';
import { useTranslation } from 'react-i18next';

const MaintenanceError = () => {
  const { t } = useTranslation();

  return (
    <FeedbackLayout>
      <Spacer />
      <ContentWrapper>
        <Graphic image={maintenanceimage}></Graphic>
        <Typography variant="h2" gutterBottom>
          {t('errors_maintenance_title')}
        </Typography>
        <Content>
          <Typography>{t('errors_maintenance_descriptive_text')}</Typography>
        </Content>
      </ContentWrapper>
      <Spacer disableMinHeight>
        <Typography gutterBottom>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </Spacer>
    </FeedbackLayout>
  );
};

export default MaintenanceError;
