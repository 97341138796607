import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '../icons';
import DialogContent from '@material-ui/core/DialogContent';
import { Content, ContentWrapper } from '../../common/components/FeedbackLayout';
import Typography from '@material-ui/core/Typography';
import { ButtonsContainer } from '../../common/components/FormLayout';
import { Button } from './index';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  subtitleClass: {
    textAlign: 'center',
    fontSize: (props: { isMobile: boolean }) => (props.isMobile ? '16px' : '20px'),
  },
  subtitleContainer: {
    margin: '36px 0',
  },
  dialogContent: {
    padding: 0,
  },
  contentMargins: {
    margin: '32px 24px 48px 24px',
  },
  modalContent: {
    maxHeight: '600px',
  },
  titleClass: {
    marginBottom: '0px',
  },
  buttonClass: {
    margin: (props: { isMobile: boolean }) => (props.isMobile ? '3px' : '0px'),
  },
  buttonContainer: {
    width: (props: { isMobile: boolean }) => (props.isMobile ? 'unset' : 'inherit'),
  },
}));

interface IResponsiveModal {
  open: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;
  body?: React.ReactNode;
  secondaryButton?: {
    label: string;
    handle: () => void;
  };
  primaryButton?: {
    label: string;
    handle: () => void;
  };
  stackButtons?: boolean;
}

const ResponsiveSimpleModal: FC<IResponsiveModal> = ({
  open,
  handleClose,
  title,
  subtitle,
  secondaryButton,
  primaryButton,
  body,
  stackButtons,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    subtitleClass,
    dialogContent,
    contentMargins,
    subtitleContainer,
    modalContent,
    buttonClass,
    buttonContainer,
    titleClass,
  } = useStyles({ isMobile });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-skip-transaction"
      aria-describedby="Dialog for skipping Transaction Reporting"
      data-testid={'modal-dialog'}
    >
      <DialogTitle>
        <IconButton aria-label="close" onClick={handleClose} className="closeButton">
          <CloseIcon className="closeIcon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <div className={modalContent}>
          <ContentWrapper marginTop={'0rem'} className={contentMargins}>
            <Content>
              <Typography variant="h4" align="center" className={titleClass}>
                {title}
              </Typography>
              <div className={subtitleContainer}>
                {subtitle && <Typography className={subtitleClass}>{subtitle}</Typography>}
                {body && body}
              </div>
              {stackButtons ? (
                <>
                  <ButtonsContainer marginBottom={'10px'}>
                    {primaryButton && (
                      <Button
                        onClick={primaryButton.handle}
                        className={buttonClass}
                        data-testid={'modal-primary-button'}
                        fullWidth
                      >
                        {primaryButton.label}
                      </Button>
                    )}
                  </ButtonsContainer>
                  <ButtonsContainer marginBottom={'10px'}>
                    {secondaryButton && (
                      <Button
                        variant="secondary"
                        onClick={secondaryButton.handle}
                        className={buttonClass}
                        fullWidth
                        data-testid="modal-dialog-button-secondary"
                      >
                        {secondaryButton.label}
                      </Button>
                    )}
                  </ButtonsContainer>
                </>
              ) : (
                <ButtonsContainer
                  className={buttonContainer}
                  alignCenter={!primaryButton || !secondaryButton}
                  marginBottom={'10px'}
                >
                  {secondaryButton && (
                    <Button
                      variant="secondary"
                      onClick={secondaryButton.handle}
                      className={buttonClass}
                      data-testid="modal-dialog-button-secondary"
                    >
                      {secondaryButton.label}
                    </Button>
                  )}
                  {primaryButton && (
                    <Button onClick={primaryButton.handle} className={buttonClass} data-testid={'modal-primary-button'}>
                      {primaryButton.label}
                    </Button>
                  )}
                </ButtonsContainer>
              )}
            </Content>
          </ContentWrapper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ResponsiveSimpleModal;
