import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const EnvelopeIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <path
      transform={`translate(${translateValue},${translateValue})`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.5H20C21.3807 4.5 22.5 5.61929 22.5 7V17C22.5 18.3807 21.3807 19.5 20 19.5H4C2.61929 19.5 1.5 18.3807 1.5 17V7C1.5 5.61929 2.61929 4.5 4 4.5ZM4 6H20C20.0525 6 20.1041 6.00405 20.1545 6.01186L12.7059 11.1083C12.2803 11.3994 11.7197 11.3994 11.2941 11.1083L3.84551 6.01186C3.89586 6.00405 3.94746 6 4 6ZM3 7.25086V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V7.25086L13.5529 12.3463C12.6167 12.9868 11.3833 12.9868 10.4471 12.3463L3 7.25086Z"
      fill="black"
    />
  );
};

export default EnvelopeIcon;
