import { FormControlClassKey, StyleRules } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiFormControl: Partial<StyleRules<FormControlClassKey, {}>> = {
  marginNormal: {
    marginTop: 2,
    marginBottom: 30,
  },
};

export default MuiFormControl;
