// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-Capture-CountrySelector-countrySelector .ods-select{max-width:unset}.onfido-sdk-ui-Capture-CountrySelector-countrySelector .ods-select-output{display:-webkit-box;display:-webkit-flex;display:flex}.onfido-sdk-ui-Capture-CountrySelector-countryFlag{position:absolute;top:.75em;left:.75em;width:1.5em;height:1.5em;background-color:transparent;background-repeat:no-repeat;background-size:cover;background-position:center;border-radius:50%}.onfido-sdk-ui-Capture-CountrySelector-countryLabel{padding-left:1.875em;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"countrySelector": "onfido-sdk-ui-Capture-CountrySelector-countrySelector",
	"countryFlag": "onfido-sdk-ui-Capture-CountrySelector-countryFlag",
	"countryLabel": "onfido-sdk-ui-Capture-CountrySelector-countryLabel"
};
export default ___CSS_LOADER_EXPORT___;
