import { StyleRules } from '@material-ui/core';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiAutocomplete: Partial<StyleRules<AutocompleteClassKey, {}>> = {
  root: {
    marginTop: theme.spacing(2),
  },
  inputRoot: {
    borderRadius: 22,
    backgroundColor: theme.palette.common.white,
    fontSize: 18,
    padding: `0px ${theme.spacing(5)}px`,
    border: `1px solid ${theme.palette.grey[500]}`,
    height: 44,
  },
  paper: {
    border: '1px solid #ccc',
    borderRadius: 22,
  },
  listbox: {
    maxHeight: 220,
    overflow: 'auto',
    padding: 0,
  },
  endAdornment: {
    right: 8,
    top: 'calc(50% - 16px)',
  },
  popupIndicator: {
    marginRight: theme.spacing(1),
    fill: theme.palette.text.secondary,
    stroke: theme.palette.text.secondary,
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  popupIndicatorOpen: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  clearIndicator: {
    '& svg': {
      color: theme.palette.text.secondary,
    },
  },
};

export default MuiAutocomplete;
