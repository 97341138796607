import { TFunction } from 'react-i18next';

const noDuplicateValue = (t: TFunction<'translation'>, primaryTaxNumber: string) => (value: any) => {
  let error;
  if (primaryTaxNumber === value) {
    error = `${t('secondary_tax_error_duplicated')}`;
  }
  return error;
};

export default noDuplicateValue;
