import { KeyboardDatePicker as FormikKeyboardDatePicker, KeyboardDatePickerProps } from 'formik-material-ui-pickers';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '../icons';

function KeyboardDatePicker(props: KeyboardDatePickerProps) {
  const { t } = useTranslation();
  return (
    <FormikKeyboardDatePicker
      format="dd/MM/yyyy"
      autoOk
      keyboardIcon={<CalendarIcon />}
      cancelLabel={t('common_cancel')}
      okLabel={t('common_ok')}
      {...props}
    />
  );
}

export default KeyboardDatePicker;
