import { TFunction } from 'react-i18next';
import { noSpecialCharsValidator, regexValidator, requiredValidator } from './index';
import noDuplicateValue from './noDuplicateValue';

const taxCodeValidator =
  (t: TFunction<'translation'>, primaryTaxNumber: string, inputExample?: string, inputValidationRule?: string) =>
  (value: any) => {
    return (
      requiredValidator(t)(value) ||
      noSpecialCharsValidator(t)(value) ||
      (primaryTaxNumber && noDuplicateValue(t, primaryTaxNumber)(value)) ||
      (inputValidationRule && regexValidator(t, inputValidationRule, inputExample)(value))
    );
  };

export { taxCodeValidator };
