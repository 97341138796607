import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { ITaxStepPayload, ITaxValues, OnboardingSteps } from '../../../models/onboarding.models';
import { LoadingContainer } from '../../../../common/components';
import { CircularProgress } from '../../../../theme/components';
import TaxInputForm from './TaxInputForm';
import { fetchCustomData, submitFormDataForOnboardingStep, submitSkipTaxStep } from '../../../store';
import SecondTaxCountryQuestion from './SecondTaxCountryQuestion';
import SecondaryTaxValueFormContainer from './SecondaryTaxValueFormContainer';
import { mapCountryCode } from '../../../helpers';
import { useTranslation } from 'react-i18next';

export interface IsubmittableCountryValue {
  secondaryTaxCountryValue?: {
    label: string;
    value: string;
  };
}

const TaxNumberStep = () => {
  const dispatch = useDispatch();

  const views = ['taxNumber', 'selectCountry', 'secondaryTax'];
  const { t } = useTranslation();

  const [currentView, setCurrentView] = useState(views[0]);
  const [taxPayload, setTaxPayload] = useState<ITaxValues>({ taxNumber: '' });
  const [skipSecondCountry, setSkipSecondCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{ label: string; value: string }>({ label: '', value: '' });
  const {
    currentStep: { payload },
    error,
    loading: isLoading,
  } = useSelector((state: IRootState) => state.onboarding);

  const taxStepPayload = payload as ITaxStepPayload;

  useEffect(() => {
    if (error && skipSecondCountry) {
      setCurrentView(views[0]);
    }
  }, [error]);

  // Primary Tax number
  const onSubmitTaxNumber = useCallback((value: ITaxValues) => {
    setTaxPayload(() => ({
      taxNumber: value.taxNumber,
    }));
    setCurrentView(() => views[1]);
  }, []);

  const onSkipTaxNumber = useCallback(() => {
    // SKIP the whole step
    dispatch(submitSkipTaxStep());
  }, []);

  // Secondary Tax number and country Selection
  const onSubmitSecondaryTaxCountry = useCallback(
    (values: IsubmittableCountryValue) => {
      const secondaryTaxCountry = values.secondaryTaxCountryValue
        ? mapCountryCode(t)(values.secondaryTaxCountryValue.value)
        : null;
      if (secondaryTaxCountry) {
        const updatedState = {
          taxNumber: taxPayload.taxNumber,
          secondaryTaxNumber: {
            taxNumberDomicile: secondaryTaxCountry.value,
            taxNumberValue: '',
          },
        };
        setTaxPayload(() => updatedState);
        setSelectedCountry({ ...secondaryTaxCountry });
        dispatch(
          fetchCustomData({
            url: `tax_number/countries/${secondaryTaxCountry.value}`,
            key: 'tax_second_country_config',
          }),
        );
        setCurrentView(views[2]);
      }
    },
    [taxPayload],
  );

  const onSubmitSecondaryTaxValue = useCallback(
    ({ secondaryTaxValue }) => {
      const finalPayload = {
        taxNumber: taxPayload.taxNumber,
        secondaryTaxNumber: {
          taxNumberDomicile: taxPayload.secondaryTaxNumber?.taxNumberDomicile,
          taxNumberValue: secondaryTaxValue,
        },
      };
      setTaxPayload(() => finalPayload);
      dispatchPayload(finalPayload);
    },
    [taxPayload],
  );

  const onSkipSecondaryCountry = useCallback(() => {
    setSkipSecondCountry(true);
    dispatchPayload(taxPayload);
  }, [taxPayload]);

  const handleSecondaryCountryBackButton = useCallback(() => {
    setCurrentView(views[1]);
  }, []);

  const dispatchPayload = (taxPayload: ITaxValues) => {
    dispatch(
      submitFormDataForOnboardingStep({
        href: OnboardingSteps.TAX_NUMBER.href,
        payload: taxPayload,
      }),
    );
  };

  const switchComponent = () => {
    switch (currentView) {
      case views[0]:
        return (
          <TaxInputForm
            initialValues={taxPayload}
            onSubmit={onSubmitTaxNumber}
            onSkip={onSkipTaxNumber}
            error={error}
            isLoading={isLoading}
            taxConfig={taxStepPayload._config}
          />
        );
      case views[1]:
        return (
          <SecondTaxCountryQuestion
            onSkipSecondaryCountry={onSkipSecondaryCountry}
            onSubmitSecondaryTaxCountry={onSubmitSecondaryTaxCountry}
          />
        );
      case views[2]:
        return (
          <SecondaryTaxValueFormContainer
            country={selectedCountry}
            handleBackButton={handleSecondaryCountryBackButton}
            onSubmit={onSubmitSecondaryTaxValue}
            error={error}
            isLoading={isLoading}
            taxPayload={taxPayload}
          />
        );
      default:
        return (
          <TaxInputForm
            initialValues={taxPayload}
            onSubmit={onSubmitTaxNumber}
            onSkip={onSkipTaxNumber}
            error={error}
            isLoading={isLoading}
            taxConfig={taxStepPayload._config}
          />
        );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        switchComponent()
      )}
    </>
  );
};

export { TaxNumberStep };
