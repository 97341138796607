import { TFunction } from 'react-i18next';
import { lengthValidator, noDigitsValidator, requiredValidator } from './index';
import { noSpecialCharsNameValidator } from './noSpecialCharsValidator';

const nameValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return (
    requiredValidator(t, fieldName)(value) ||
    noSpecialCharsNameValidator(t, fieldName)(value) ||
    noDigitsValidator(t, fieldName)(value) ||
    lengthValidator(t, 2, 50, fieldName)(value)
  );
};

export default nameValidator;
