import { Typography } from '../../../../theme/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Error: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography color="error" variant="subtitle2">
        {t('common_error_sorry_there_is_a_problem')}
      </Typography>
      <Typography color="error" variant="caption" component="p" gutterBottom>
        {t('common_error_please_try_again')}
      </Typography>
    </>
  );
};

export default Error;
