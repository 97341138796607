import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const CheckboxIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke={theme.palette.primary.main} fill="none" />
    </SvgIcon>
  );
};

export default CheckboxIcon;
