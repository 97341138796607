function getEnvironment() {
  const baseUrl = window.location.origin;
  const findTerm = (term: string) => {
    if (baseUrl.includes(term)) {
      return baseUrl;
    }
  };

  switch (baseUrl) {
    case findTerm('localhost'):
      return 'local';
    case findTerm('dev'):
      return 'development';
    case findTerm('beta'):
      return 'acceptance';
    default:
      return 'production';
  }
}
export default getEnvironment;
