import * as Onfido from 'onfido-sdk-ui';
import { SupportedLanguages } from 'onfido-sdk-ui';
import { IProofIdStepPayload, OnboardingSteps } from '../../../models/onboarding.models';
import { Theme } from '@material-ui/core/styles';
import { TFunction } from 'react-i18next';
import { countryCodeList } from '../../../utils/countryList';
import { submitPhotoId } from '../../../store';
import { Dispatch } from 'redux';
import onfidoFile from '../../../../../translations/onfido.json';

const translations: Record<string, any> = {
  en: onfidoFile.en.translation,
  es: onfidoFile.es.translation,
  fr: onfidoFile.fr.translation,
  de: onfidoFile.de.translation,
  nl: onfidoFile.nl.translation,
  it: onfidoFile.it.translation,
};

export const getOnfidoInstance = async (
  onfidoPayload: IProofIdStepPayload,
  language: SupportedLanguages,
  theme: Theme,
  t: TFunction<'translation'>,
  dispatch: Dispatch<any>,
  POIAttempted: boolean,
) => {
  const publicSvgUrl = process.env.PUBLIC_URL
    ? `${process.env.PUBLIC_URL}/BUX_logo.svg`
    : 'https://register.getbux.com/BUX_logo.svg';
  const instance = Onfido.init({
    useModal: false,
    token: onfidoPayload.onfidoWebToken,
    crossDeviceClientIntroProductName: '',
    crossDeviceClientIntroProductLogoSrc: publicSvgUrl,
    language: {
      locale: language,
      phrases: translations[language],
    },
    customUI: {
      //Typography options
      fontFamilyTitle: 'Maison Neue, sans-serif',
      fontSizeTitle: '34px',
      fontWeightTitle: 600,
      fontFamilySubtitle: 'Maison Neue, sans-serif',
      fontSizeSubtitle: '20px',
      fontWeightSubtitle: 400,
      fontFamilyBody: 'Maison Neue, sans-serif',
      fontSizeBody: '20px',
      fontWeightBody: 400,

      //Modal (SDK main container)
      colorBackgroundSurfaceModal: 'white',
      borderWidthSurfaceModal: '0.5px',
      colorBorderSurfaceModal: theme.palette.grey.A100,
      borderRadiusSurfaceModal: '12px',

      // Document buttons
      colorContentDocTypeButton: '',
      colorBorderDocTypeButton: theme.palette.grey['500'],
      colorBorderDocTypeButtonHover: 'black',

      // shared button options
      borderRadiusButton: '100px',

      //Primary Buttons
      colorBorderDocTypeButtonActive: '',
      colorBackgroundButtonPrimary: theme.palette.primary.main,
      colorBackgroundButtonPrimaryHover: theme.palette.primary.main,
      colorBackgroundButtonPrimaryActive: theme.palette.primary.main,
      colorBorderButtonPrimary: theme.palette.primary.main,

      //Secondary Buttons
      colorBackgroundButtonSecondaryActive: '',
      colorContentButtonSecondaryText: theme.palette.primary.main,
      colorBackgroundButtonSecondary: '',
      colorBorderButtonSecondary: theme.palette.primary.main,
      colorBackgroundButtonSecondaryHover: '',

      //Links
      colorBorderLinkUnderline: theme.palette.primary.main,
      colorContentLinkTextHover: theme.palette.primary.main,
      colorBackgroundLinkHover: '',
      colorBackgroundLinkActive: '',

      //cross device
      colorBackgroundInfoPill: theme.palette.primary.main,

      //camera
      colorBackgroundButtonCameraActive: theme.palette.primary.main,
      colorBackgroundButtonCameraHover: theme.palette.primary.main,

      // warnings and error
      colorBackgroundAlertError: theme.palette.error.main,
      colorBackgroundAlertInfo: theme.palette.error.main,
    },
    onComplete: () => {
      dispatch(
        submitPhotoId({
          href: OnboardingSteps.PROOF_OF_ID.href,
          payload: {},
        }),
      );
    },
    steps: [
      {
        type: 'welcome',
        options: {
          title: POIAttempted ? t('proof_of_id_title_error') : t('proof_of_id_title'),
          descriptions: POIAttempted
            ? [t('proof_of_id_welcome_description_error'), t('proof_of_id_welcome_description_error_reasons')]
            : [t('proof_of_id_welcome_description')],
          nextButton: POIAttempted ? t('common_retry') : t('common_continue'),
        },
      },
      {
        type: 'document',
        options: {
          forceCrossDevice: true,
          useLiveDocumentCapture: true,
          uploadFallback: false,
          documentTypes: {
            passport: {
              country: countryCodeList[onfidoPayload.nationality],
            },
            national_identity_card: {
              country: countryCodeList[onfidoPayload.nationality],
            },
            driving_licence: {
              country: countryCodeList[onfidoPayload.nationality],
            },
          },
        },
      },
      'face',
      'complete',
    ],
  });
  return instance;
};
