import React, { CSSProperties } from 'react';
import { Page, PDFPageProxy } from 'react-pdf';
import { logError } from './config';

export interface IPassedData {
  scale: number;
  numPages: number | undefined;
  triggerResize: () => void;
  pages: React.MutableRefObject<WeakMap<any, any>>;
  pageNumbers: WeakMap<any, any>;
}

interface IPageRendererProps {
  index: number;
  style: CSSProperties;
  data: IPassedData;
}

const PageRenderer = ({ index, data, style }: IPageRendererProps) => {
  const { scale, numPages, triggerResize } = data;
  const pageNumber = index + 1;
  return (
    <div style={{ ...style }}>
      <div
        ref={(ref) => {
          const { pages, pageNumbers } = data;

          if (!pageNumbers.has(pageNumber)) {
            const key = { pageNumber };
            pageNumbers.set(pageNumber, key);
          }

          pages.current.set(pageNumbers.get(pageNumber), ref);
        }}
      >
        <Page
          {...{ pageNumber }}
          {...{ scale }}
          renderAnnotationLayer={false} // Todo: future improvement is rendering annotation layer to enable links within the document.
          onLoadError={(error) => logError(error, `Page ${pageNumber.toString()}`)}
          onLoadSuccess={(page: PDFPageProxy) => {
            // This is necessary to ensure the row heights of
            // the variable list are correctly initialised.
            if (page.pageNumber === numPages) {
              triggerResize();
            }
          }}
        />
      </div>
    </div>
  );
};

export default PageRenderer;
