import { TabClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiTab: Partial<StyleRules<TabClassKey, {}>> = {
  root: {
    padding: 0,
    minHeight: 'none',
    color: theme.palette.common.black,
    textAlign: 'left',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    marginBottom: theme.spacing(6),
    '&$selected': {
      color: theme.palette.primary.main,
    },
  },
  wrapper: {
    alignItems: 'start',
  },
  textColorInherit: {
    color: theme.palette.common.black,
    opacity: 1,
  },
};

export default MuiTab;
