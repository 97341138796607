import { createReducer } from 'typesafe-actions';
import { IErrorResponse } from '../../common/models';
import { OnboardingSteps } from '../models/onboarding.models';
import {
  fetchCustomData,
  getExistingApplication,
  gotoStep,
  gotoStepAndGetData,
  handleOnboardingError,
  pollForVerification,
  registerSentrySessionId,
  resumeApplication,
  saveFetchedCustomData,
  setSentrySessionId,
  startUserApplication,
  submitFormDataForFinanceQuestions,
  submitFormDataForOnboardingStep,
  submitPhotoId,
  submitSelfie,
  submitSkipTaxStep,
  submitUserEmail,
  markPOIAttempted,
  submitPhoneVerification,
} from './onboarding.actions';
import { IOnboardingState } from './onboarding.types';

const initialState: IOnboardingState = {
  loading: false,
  currentStep: { step: OnboardingSteps.NOT_STARTED, payload: null },
  emailIsVerified: { verified: false, email: '', _inProgress: true, _links: { next: { href: '' } } },
  error: null,
  pollingAttempt: 0,
  anonymousId: null,
  POIAttempted: false,
};

export default createReducer(initialState)
  .handleAction(startUserApplication, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(getExistingApplication, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(submitUserEmail, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(submitPhotoId, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
    pollingAttempt: 0,
  }))
  .handleAction(submitSelfie, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
    pollingAttempt: 0,
  }))
  .handleAction(pollForVerification, (state: IOnboardingState) => ({
    ...state,
    loading: false,
    error: null,
    pollingAttempt: state.pollingAttempt + 1,
  }))
  .handleAction(submitFormDataForOnboardingStep, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(submitFormDataForFinanceQuestions, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(resumeApplication, (state: IOnboardingState, { payload }: { payload: OnboardingSteps }) => ({
    ...state,
    loading: false,
    currentStep: payload,
  }))
  .handleAction(handleOnboardingError, (state: IOnboardingState, { payload }: { payload: IErrorResponse }) => ({
    ...state,
    loading: false,
    error: payload,
  }))
  .handleAction(gotoStepAndGetData, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(gotoStep, (state: IOnboardingState, { payload }: { payload: OnboardingSteps }) => ({
    ...state,
    currentStep: { ...state.currentStep, step: payload },
    error: null,
  }))
  .handleAction(registerSentrySessionId, (state: IOnboardingState) => ({
    ...state,
  }))
  .handleAction(setSentrySessionId, (state: IOnboardingState, { payload }: { payload: { anonymousId: string } }) => ({
    ...state,
    anonymousId: payload.anonymousId,
  }))
  .handleAction(submitSkipTaxStep, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(fetchCustomData, (state: IOnboardingState) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(
    saveFetchedCustomData,
    (state: IOnboardingState, { payload }: { payload: { key: string; data: unknown } }) => ({
      ...state,
      [payload.key]: payload.data,
      loading: false,
      error: null,
    }),
  )
  .handleAction(markPOIAttempted, (state: IOnboardingState) => ({
    ...state,
    POIAttempted: true,
  }))
  .handleAction(submitPhoneVerification, (state: IOnboardingState) => ({
    ...state,
    loading: true,
  }));
