import { TFunction } from 'react-i18next';
import isEqual from 'lodash.isequal';

const secondNationalityValidator =
  (t: TFunction<'translation'>, nationality: { label: string; value: string } | null) => (value: any) => {
    if (nationality && isEqual(value, nationality)) {
      return t('second_nationality_error');
    } else {
      return undefined;
    }
  };

export default secondNationalityValidator;
