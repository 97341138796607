import { OnboardingSteps } from '../models/onboarding.models';

import { AgreementsStep } from './steps/agreements/AgreementsStep';
import { AddressStep } from './steps/address/AddressStep';
import { EmailCaptureStep } from './steps/email/EmailCaptureStep';
import WaitForEmailVerificationStep from './steps/email/WaitForEmailVerificationStep';
import { ContinueOnAppStep } from './steps/continueonapp/ContinueOnAppStep';

import {
  CountryOfResidenceStep,
  DateOfBirthStep,
  DualNationalityStep,
  FullnameStep,
  GenderStep,
  NationalityStep,
  PlaceOfBirthStep,
} from './steps/personaldetails';
import { ProofOfIdStep } from './steps/proof-of-id/ProofOfIdStep';
import WaitingVerification from './steps/proof-of-id/WaitingVerification';
import { TransactionStep } from './steps/transactions-reporting/TransactionStep';
import { TaxNumberStep } from './steps/tax-number/TaxNumberStep';
import FinanceDetailsStep from './steps/finance-details/FinanceDetailsStep';
import AddressVerification from './steps/address-verification/AddressVerificationStep';
import PhoneVerificationStep from './steps/phone-verification/PhoneVerificationStep';
import PhoneNumberStep from './steps/phone-verification/PhoneNumberStep';
import VerificationSuccess from './steps/phone-verification/VerificationSuccess';
import SignedAgreementStep from './steps/signed-agreements/SignedAgreementStep';
import SelfieVerification from './steps/selfie/SelfieVerification';
import SelfieStep from './steps/selfie/SelfieStep';

const documentsSteps = [{ step: OnboardingSteps.AGREEMENTS, component: AgreementsStep }];
const emailSteps = [
  { step: OnboardingSteps.EMAIL, component: EmailCaptureStep },
  { step: OnboardingSteps.VERIFY_EMAIL, component: WaitForEmailVerificationStep },
];
const personalDetailsSteps = [
  { step: OnboardingSteps.FULLNAME, component: FullnameStep },
  { step: OnboardingSteps.DATE_OF_BIRTH, component: DateOfBirthStep },
  { step: OnboardingSteps.GENDER, component: GenderStep },
  { step: OnboardingSteps.PLACE_OF_BIRTH, component: PlaceOfBirthStep },
  { step: OnboardingSteps.COUNTRY_OF_RESIDENCE, component: CountryOfResidenceStep },
  { step: OnboardingSteps.NATIONALITY, component: NationalityStep },
  { step: OnboardingSteps.DUAL_NATIONALITY, component: DualNationalityStep },
];

const proofOfIdSteps = [
  { step: OnboardingSteps.PROOF_OF_ID, component: ProofOfIdStep },
  { step: OnboardingSteps.PROOF_OF_ID_VERIFICATION, component: WaitingVerification },
];

const transactionTaxSteps = [
  { step: OnboardingSteps.TRANSACTION_REPORTING, component: TransactionStep },
  { step: OnboardingSteps.TAX_NUMBER, component: TaxNumberStep },
];

const financeDetailsSteps = [{ step: OnboardingSteps.FINANCE_DETAILS, component: FinanceDetailsStep }];

const AddressSteps = [{ step: OnboardingSteps.ADDRESS, component: AddressStep }];

const continueOnAppSteps = [{ step: OnboardingSteps.CONTINUE_ON_APP, component: ContinueOnAppStep }];

const personalDetailsStepsWithAddress = [...personalDetailsSteps, ...AddressSteps];

const selfieSteps = [
  { step: OnboardingSteps.SELFIE, component: SelfieStep },
  { step: OnboardingSteps.SELFIE_VERIFICATION, component: SelfieVerification },
  { step: OnboardingSteps.ADDRESS_VERIFICATION, component: AddressVerification },
];

const phoneSteps = [
  { step: OnboardingSteps.PHONE_NUMBER, component: PhoneNumberStep },
  { step: OnboardingSteps.PHONE_VERIFICATION, component: PhoneVerificationStep },
  { step: OnboardingSteps.PHONE_VERIFICATION_SUCCESS, component: VerificationSuccess },
];

const signedAgreementStep = [{ step: OnboardingSteps.SIGNED_AGREEMENTS, component: SignedAgreementStep }];

export {
  documentsSteps,
  emailSteps,
  personalDetailsSteps,
  AddressSteps,
  personalDetailsStepsWithAddress,
  proofOfIdSteps,
  transactionTaxSteps,
  financeDetailsSteps,
  continueOnAppSteps,
  selfieSteps,
  phoneSteps,
  signedAgreementStep,
};
