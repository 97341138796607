import * as Sentry from '@sentry/react';

const SENTRY_SESSION_ID_KEY = 'BUX_anon_session_id';

async function storeSentrySessionId(anonymousId: string) {
  try {
    await window.localStorage.setItem(SENTRY_SESSION_ID_KEY, anonymousId);
  } catch (error) {
    Sentry.captureException(error);
  }
}

async function retrieveSentrySessionId() {
  try {
    return await window.localStorage.getItem(SENTRY_SESSION_ID_KEY);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export { storeSentrySessionId, retrieveSentrySessionId };
