import React from 'react';
import { IProgressPartConfig } from './progress-config';
import { makeStyles } from '@material-ui/core';
import { Typography } from '../../../theme/components';
import tickIcon from '../../../../assets/images/tick.svg';
import { useTranslation } from 'react-i18next';

interface IProgressBoxContentProps {
  progressParts: IProgressPartConfig[];
  currentIndex: number;
}

const useStyles = makeStyles((theme) => ({
  rowStyle: {
    display: 'grid',
    margin: '15px 0',
    gridTemplateColumns: '1fr 3fr 2fr',
    alignItems: 'center',
  },
  boxStyle: {
    padding: '5px',
  },
  numberStyle: {
    justifySelf: 'center',
    textAlign: 'center',
    backgroundColor: '#F4F2F2',
    width: '36px',
    height: '36px',
    padding: '0.25em',
    fontSize: '16px',
    borderRadius: '50%',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      width: '26px',
      height: '26px',
    },
  },
  partDetailStyle: {
    justifySelf: 'start',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyItems: 'center',
  },
  durationStyle: {
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  titleStyle: {
    alignSelf: 'flex-start',
    fontSize: '0.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
  },
  descriptionStyle: {
    alignSelf: 'flex-start',
    textAlign: 'left',
  },
  tickImg: {
    height: '36px',
    width: '36px',
    [theme.breakpoints.down('xs')]: {
      width: '26px',
      height: '26px',
    },
  },
}));

const ProgressBoxContent: React.FC<IProgressBoxContentProps> = ({ progressParts, currentIndex }) => {
  const { rowStyle, boxStyle, numberStyle, partDetailStyle, durationStyle, titleStyle, descriptionStyle, tickImg } =
    useStyles();
  const { t } = useTranslation();
  const isComplete = (index: number) => {
    return currentIndex >= 0 && currentIndex > index;
  };

  return (
    <div className={boxStyle}>
      {progressParts.map((part, index) => (
        <div key={`${index} - ${part.partName}`} className={rowStyle}>
          {isComplete(index) ? (
            <div>
              <img src={tickIcon} className={tickImg}></img>
            </div>
          ) : (
            <div className={numberStyle}>
              <span>{index + 1}</span>
            </div>
          )}
          <div className={partDetailStyle}>
            <div>
              <Typography variant="subtitle2" color={'textSecondary'} className={titleStyle}>
                {t(part.partName)}
              </Typography>
            </div>
            <div className={descriptionStyle}>
              <Typography
                variant="subtitle2"
                color={'textSecondary'}
                style={{ fontWeight: 400 }}
                className={titleStyle}
              >
                {part.partDescription && t(part.partDescription)}
              </Typography>
            </div>
          </div>
          <div>
            <Typography variant="subtitle1" color={'textSecondary'} className={durationStyle}>
              {isComplete(index) ? t('common_complete') : `${t('duration_mins', { duration: part.partDuration })}`}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBoxContent;
