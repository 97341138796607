import { useSelector } from 'react-redux';
import { IRootState } from '../../../../Store';
import { CommonError, MaintenanceError } from '.';
import { BOS_MAINTENANCE_ERROR } from '../../constants';

const ErrorFallback = () => {
  const { error } = useSelector((state: IRootState) => state.onboarding);
  switch (error?.errorCode) {
    case BOS_MAINTENANCE_ERROR:
      return <MaintenanceError />;
    default:
      return <CommonError />;
  }
};

export default ErrorFallback;
