import { Button, makeStyles, MenuItem, Popover } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDownIcon from '../../theme/icons/ArrowDownIcon';
import { languages } from '../../../config/i18next';

const useMenuItemStyles = makeStyles((theme) => ({
  root: {
    fontSize: 20,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    justifyContent: 'flex-end',
    fontWeight: 600,
    marginRight: 17,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      background: 'none',
    },
    '&:active': {
      color: theme.palette.primary.light,
      background: 'none !important',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    background: 'none !important',
  },
}));

const usePopoverStyles = makeStyles({
  paper: {
    background: 'none',
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 0,
    fontSize: 20,
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  collapsedIcon: {
    stroke: theme.palette.common.black,
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  expandedIcon: {
    stroke: theme.palette.common.black,
    transform: 'rotate(-180deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const selectedLanguage = useMemo(() => languages.find((l) => l.lang === i18n.language), [i18n.language]);
  const { button, expandedIcon, collapsedIcon } = useStyles();
  const menuItemClasses = useMenuItemStyles();
  const popoverClasses = usePopoverStyles();

  const handleMenuItemClick = useCallback(
    (lang: string) => {
      setAnchorEl(null);
      i18n.changeLanguage(lang);
    },
    [i18n],
  );

  return (
    <>
      <Button variant="text" onClick={(evt) => setAnchorEl(evt.currentTarget)} className={button} disableRipple>
        {selectedLanguage?.displayName}
        <ArrowDownIcon className={Boolean(anchorEl) ? expandedIcon : collapsedIcon} />
      </Button>
      <Popover
        elevation={0}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={popoverClasses}
      >
        {languages.map(({ lang, title }, k) => (
          <MenuItem
            key={k}
            classes={menuItemClasses}
            selected={lang === selectedLanguage?.lang}
            onClick={() => handleMenuItemClick(lang)}
            disableTouchRipple
          >
            {title}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
