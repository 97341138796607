import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { CircularProgress } from '../../../../theme/components';
import { IFinanceDetailsPayload, IFinanceFields, OnboardingSteps } from '../../../models/onboarding.models';
import Question from './Question';
import { getListOfQuestions, IQuestion, QuestionAnswerValues, QuestionFormNames } from './questionsList';
import { LoadingContainer } from '../../../../common/components';
import ResponsiveSimpleModal from '../../../../theme/components/ResponsiveSimpleModal';
import { useTranslation } from 'react-i18next';
import RiskWarning from './RiskWarning';
import ExitScreen from './ExitScreen';
import { submitFormDataForFinanceQuestions } from '../../../store';
import CommercialEstatePopup from './popups/CommercialEstatePopup';
import IntensiveCashPopup from './popups/IntensiveCashPopup';

export interface IInitialState {
  ownerOfACompany?: string;
  sourceOfFunds_other?: string;
  investedInShares?: string;
  understandsLosingFunds?: string;
  understandsBalanceChanges?: string;
  acceptsRisk?: string;
  netWorth?: string;
  ownerDirectorSector?: string;
  sourceOfFunds?: string;
  expectedInvestedFunds?: string;
}

enum EDisplayComponent {
  QUESTION = 'QUESTION',
  RISK_WARNING = 'RISK_WARNING',
  EXIT_SCREEN = 'EXIT_SCREEN',
}

const FinanceDetailsStep = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // maybe move this to redux
  const [listOfQuestions, setListOfQuestions] = useState<IQuestion[]>([]);
  const [isInitializing, setIsInitializing] = useState(true);
  const [displayComponent, setDisplayComponent] = useState<EDisplayComponent>(EDisplayComponent.QUESTION);
  const [showFirstTimeInvestingDialog, setShowFirstTimeInvestingDialog] = useState(false);
  const [showCommercialEstateDialog, setShowCommercialEstateDialog] = useState(false);
  const [showIntensiveCashDialog, setShowIntensiveCashDialog] = useState(false);
  const [answersState, setAnswersState] = useState<IInitialState>({
    investedInShares: '',
    understandsLosingFunds: '',
    understandsBalanceChanges: '',
    netWorth: '',
    sourceOfFunds: '',
    sourceOfFunds_other: '',
    expectedInvestedFunds: '',
    ownerOfACompany: '',
    ownerDirectorSector: '',
    acceptsRisk: '',
  });
  const {
    currentStep: { payload },
    error,
    loading: isLoading,
  } = useSelector((state: IRootState) => state.onboarding);

  const financePayload = payload as IFinanceDetailsPayload;

  useEffect(() => {
    if (financePayload) {
      const list = getListOfQuestions(financePayload);
      setListOfQuestions(list);
      setIsInitializing(false);
    }
  }, []);

  const processSubmissionValues = (values: IInitialState) => {
    const valuesCopy = Object.assign({}, values);
    return Object.keys(valuesCopy).reduce(
      (refinedObj: Partial<Record<keyof IInitialState, string | boolean>>, key: string) => {
        if (valuesCopy[key as keyof IInitialState] !== undefined && valuesCopy[key as keyof IInitialState] !== '') {
          if (key === 'ownerOfACompany' || key === 'sourceOfFunds_other') {
            return refinedObj;
          }

          if (
            key === QuestionFormNames.sourceOfFunds &&
            valuesCopy[key as keyof IInitialState] === 'other' &&
            valuesCopy.sourceOfFunds_other
          ) {
            refinedObj[QuestionFormNames.sourceOfFunds] = valuesCopy.sourceOfFunds_other;
            return refinedObj;
          }

          const refinedObjKey = key as keyof IInitialState;
          let val: string | boolean | undefined = valuesCopy[key as keyof IInitialState];
          if (val === 'true' || val === 'false') {
            val = (val === 'true') as boolean;
          }

          refinedObj[refinedObjKey] = val;
        }
        return refinedObj;
      },
      {},
    );
  };

  const handleSubmit = useCallback(
    (cb: (values: IInitialState) => void) => (values: IInitialState) => {
      setAnswersState((prevState) => ({
        ...prevState,
        ...values,
      }));
      cb(values);
    },
    [answersState, listOfQuestions],
  );

  const handleSubmitRiskWarning = useCallback(
    (values: IInitialState) => {
      if (values.acceptsRisk === 'true') {
        setAnswersState((prevState) => ({
          ...prevState,
          acceptsRisk: 'true',
        }));
        setDisplayComponent(EDisplayComponent.QUESTION);
        setCurrentQuestionIndex((n) => n + 1);
      } else {
        //show exit screen
        setDisplayComponent(EDisplayComponent.EXIT_SCREEN);
      }
    },
    [answersState],
  );

  const onNext = useCallback(
    (values: IInitialState) => {
      if (
        listOfQuestions[currentQuestionIndex].formName === QuestionFormNames.investedInShares &&
        values.investedInShares === 'false'
      ) {
        setShowFirstTimeInvestingDialog(true);
        return;
      }
      if (
        listOfQuestions[currentQuestionIndex].formName === QuestionFormNames.sourceOfFunds &&
        values.sourceOfFunds === QuestionAnswerValues.commercial_real_estate
      ) {
        setShowCommercialEstateDialog(true);
        return;
      }
      if (
        listOfQuestions[currentQuestionIndex].formName === QuestionFormNames.ownerDirectorSector &&
        values.ownerDirectorSector === QuestionAnswerValues.cash_intensive
      ) {
        setShowIntensiveCashDialog(true);
        return;
      }
      if (
        currentQuestionIndex === 2 &&
        (values.understandsBalanceChanges === 'false' || values.understandsLosingFunds === 'false')
      ) {
        setDisplayComponent(EDisplayComponent.RISK_WARNING);
        return;
      }
      if (
        listOfQuestions[currentQuestionIndex].formName === QuestionFormNames.ownerOfACompany &&
        values.ownerOfACompany === 'false'
      ) {
        values.ownerDirectorSector = ''; //ensure this value is always empty if ownerOfACompany is set to false by user
        finishSubmission(values);
        return;
      }
      if (currentQuestionIndex < listOfQuestions.length - 1) {
        setCurrentQuestionIndex((n) => n + 1);
      } else {
        finishSubmission(values);
      }
    },
    [currentQuestionIndex, listOfQuestions],
  );

  const finishSubmission = (values: IInitialState) => {
    const valueToSubmit = processSubmissionValues(values) as IFinanceFields;
    dispatch(
      submitFormDataForFinanceQuestions({
        href: OnboardingSteps.FINANCE_DETAILS.href,
        payload: valueToSubmit,
      }),
    );
  };

  const onPrevious = () => {
    if (currentQuestionIndex > 0 && currentQuestionIndex < listOfQuestions.length) {
      setCurrentQuestionIndex((n) => n - 1);
    }
  };

  const setAnswerAsOther = () => {
    closePopups();
    setAnswersState((prevState) => ({
      ...prevState,
      sourceOfFunds: 'other',
    }));
  };

  const closePopups = () => {
    setShowFirstTimeInvestingDialog(false);
    setShowCommercialEstateDialog(false);
    setShowIntensiveCashDialog(false);
  };

  const proceedToNextQuestion = () => {
    closePopups();
    if (currentQuestionIndex < listOfQuestions.length - 1) {
      setCurrentQuestionIndex((n) => n + 1);
    } else {
      finishSubmission(answersState);
    }
  };

  const returnToRiskWarning = () => {
    setDisplayComponent(EDisplayComponent.RISK_WARNING);
  };

  const SwitchComponent = () => {
    switch (displayComponent) {
      case EDisplayComponent.QUESTION:
        return (
          <Question
            error={error}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onNext={onNext}
            initialValues={answersState}
            showPrevious={currentQuestionIndex !== 0}
            onPrevious={onPrevious}
            question={listOfQuestions[currentQuestionIndex]}
          />
        );
      case EDisplayComponent.EXIT_SCREEN:
        return <ExitScreen returnToPrevious={returnToRiskWarning} />;
      case EDisplayComponent.RISK_WARNING:
        return (
          <RiskWarning
            error={error}
            isLoading={isLoading}
            onSubmit={handleSubmitRiskWarning}
            initialValues={answersState}
          />
        );
    }
  };

  return (
    <>
      {isLoading || isInitializing ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <>
          <SwitchComponent />
          <ResponsiveSimpleModal
            open={showFirstTimeInvestingDialog}
            primaryButton={{ handle: proceedToNextQuestion, label: t('common_continue') }}
            handleClose={() => setShowFirstTimeInvestingDialog(false)}
            title={t('questionnaire_start_investing_dialog_title')}
            subtitle={t('questionnaire_start_investing_dialog_desc')}
          />
          <CommercialEstatePopup
            handleOtherAnswer={setAnswerAsOther}
            showCommercialEstateDialog={showCommercialEstateDialog}
            setShowCommercialEstateDialog={setShowCommercialEstateDialog}
            proceedToNextQuestion={proceedToNextQuestion}
          />
          <IntensiveCashPopup
            showIntensiveCashDialog={showIntensiveCashDialog}
            setShowIntensiveCashDialog={setShowIntensiveCashDialog}
            proceedToNextQuestion={proceedToNextQuestion}
          />
        </>
      )}
    </>
  );
};

export default FinanceDetailsStep;
