/*

Configure animation timeline starts and delays.

There are few sections of related animation groups that need to run in order.

Group 1:
- The completed progress circle need to finish it's stroke
- The custom icon needs to fade out.

Group 2:
- The completed progress circle needs to scale down
- The tick needs to animate in (motion path animation)
- The text of the completed circle needs to fade out

Group 3:
- The progress line connecting the two circles needs to animate the red color stroke.

Group 4:
- The new active circle needs to scale up
- The new custom icon needs to fade in
- The text of the active circle needs to fade in

Group 5:
- The progress stroke dash needs to animate on the new active circle for the first step.

All values are in seconds.
*/

type timelineConfig = {
  length: number;
  delay: number;
};

export const group1: timelineConfig = {
  length: 0.4,
  delay: 0.2,
};

export const group2: timelineConfig = {
  length: 0.4,
  delay: group1.delay + group1.length,
};

export const group3: timelineConfig = {
  length: 0.4,
  delay: group2.length + group2.delay,
};

export const group4: timelineConfig = {
  length: 0.4,
  delay: group3.length + group3.delay,
};

export const group5: timelineConfig = {
  length: 0.4,
  delay: group4.length + group4.delay,
};

export const activeCircleStrokeLength = '0.4s';
