import { TFunction } from 'react-i18next';
import { lengthValidator } from './index';

const questionnaireValidator = (t: TFunction<'translation'>) => (value: any) => {
  let error;
  if (!value) {
    error = t('questionnaire_error_select_option');
  }
  return error;
};

const questionnaireManualInputValidator =
  (t: TFunction<'translation'>, length: number, isRequired?: boolean) => (value: any) => {
    let error;
    if (isRequired) {
      if (value) {
        error = lengthValidator(t, 0, length)(value);
      } else {
        error = t('common_required');
      }
    }
    return error;
  };

export { questionnaireValidator, questionnaireManualInputValidator };
