import { DialogContentClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiDialogContent: Partial<StyleRules<DialogContentClassKey, {}>> = {
  root: {
    padding: theme.spacing(6),
    paddingBottom: 0,
  },
};

export default MuiDialogContent;
