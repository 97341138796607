import { FormHelperTextClassKey, StyleRules } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiFormHelperText: Partial<StyleRules<FormHelperTextClassKey, {}>> = {
  root: {
    marginBottom: -21,
  },
};

export default MuiFormHelperText;
