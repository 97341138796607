import { InputClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiInput: Partial<StyleRules<InputClassKey, {}>> = {
  root: {
    caretColor: theme.palette.primary.main,
  },
  input: {
    height: 28,
    padding: '4px 0',
    '&::placeholder': {
      color: theme.palette.grey[500],
      opacity: 1,
    },
  },
  underline: {
    '&:before': {
      borderBottomColor: theme.palette.grey[500],
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
    '&:hover:before': {
      borderBottom: '1px solid #000 !important',
    },
  },
};

export default MuiInput;
