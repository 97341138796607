// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-Capture-documentContainer{position:relative;overflow:hidden}.onfido-sdk-ui-Capture-faceContainer{position:static}.onfido-sdk-ui-Capture-form{display:grid;gap:16px;grid:auto-flow/1fr;width:100%}.onfido-sdk-ui-Capture-optional{color:rgba(var(--ods-color-content-secondary))}.onfido-sdk-ui-Capture-submit-button{margin-top:32px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"documentContainer": "onfido-sdk-ui-Capture-documentContainer",
	"faceContainer": "onfido-sdk-ui-Capture-faceContainer",
	"form": "onfido-sdk-ui-Capture-form",
	"optional": "onfido-sdk-ui-Capture-optional",
	"submit-button": "onfido-sdk-ui-Capture-submit-button"
};
export default ___CSS_LOADER_EXPORT___;
