import React from 'react';

export const AddressValidationContext = React.createContext<{
  cityExample: string | undefined;
  postcodeExample: string | undefined;
  postcodeValidationRule: string | undefined;
  provinceExample: string | undefined;
  provinceValidationRule: string | undefined;
}>({
  cityExample: '',
  postcodeExample: '',
  postcodeValidationRule: undefined,
  provinceExample: '',
  provinceValidationRule: undefined,
});
