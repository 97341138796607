import React from 'react';
import { Typography } from '../../../../../../theme/components';
import FormLayout, { Content as FormContent } from '../../../../../../common/components/FormLayout';
import { useTranslation } from 'react-i18next';
import CommonEmailInput from '../CommonEmailInput';

export const EmailOnlyStep: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FormLayout>
      <Typography variant="h2" gutterBottom={true}>
        {t('email_title')}
      </Typography>
      <FormContent>
        <Typography gutterBottom={true}>{t('email_descriptive_text')}</Typography>
        <CommonEmailInput />
      </FormContent>
    </FormLayout>
  );
};
