import { TFunction } from 'react-i18next';

const lengthValidator = (t: TFunction<'translation'>, min: number, max: number, fieldName?: string) => (value: any) => {
  let error;
  const regex = new RegExp(`^.{${min},${max}}$`);
  const safeStringValue = typeof value === 'string' ? value : '';
  if (!regex.test(safeStringValue.trim())) {
    const minChars = min > 1 ? min - 1 : min;
    const errorMessageMore = fieldName
      ? t('common_error_more_than_dynamic', { chars: minChars, count: minChars, field: fieldName })
      : t('common_error_more_than', { chars: minChars, count: minChars });
    const errorMessageLess = fieldName
      ? t('common_error_less_than_dynamic', { chars: max, field: fieldName })
      : t('common_error_less_than_dynamic', { chars: max });
    error = `${safeStringValue.length > max ? errorMessageLess : errorMessageMore}`;
  }
  return error;
};

export default lengthValidator;
