import { TFunction } from 'react-i18next';

const regexValidator = (t: TFunction<'translation'>, regexString: string, example?: string) => (value: any) => {
  let error;
  const regex = new RegExp(regexString);
  if (!regex.test(value)) {
    error = example ? `${t('email_error_please_use_format')}: ${example}` : t('common_error_please_check_field');
  }
  return error;
};

export default regexValidator;
