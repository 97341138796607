import React, { useContext, useMemo } from 'react';
import { Field, Form, FormikProvider } from 'formik';
import CountryAutocomplete, { Country, FlagAdornment } from '../../../../common/components/CountryAutocomplete';
import { Typography, Button } from '../../../../theme/components';
import FormLayout, {
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
  ButtonsContainer,
} from '../../../../common/components/FormLayout';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { filterUnmappedCountries, mapCountryCode, useFormikWithErrors } from '../../../helpers';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { Error } from '.';
import { useSelector } from 'react-redux';
import { PersonalDetailsContext } from './PersonalDetailsProvider';
import { IRootState } from '../../../../../Store';
import { StepComponent } from '../../StepRoute';
import secondNationalityValidator from '../../../utils/validators/secondNationalityValidator';

export const DualNationalityStep: React.FC<StepComponent> = ({ onPrevious, onNext, showPrevious = true }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { isLoading, error, initialValues, onSubmit } = useContext(PersonalDetailsContext);
  const {
    currentStep: { payload },
  } = useSelector((state: IRootState) => state.onboarding);
  const nationalities = useMemo(() => {
    const {
      _config: { nationalities },
    } = payload as any;
    return nationalities;
  }, []);

  const { touched, errors, values, ...formik } = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit((values: any) => onNext(values)),
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  return (
    <FormLayout>
      <FormContent marginTop={6}>
        <FormikProvider value={{ touched, errors, values, ...formik }}>
          <Form>
            <FormFieldsWrapper marginTop={3}>
              {error && <Error />}
              <Fieldset>
                <Typography variant="h4" component="legend" align="center">
                  {t('personal_details_secondarynationality_title')}
                </Typography>
                <Typography align="center" gutterBottom>
                  {t('personal_details_dual_nationality_description')}
                </Typography>
                <Field
                  validate={secondNationalityValidator(t, initialValues.nationality)}
                  component={CountryAutocomplete}
                  name="secondNationality"
                  placeholder={t('personal_details_select_nationality')}
                  countries={nationalities.suggested
                    .concat(nationalities.other)
                    .filter(filterUnmappedCountries(i18n))
                    .map(mapCountryCode(t))
                    .sort((a: Country, b: Country) => a.label?.localeCompare(b.label))}
                  fullWidth={true}
                  TextFieldProps={{
                    label: `${t('personal_details_secondarynationality')} (${t('common_optional')})`,
                    name: 'secondNationality',
                    error: touched['secondNationality'] && !!errors['secondNationality'],
                    helperText: touched['secondNationality'] && errors['secondNationality'],
                    InputProps: {
                      startAdornment: values['secondNationality'] ? (
                        <FlagAdornment nationality={values['secondNationality'].value} />
                      ) : undefined,
                    },
                  }}
                />
              </Fieldset>
            </FormFieldsWrapper>
            <ButtonsContainer>
              {showPrevious && (
                <Button variant="secondary" onClick={onPrevious}>
                  {t('common_go_back')}
                </Button>
              )}
              <Button type="submit" loading={isLoading} id="web-onboarding-personal-details">
                {!values.secondNationality ? t('personal_details_secondarynationality_i_dont') : t('common_continue')}
              </Button>
            </ButtonsContainer>
          </Form>
        </FormikProvider>
        <Typography component="p" variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </FormContent>
    </FormLayout>
  );
};

export default DualNationalityStep;
