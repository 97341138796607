import React, { useContext } from 'react';
import { Field, Form, FormikProvider } from 'formik';
import { Typography, Button, TextField } from '../../../../theme/components';
import FormLayout, {
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
  ButtonsContainer,
} from '../../../../common/components/FormLayout';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikWithErrors } from '../../../helpers';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { Error } from '.';
import { PersonalDetailsContext } from './PersonalDetailsProvider';
import { StepComponent } from '../../StepRoute';
import { nameValidator } from '../../../utils/validators';

export const FullnameStep: React.FC<StepComponent> = ({ onNext }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { isLoading, error, initialValues, onSubmit } = useContext(PersonalDetailsContext);

  const formik = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit((values: any) => onNext(values)),
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  return (
    <FormLayout>
      <FormContent marginTop={6}>
        <FormikProvider value={formik}>
          <Form>
            <FormFieldsWrapper marginTop={3}>
              {error && <Error />}
              <Fieldset>
                <Typography variant="h4" component="legend" align="center">
                  {t('personal_details_fullname_title')}
                </Typography>
                <Field
                  component={TextField}
                  name="firstName"
                  id="firstName"
                  label={t('personal_details_firstname')}
                  placeholder={t('personal_details_firstname_placeholder')}
                  validate={nameValidator(t, t('personal_details_firstname'))}
                  fullWidth
                />
                <Field
                  component={TextField}
                  name="lastName"
                  id="lastName"
                  label={t('personal_details_lastname')}
                  placeholder={t('personal_details_lastname_placeholder')}
                  validate={nameValidator(t, t('personal_details_lastname'))}
                  fullWidth
                />
              </Fieldset>
            </FormFieldsWrapper>
            <ButtonsContainer alignCenter={true}>
              <Button type="submit" loading={isLoading} id="web-onboarding-personal-details">
                {t('common_continue')}
              </Button>
            </ButtonsContainer>
          </Form>
        </FormikProvider>
        <Typography component="p" variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </FormContent>
    </FormLayout>
  );
};

export default FullnameStep;
