import { AddressFieldTypes, AddressTypes } from '../models/onboarding.models';
import {
  countryAddressConfig,
  customAddressFullWidth,
  customCountryAddressFormLabels,
} from '../../../config/countryAddress';
import {
  StreetField,
  CityField,
  NumberAdditionField,
  AddressNumberField,
  AddressBuildingField,
  PostCodeField,
  ProvinceField,
} from '../components/steps/address/AddressFieldComponents';
import React from 'react';
import { TFunction } from 'react-i18next';

const getCountryFields = (country: keyof typeof AddressTypes): string[] => {
  return country && countryAddressConfig[country]
    ? countryAddressConfig[country]
    : countryAddressConfig[AddressTypes.DEFAULT];
};

const getAddressCustomFields = (
  country: keyof typeof AddressTypes,
): { [key in AddressFieldTypes]?: string } | undefined => {
  return country && customCountryAddressFormLabels[country];
};

const getAddressFieldWidth = (
  country: keyof typeof AddressTypes,
): { [key in AddressFieldTypes]?: boolean } | undefined => {
  return country && customAddressFullWidth[country];
};

export type AddressFieldMapper = {
  [key: string]: any;
};

const mapAddressFieldToComponent: AddressFieldMapper = {
  [AddressFieldTypes.STREET]: StreetField,
  [AddressFieldTypes.NUMBER]: AddressNumberField,
  [AddressFieldTypes.NUMBER_ADDITION]: NumberAdditionField,
  [AddressFieldTypes.BUILDING]: AddressBuildingField,
  [AddressFieldTypes.POSTAL_CODE]: PostCodeField,
  [AddressFieldTypes.CITY]: CityField,
  [AddressFieldTypes.PROVINCE]: ProvinceField,
};

const getAddressFieldComponent = (country: keyof typeof AddressTypes, fieldType: string, key: number, t: TFunction) => {
  const customFields = getAddressCustomFields(country);
  const customFieldWidth = getAddressFieldWidth(country);
  const fieldComponent = mapAddressFieldToComponent[fieldType];
  let customLabel = undefined;
  if (customFields && Object.keys(customFields).includes(fieldType)) {
    const fieldTypeKey = fieldType as AddressFieldTypes;
    customLabel = customFields[fieldTypeKey];
  }
  let customWidth = undefined;
  if (customFieldWidth && Object.keys(customFieldWidth).includes(fieldType)) {
    const fieldTypeKey = fieldType as AddressFieldTypes;
    customWidth = customFieldWidth[fieldTypeKey];
  }
  return fieldComponent ? (
    <React.Fragment key={key}>{fieldComponent(t, customLabel, customWidth)}</React.Fragment>
  ) : null;
};

export { getCountryFields, getAddressFieldComponent };
