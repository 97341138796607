import React from 'react';
import { useFreshChat } from '../../../config/freshChat';
import CircularProgress from '../../theme/components/CircularProgress';
import styled from '@emotion/styled';

const StyledLoaderContainer = styled.div`
  position: absolute;
  right: 0.75rem;
  bottom: 0.35rem;
  height: 44px;
  width: 44px;
  background: #ffffff;
  opacity: 0.8;
`;

const FreshChatLoadingSpinner = () => {
  const { isInitializing } = useFreshChat();

  return isInitializing ? (
    <StyledLoaderContainer>
      <CircularProgress size={'1.25rem'} />
    </StyledLoaderContainer>
  ) : (
    <></>
  );
};

export default FreshChatLoadingSpinner;
