import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loadScript from '../modules/onboarding/utils/loadScript';

type fcContextType = {
  openFreshChat: () => void;
  isInitializing: boolean;
  initializeFc: () => void;
};

type fcWidgetType = {
  init: (config: any) => void;
  on: (eventName: string, callback: (resp?: any) => void) => void;
  user: {
    setLocale: (locale: string) => void;
  };
  isOpen: () => boolean;
  open: () => void;
  close: () => void;
  hide: (arg?: string) => void;
  show: (arg?: string) => void;
};

interface WindowWithFreshChatWidget extends Window {
  fcWidget: fcWidgetType;
}

declare const window: WindowWithFreshChatWidget;

function initFreshChat(locale: string) {
  window.fcWidget.init({
    token: '19fbff67-b2f0-4cbf-9f34-6f5b51ceeb2a',
    host: 'https://wchat.freshchat.com',
    tags: ['webonboarding'],
    locale,
  });
}

async function initialize(lang: string) {
  await loadScript('https://wchat.freshchat.com/js/widget.js', 'Freshchat-js-sdk');
  initFreshChat(lang);

  window.fcWidget.on('widget:closed', function () {
    window.fcWidget.hide();
  });

  return new Promise<void>((resolve) => {
    window.fcWidget.on('widget:loaded', () => {
      resolve();
    });
  });
}

const FreshChatContext = React.createContext<fcContextType>({
  openFreshChat: () => {},
  isInitializing: false,
  initializeFc: () => {},
});

export const FreshChatProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { i18n } = useTranslation();
  const [isInitializing, setIsInitializing] = useState(false);

  const openFreshChat = async () => {
    if (!window.fcWidget) {
      setIsInitializing(true);
      await initialize(i18n.language);
      i18n.on('languageChanged', (lang) => {
        window.fcWidget.user.setLocale(lang);
      });
      setIsInitializing(false);
    }
    const freshChatWidget: fcWidgetType = window.fcWidget;
    if (freshChatWidget) {
      freshChatWidget.show();
      freshChatWidget.open();
    }
  };

  const initializeFc = async () => {
    if (!window.fcWidget) {
      setIsInitializing(true);
      await initialize(i18n.language);
      i18n.on('languageChanged', (lang) => {
        window.fcWidget.user.setLocale(lang);
      });
      setIsInitializing(false);
    }
  };

  return (
    <FreshChatContext.Provider value={{ openFreshChat, isInitializing, initializeFc }}>
      {children}
    </FreshChatContext.Provider>
  );
};

export function useFreshChat(): fcContextType {
  const freshChat = useContext(FreshChatContext);
  return freshChat;
}
