import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { OnboardingSteps } from '../../../models/onboarding.models';
import { LoadingContainer } from '../../../../common/components';
import { CircularProgress } from '../../../../theme/components';
import { submitSelfie } from '../../../store';

const SelfieStep = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state: IRootState) => state.onboarding);

  useEffect(() => {
    /* Once this component loads, we tell the backend that the selfie is done already by submitting this post request.
       The selfie was already taken in the proof of Id step by the user.
       The reason we still do this component and step, is because the backend itself didn't merge the states of proof of id
       and selfie together, but since we can do this on the web frontend side, we can save some code and time,
       and remove the step from the user's perspective. It's a nice hack :)
    */

    dispatch(
      submitSelfie({
        href: OnboardingSteps.SELFIE.href,
        payload: {},
      }),
    );
  }, []);

  return (
    <>
      {loading && (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      )}
    </>
  );
};

export default SelfieStep;
