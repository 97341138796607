import { theme } from '..';

const MuiPickersModal: any = {
  dialogRoot: {
    '& .MuiButton-root': {
      minWidth: 0,
    },
    '& .MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1': {
      fontSize: 16,
      lineHeight: '22px',
    },
    '& .MuiPickersToolbarText-toolbarTxt.MuiTypography-h4': {
      fontSize: 34,
      lineHeight: '40px',
    },
    '& .MuiDialogActions-root': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
      padding: theme.spacing(3),
    },
    '& .MuiToolbar-gutters': {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      paddingTop: theme.spacing(4.5),
      paddingBottom: theme.spacing(4.5),
    },
    '& .MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
  },
};

export default MuiPickersModal;
