import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRudderAnalytics } from '../../../config/rudderstack';
import { IRootState } from '../../../Store';
import { OnboardingSteps } from '../models/onboarding.models';
import { checkCookieNotNull, getCookie } from '../../cookieconsent/helpers/cookies';
import * as Sentry from '@sentry/react';
import SentryError from '../utils/SentryError';
import { IErrorResponse } from '../../common/models';

const GTM_COOKIE_URL = '__gtm_campaign_url';

function getGTMCookie() {
  try {
    const gtmCampaignUrl = checkCookieNotNull(GTM_COOKIE_URL) && getCookie(GTM_COOKIE_URL);
    if (gtmCampaignUrl) {
      return decodeURIComponent(gtmCampaignUrl);
    } else {
      return false;
    }
  } catch (e) {
    Sentry.captureException(new SentryError(e as IErrorResponse));
    console.error(e);
    return false;
  }
}

function useStepTracking(step: OnboardingSteps) {
  const rudderAnalytics = useRudderAnalytics();
  const [identifySent, setIdentifySent] = useState(false);
  const { userId } = useSelector((state: IRootState) => state.auth);
  useEffect(() => {
    if (rudderAnalytics) {
      // assume email confirmed upon reaching personal details step.
      if (step === OnboardingSteps.FULLNAME) {
        rudderAnalytics.page('web_onboarding', 'email_confirmed');
      }

      if (!identifySent && step === OnboardingSteps.NOT_STARTED) {
        try {
          const gtmCampaignUrl = getGTMCookie();
          rudderAnalytics.identify(userId, { userId, gtmCampaignUrl }, undefined, undefined);
          setIdentifySent(() => true);
        } catch (e) {
          Sentry.captureException(new SentryError(e as IErrorResponse));
          console.error(e);
        }
      }

      const sendPageEvent = () => {
        if (step) {
          step.trackingPage && rudderAnalytics.page('web_onboarding', step.trackingPage);
        }
      };

      const timer = setTimeout(() => {
        sendPageEvent();
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [step, rudderAnalytics]);
}

export default useStepTracking;
