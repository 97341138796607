import styled from '@emotion/styled';

export const ContentWrapper = styled.div`
  grid-row: 2;
  grid-column: 1;
  margin: 0 24px;
  ${({ theme }) => `@media (max-width: ${theme.breakpoint.sm}px)`} {
    grid-row: 1/-1;
    margin: 24px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
