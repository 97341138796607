export const apiConfig = {
  api: {
    baseUrl: '/',
  },
  endpoints: {
    auth: 'auth/get-anonymous',
    applicants: 'applicants/api/4',
    guestAccount: 'api/create-guest-account',
    socialSignIn: 'api/social-signin',
  },
};
