import { apiConfig } from '../../common/api/apiConfig';
import { fetchRequest, HTTPMethods } from '../../common/api/fetchRequest';
import { IApplicationResponse, IEmailVerificationResponse, IProofIdStepPayload } from '../models/onboarding.models';
import { IErrorResponse } from '../../common/models';

export const startApplication: () => Promise<IApplicationResponse | IErrorResponse> = async () => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/applications`, {
    method: HTTPMethods.POST,
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getUserApplication: () => Promise<IApplicationResponse | IErrorResponse> = async () => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/applications/current`, {
    method: HTTPMethods.GET,
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getApplicationDataForStep: (url: string) => Promise<unknown | IErrorResponse> = async (url: string) => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/${url}`, {
    method: HTTPMethods.GET,
  });
};

export const putApplicationDataForStep: (
  url: string,
  payload: unknown,
  method?: 'post' | 'put',
) => Promise<unknown | IErrorResponse> = async (url: string, payload: unknown, method) => {
  const postUrlsList = ['email[-_]verification', 'proof[-_]of[-_]id'];
  const isRequestMethodUrlPOST = postUrlsList.filter((s) => new RegExp(s).test(url)).length > 0;
  const requestMethod = isRequestMethodUrlPOST || (method && method === 'post') ? HTTPMethods.POST : HTTPMethods.PUT;
  return await fetchRequest(`${apiConfig.endpoints.applicants}/${url}`, {
    method: requestMethod,
    data: payload,
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getEmailVerificationStatus: () => Promise<IEmailVerificationResponse> = async () => {
  return await fetchRequest(
    `${apiConfig.endpoints.applicants}/applications/current/personal_details/email-verification`,
  );
};

export const getProofOfIdVerificationStatus: () => Promise<IProofIdStepPayload> = async () => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/applications/current/proof_of_id`);
};

export const getVerificationStatus: (path: string) => Promise<unknown | IErrorResponse> = async (path: string) => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/applications/current/${path}`);
};

export const skipTaxStep: (url: string) => Promise<unknown | IErrorResponse> = async (url: string) => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/${url}`, {
    method: HTTPMethods.DELETE,
    data: {},
    headers: { 'Content-Type': 'application/json' },
  });
};

export const resendPhoneCodeVerification: () => Promise<unknown | IErrorResponse> = async () => {
  return await fetchRequest(`${apiConfig.endpoints.applicants}/applications/current/phone_verification/resend`, {
    method: HTTPMethods.POST,
    data: {},
    headers: { 'Content-Type': 'application/json' },
  });
};

export const createStockGuestAccount: () => Promise<unknown | IErrorResponse> = async () => {
  return await fetchRequest(`${apiConfig.endpoints.guestAccount}`, {
    method: HTTPMethods.POST,
    data: {},
    headers: { 'Content-Type': 'application/json' },
  });
};

export const exchangeGoogleAuthCode: (code: string) => Promise<unknown | IErrorResponse> = async (code: string) => {
  return await fetchRequest(`${apiConfig.endpoints.socialSignIn}/google`, {
    method: HTTPMethods.POST,
    data: { code },
    headers: { 'Content-Type': 'application/json' },
  });
};
