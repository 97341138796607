import React from 'react';
import { Box, Typography } from './index';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  separator: {
    display: 'flex',
    flexBasis: 0,
    flexGrow: 1,
    height: 1,
    minWidth: 50,
    backgroundColor: theme.palette.grey['500'],
  },
  text: {
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const TextWithSeparator = ({ children }: { children: string }) => {
  const { separator, text } = useStyles();
  return (
    <Box display="flex" alignItems="center" flexGrow={1} width="100%">
      <div className={separator} />
      <Typography className={text}>{children}</Typography>
      <div className={separator} />
    </Box>
  );
};

export default TextWithSeparator;
