import { TFunction } from 'react-i18next';
import { lengthValidator, noDigitsValidator, noSpecialCharsValidator, requiredValidator } from './index';

const cityOfBirthValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  return (
    requiredValidator(t, fieldName)(value) ||
    noSpecialCharsValidator(t, fieldName)(value) ||
    noDigitsValidator(t, fieldName)(value) ||
    lengthValidator(t, 0, 60, fieldName)(value)
  );
};

export default cityOfBirthValidator;
