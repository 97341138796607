import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { ITaxStepConfiguration, ITaxValues, KeyBoardType } from '../../../models/onboarding.models';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Button, CircularProgress, TextField, Typography } from '../../../../theme/components';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { taxCodeValidator } from '../../../utils/validators';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingContainer } from '../../../../common/components';
import { IErrorResponse } from '../../../../common/models';

interface ISecondTaxCountryProps {
  country?: { label: string; value: string };
  handleBackButton: () => void;
  onSubmit: (values: { secondaryTaxValue: string }) => void;
  error: IErrorResponse | null;
  isLoading: boolean;
  taxPayload: ITaxValues;
}

const useStyles = makeStyles(() => ({
  inputStyle: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const SecondaryTaxValueFormContainer: FC<ISecondTaxCountryProps> = ({
  country,
  handleBackButton,
  onSubmit,
  error,
  isLoading,
  taxPayload,
}) => {
  const { inputStyle } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { tax_second_country_config } = useSelector((state: IRootState) => state.onboarding);
  const [isInitializing, setIsInitializing] = useState(true);

  const taxConfig = tax_second_country_config as ITaxStepConfiguration;

  useEffect(() => {
    if (tax_second_country_config) {
      setIsInitializing(false);
    }
  }, []);

  const initialValues = { secondaryTaxValue: '' };
  const formik = useFormik({ initialValues, onSubmit });

  const title = taxConfig?.displayName || `${country?.label} tax`;

  return (
    <>
      {isLoading || isInitializing ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <FormLayout>
          <Typography variant="h2" gutterBottom={true}>
            {t('transactions_question', { transactionName: title })}
          </Typography>
          <FormContent>
            <FormikProvider value={formik}>
              <Form>
                <FormFieldsWrapper>
                  {error && (
                    <>
                      <Typography color="error" variant="subtitle2">
                        {t('common_error_sorry_there_is_a_problem')}
                      </Typography>
                      <Typography color="error" variant="caption" component="p" gutterBottom>
                        {t('common_error_please_try_again')}
                      </Typography>
                    </>
                  )}
                  <Typography gutterBottom={true}>{t('transactions_disclaimer')}</Typography>
                  <Field
                    className={inputStyle}
                    component={TextField}
                    type={taxConfig && taxConfig.keyboardType === KeyBoardType.NUMERIC ? 'tel' : 'text'}
                    name="secondaryTaxValue"
                    id="secondaryTaxValue"
                    placeholder={taxConfig?.example || ''}
                    validate={taxCodeValidator(t, taxPayload.taxNumber, taxConfig.example, taxConfig.validationRule)}
                    fullWidth={true}
                  />
                </FormFieldsWrapper>
                <ButtonsContainer marginTop={isMobile ? 0 : 1}>
                  <Button variant="secondary" onClick={handleBackButton}>
                    {t('common_go_back')}
                  </Button>
                  <Button type="submit" loading={isLoading} id="web-onboarding-personal-details">
                    {t('common_continue')}
                  </Button>
                </ButtonsContainer>
              </Form>
            </FormikProvider>
          </FormContent>
        </FormLayout>
      )}
    </>
  );
};

export default SecondaryTaxValueFormContainer;
