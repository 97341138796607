import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pollForVerification } from '../../../store';
import { IRootState } from '../../../../../Store';
import { MAX_POLL_PHOTO_ID } from '../../../../common/constants';
import FeedbackLayout, { Content, ContentWrapper, Spacer } from '../../../../common/components/FeedbackLayout';
import { Graphic } from '../../../../common/components';
import idverify from '../../../../../assets/images/idverify.svg';
import { Typography } from '../../../../theme/components';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { OnboardingSteps } from '../../../models/onboarding.models';

const WaitingVerification = () => {
  const { pollingAttempt } = useSelector((state: IRootState) => state.onboarding);
  const [showDelayedVerificationMessage, setShowDelayedVerificationMessage] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    dispatch(pollForVerification({ path: OnboardingSteps.SELFIE.toString() }));
  }, []);

  useEffect(() => {
    if (pollingAttempt > MAX_POLL_PHOTO_ID) {
      setShowDelayedVerificationMessage(true);
    }
  }, [pollingAttempt]);

  return (
    <FeedbackLayout>
      <Spacer />
      <ContentWrapper marginBottom={0}>
        <Graphic image={idverify} />
        <Typography variant="h2" gutterBottom>
          {t('selfie_wait_screen_title')}
        </Typography>
        <Content>
          <Typography gutterBottom>
            {showDelayedVerificationMessage ? t('errors_common_please_refresh') : t('selfie_wait_screen')}
          </Typography>
        </Content>
      </ContentWrapper>
      <Spacer minHeight={60}>
        <Typography variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </Spacer>
    </FeedbackLayout>
  );
};

export default WaitingVerification;
