import { ButtonClassKey, StyleRules } from '@material-ui/core';
import { theme } from '../';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiButton: Partial<StyleRules<ButtonClassKey, {}>> = {
  root: {
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 16,
    fontWeight: 600,
    borderRadius: 24,
    height: 48,
    '&$disabled': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: 44,
    },
    minWidth: 194,
    [theme.breakpoints.down('xs')]: {
      minWidth: 144,
    },
  },
  fullWidth: {
    width: '100%',
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  outlinedSecondary: {
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.light,
    },
  },
  textPrimary: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
    },
  },
  textSecondary: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
    },
  },
  contained: {
    border: 0,
    color: 'white',
    padding: '0 30px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&$disabled': {
      color: 'none',
      backgroundColor: 'none',
      opacity: 0.3,
    },
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  containedSecondary: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  label: {
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
};

export default MuiButton;
