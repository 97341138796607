import { FormControlLabelClassKey, StyleRules } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiFormControlLabel: Partial<StyleRules<FormControlLabelClassKey, {}>> = {
  label: {
    fontSize: 16,
    marginLeft: 3,
  },
  root: {
    marginLeft: -9,
  },
};

export default MuiFormControlLabel;
