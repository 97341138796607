import { LinkClassKey, StyleRules } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiLink: Partial<StyleRules<LinkClassKey, {}>> = {
  underlineAlways: {
    textUnderlinePosition: 'under',
  },
};

export default MuiLink;
