import React, { FC } from 'react';
import { ButtonsContainer } from '../../../../common/components/FormLayout';
import { Button, CheckboxWithLabel } from '../../../../theme/components';
import { useTranslation } from 'react-i18next';
import { IAgreementOptions } from './config';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fieldSetContainer: {
    border: 'none',
  },
}));

interface IAgreementContainer {
  handleContinue: () => void;
  agreementOptions: IAgreementOptions;
}

const AgreeButtonSlider: FC<IAgreementContainer> = ({ handleContinue, agreementOptions }) => {
  const { t } = useTranslation();
  const { fieldSetContainer } = useStyles();

  const formik = useFormik({
    initialValues: agreementOptions ? Object.fromEntries(agreementOptions?.options.map((d) => [d, false])) : {},
    onSubmit: handleContinue,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <div>
            <Box component="fieldset" className={fieldSetContainer}>
              {agreementOptions?.options.map((d: string, i) => {
                return (
                  <div key={`${d}-${i}`}>
                    <Field type="checkbox" component={CheckboxWithLabel} label={t(d)} name={d} required />
                  </div>
                );
              })}
            </Box>
          </div>
          <ButtonsContainer alignCenter={true} maxWidth={'unset'}>
            <Button type="submit" data-testid={'agreement-continue-button'}>
              {t('common_continue')}
            </Button>
          </ButtonsContainer>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AgreeButtonSlider;
