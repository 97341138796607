import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import FormLayout, { Content as FormContent } from '../../../../common/components/FormLayout';
import { CircularProgress, Typography } from '../../../../theme/components';
import { IProofIdStepPayload, OnboardingSteps } from '../../../models/onboarding.models';
import { LoadingContainer } from '../../../../common/components';
import * as Onfido from 'onfido-sdk-ui';
import { SupportedLanguages } from 'onfido-sdk-ui';
import { getOnfidoInstance } from './proofOfIdConfig';
import { gotoStep, handleOnboardingError } from '../../../store';
import { ONFIDO_ERROR } from '../../../../common/constants';
import { OnfidoMount } from './ProofOfIdStyles';
import './override.css';
import { useIsMounted } from '../../../helpers/useIsMounted';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { Spacer } from '../../../../common/components/FeedbackLayout';

const ProofOfIdStep = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const {
    currentStep: { payload },
    POIAttempted,
  } = useSelector((state: IRootState) => state.onboarding);

  const [onfidoInstance, setOnfidoInstance] = useState<Onfido.SdkHandle | null>(null);
  const [loading, setLoading] = useState(false);

  const onfidoPayload = payload as IProofIdStepPayload;

  const initOnfido = async () => {
    try {
      setLoading(true);
      const language = i18n.language as SupportedLanguages;
      const instance = await getOnfidoInstance(onfidoPayload, language, theme, t, dispatch, POIAttempted);
      setOnfidoInstance(instance);
      setLoading(false);
    } catch (e) {
      console.error('Failed to init Onfido', e);
      setLoading(false);
      dispatch(handleOnboardingError({ errorCode: ONFIDO_ERROR, message: 'Failed to init Onfido' }));
    }
  };

  useEffect(() => {
    if (onfidoPayload._inProgress) {
      dispatch(gotoStep(OnboardingSteps.PROOF_OF_ID_VERIFICATION));
    } else {
      if (isMounted.current) {
        initOnfido();
      }
    }
    return () => {
      onfidoInstance && onfidoInstance.tearDown();
    };
  }, [onfidoPayload]);

  return (
    <>
      <FormLayout>
        <FormContent>
          <OnfidoMount id="onfido-mount" contentText={t('fastest_option')} isMobile={isMobile}>
            {loading && (
              <LoadingContainer data-testid="loading-view">
                <CircularProgress />
              </LoadingContainer>
            )}
          </OnfidoMount>
          <Spacer minHeight={60}>
            <Typography variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
              {t('common_need_help')} <ChatWithSupport />
            </Typography>
          </Spacer>
        </FormContent>
      </FormLayout>
    </>
  );
};

export { ProofOfIdStep };
