import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const palette: PaletteOptions = {
  background: {
    default: '#fff',
  },
  primary: {
    main: '#ff3c64', // amaranth
    light: '#ff9db1', // amaranth 50%
  },
  secondary: {
    main: '#5fcd6f', // emerald
  },
  text: {
    secondary: '#999999', // dustyGray
  },
  error: {
    main: '#a9132d', // warning
  },
  grey: {
    '500': '#d6d5d5', // gray
    '200': '#f4f2f2', // lightGray
    '100': '#303030', // blackGray
  },
};

export default palette;
