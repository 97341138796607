import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import * as rudderAnalytics from 'rudder-sdk-js';
import { fetchRequest } from '../modules/common/api/fetchRequest';
import { CookiePolicyContext } from '../modules/cookieconsent/components/CookiePolicyProvider';
import * as Sentry from '@sentry/react';

async function initRudderstack(callback?: () => void) {
  try {
    const response = await fetchRequest(`rudderstack.env.json`);
    if (!response || !response.write_key || !response.data_plane) {
      throw new Error('Invalid response');
    }
    rudderAnalytics.load(response.write_key, response.data_plane);
    rudderAnalytics.ready(() => {
      if (process.env.NODE_ENV === 'development') {
        console.info('Rudder analytics ready');
      }
      callback && callback();
    });
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

async function removeRudderstack(callback?: () => void) {
  try {
    rudderAnalytics.reset();
    if (process.env.NODE_ENV === 'development') {
      console.info('Rudder analytics reset');
    }
    callback && callback();
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
}

const RudderAnalyticsContext = React.createContext<typeof rudderAnalytics | null>(null);

export const RudderAnalyticsProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { isRudderStackConsentGiven, isUCInitialized, isUCActionGiven } = useContext(CookiePolicyContext);
  const [value, setValue] = useState<typeof rudderAnalytics | null>(null);
  useEffect(() => {
    if (isUCInitialized && isRudderStackConsentGiven) {
      initRudderstack(() => {
        setValue(rudderAnalytics);
      });
    } else if (isUCInitialized && isUCActionGiven && !isRudderStackConsentGiven) {
      removeRudderstack(() => {
        setValue(null);
      });
    }
  }, [isRudderStackConsentGiven, isUCInitialized, isUCActionGiven]);
  return <RudderAnalyticsContext.Provider value={value}>{children}</RudderAnalyticsContext.Provider>;
};

export function useRudderAnalytics(): typeof rudderAnalytics | null {
  const rudderAnalytics = useContext(RudderAnalyticsContext);
  return rudderAnalytics;
}
