import { Box, BoxProps, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from '../../theme/components';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    '& > form': {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '& fieldset': {
      width: '100%',
      margin: 0,
      marginBottom: theme.spacing(6),
      border: 0,
      padding: 0,
      textAlign: 'left',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
      },
    },
    '& fieldset > legend, & fieldset > legend.Mui-focused': {
      color: theme.palette.common.black,
    },
    '& fieldset > .MuiFormControl-root:not(.MuiFormControl-fullWidth)': {
      width: 194,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },
  formFieldsWrapper: {
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  buttonsContainer: {
    '& div:only-child': {
      marginLeft: 'auto',
    },
  },
  buttonsContainerColumn: {
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(3),
    },
  },
  errorMessage: {
    borderTop: `1px solid ${theme.palette.error.main}`,
    width: '100%',
    textAlign: 'left',
  },
}));

const FormLayout = ({ children }: React.PropsWithChildren<unknown>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box marginTop={isMobile ? 0 : '24px'} width="100%" flex={1} display="flex" flexDirection="column">
      {children}
    </Box>
  );
};

export const Content = ({ children, ...boxProps }: React.PropsWithChildren<BoxProps>) => {
  const { content } = useStyles();
  return (
    <Box
      flex={1}
      display="flex"
      width="100%"
      maxWidth={400}
      margin="0 auto"
      flexDirection="column"
      alignItems="center"
      className={content}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export const FormFieldsWrapper = ({ children, ...boxProps }: React.PropsWithChildren<BoxProps>) => {
  const { formFieldsWrapper } = useStyles();
  return (
    <Box height="auto" width="100%" className={formFieldsWrapper} {...boxProps}>
      {children}
    </Box>
  );
};

export const Fieldset = ({ children }: React.PropsWithChildren<unknown>) => {
  return <Box component="fieldset">{children}</Box>;
};

export const ButtonsContainer = ({
  children,
  alignCenter = false,
  ...BoxProps
}: React.PropsWithChildren<{ alignCenter?: boolean } & BoxProps>) => {
  const { buttonsContainer, buttonsContainerColumn } = useStyles();
  return (
    <Box
      className={
        BoxProps.flexDirection !== 'column' ? (!alignCenter ? buttonsContainer : undefined) : buttonsContainerColumn
      }
      display="flex"
      justifyContent={alignCenter ? 'center' : 'space-between'}
      maxWidth={400}
      width="100%"
      {...BoxProps}
    >
      {children}
    </Box>
  );
};

export function ErrorMessage({ children }: React.PropsWithChildren<unknown>) {
  const { errorMessage } = useStyles();
  return (
    <Box className={errorMessage}>
      <Typography variant="caption" color="error">
        {children}
      </Typography>
    </Box>
  );
}

export default FormLayout;
