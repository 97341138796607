import React, { useState } from 'react';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Field, Form, FormikProvider } from 'formik';
import { Error } from '../personaldetails';
import { Button, RadioGroup, RadioWithLabel, Typography } from '../../../../theme/components';
import { questionnaireValidator } from '../../../utils/validators';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useFormikWithErrors } from '../../../helpers';
import { IErrorResponse } from '../../../../common/models';

interface IRiskWarningProps {
  error: IErrorResponse | null;
  isLoading: boolean;
  onSubmit: (dataToSubmit: any) => void;
  initialValues: unknown;
}

const RiskWarning = ({ error, isLoading, onSubmit, initialValues }: React.PropsWithChildren<IRiskWarningProps>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [buttonText, setButtonText] = useState('common_continue');

  const onChange = (value: any) => {
    if (value === 'false') {
      setButtonText('risk_warning_quit_button');
    } else {
      setButtonText('common_continue');
    }
    return;
  };

  const { touched, errors, handleChange, ...formik } = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit,
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  const risk = {
    title: 'risk_warning_title',
    description_p1: 'risk_warning_desc_p1',
    description_p2: 'risk_warning_desc_p2',
    description_p3: 'risk_warning_desc_p3',
    understandOption: 'risk_warning_continue',
    declineOption: 'risk_warning_decline',
  };

  return (
    <>
      <FormLayout>
        <Typography variant="h2" component="legend" align="left" style={{ marginBottom: 10 }}>
          {t(risk.title)}
        </Typography>
        <Typography align="left" gutterBottom>
          {t(risk.description_p1)}
        </Typography>
        <Typography align="left" gutterBottom>
          {t(risk.description_p2)}
        </Typography>
        <Typography align="left" gutterBottom>
          {t(risk.description_p3)}
        </Typography>

        <FormContent maxWidth={isMobile ? 400 : 600}>
          <FormikProvider value={{ touched, errors, handleChange, ...formik }}>
            <Form>
              <FormFieldsWrapper
                marginTop={3}
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                }}
              >
                {error && <Error />}
                <Fieldset>
                  <Field
                    component={RadioGroup}
                    name={'acceptsRisk'}
                    aria-label={'acceptsRisk'}
                    validate={questionnaireValidator(t)}
                    style={{ marginTop: 24 }}
                    onChange={(e: React.FocusEvent<any>) => {
                      e.preventDefault();
                      handleChange(e);
                      onChange(e.target.value);
                    }}
                  >
                    <RadioWithLabel value={'true'} label={t(risk.understandOption)} />
                    <RadioWithLabel value={'false'} label={t(risk.declineOption)} />
                  </Field>
                  {touched['acceptsRisk'] && errors && errors['acceptsRisk'] && (
                    <Typography variant="caption" color="error">
                      {errors['acceptsRisk']}
                    </Typography>
                  )}
                </Fieldset>
              </FormFieldsWrapper>
              <ButtonsContainer maxWidth={isMobile ? 400 : 500} justifyContent={'start'} marginTop={isMobile ? 0 : 1}>
                <Button type="submit" fullWidth={isMobile} loading={isLoading} style={{ marginLeft: 'unset' }}>
                  {t(buttonText)}
                </Button>
              </ButtonsContainer>
            </Form>
          </FormikProvider>
        </FormContent>
      </FormLayout>
    </>
  );
};

export default RiskWarning;
