export const BOS_APPLICATION_EXISTS_ERROR = 'BOS_201';
export const EMAIL_ALREADY_EXISTS = 'BOS_102';
export const SOCIAL_EMAIL_ALREADY_EXISTS = 'BOS_226';
export const BOS_APPLICATION_NOT_FOUND = 'BOS_004';
export const EMAIL_POLLING_TIME_OUT = '408';
export const BOS_MAINTENANCE_ERROR = 'BOS_005';
export const BOS_VALIDATION_ERROR = 'BOS_002';
export const ONFIDO_ERROR = 'ONFIDO';
export const PHONE_VERIFICATION_ERROR = 'BOS_210';
export const PHONE_VERIFICATION_MAX_EXCEED = 'BOS_212';
export const PHONE_VERIFICATION_INCORRECT = 'BOS_213';

export const EMAIL_POLLING_TIME_OUT_MESSAGE = 'Polling timed out.';
