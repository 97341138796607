import React, { FC } from 'react';
import { IErrorResponse } from '../../../../common/models';
import { useTranslation } from 'react-i18next';
import { Fieldset } from '../../../../common/components/FormLayout';
import AddressStepForm from './AddressStepForm';
import { AddressFieldTypes, AddressTypes } from '../../../models/onboarding.models';
import { AddressFieldMapper, getAddressFieldComponent, getCountryFields } from '../../../helpers/addressFieldMapper';

interface IAddressForm {
  onSubmitFn: (values: unknown) => void;
  onGoBack?: () => void;
  error: IErrorResponse | null;
  isLoading: boolean;
  country: keyof typeof AddressTypes;
}

class IAddressFormValuesClass implements Record<AddressFieldTypes, unknown> {
  street!: string;
  number!: string;
  building: string | undefined;
  numberAddition: string | undefined;
  province: string | undefined;
  city!: string;
  county: string | undefined;
  postalCode!: string;
}

interface IAddressFormValues extends IAddressFormValuesClass {}

const AddressForm: FC<IAddressForm> = ({ isLoading, error, onSubmitFn, onGoBack, country }) => {
  const { t } = useTranslation();

  const initialState: IAddressFormValues = {
    street: '',
    number: '',
    building: '',
    numberAddition: '',
    province: '',
    city: '',
    county: '',
    postalCode: '',
  };

  const onSubmit = (values: IAddressFormValues) => {
    const listOfFields = getCountryFields(country);
    const fieldValues = listOfFields.reduce((o: AddressFieldMapper, fieldName: string) => {
      const fieldNameKey = fieldName as keyof IAddressFormValues;
      if (values[fieldNameKey]) {
        o[fieldName] = values[fieldNameKey];
      }
      return o;
    }, {} as AddressFieldMapper);
    onSubmitFn({
      currentAddress: fieldValues,
    });
  };

  return (
    <AddressStepForm
      onSubmit={onSubmit}
      onGoBack={onGoBack}
      initialValues={initialState}
      isLoading={isLoading}
      error={error}
    >
      <Fieldset>
        {getCountryFields(country).map((fieldType: string, key) => {
          return getAddressFieldComponent(country, fieldType, key, t);
        })}
      </Fieldset>
    </AddressStepForm>
  );
};

export default AddressForm;
