import React, { useCallback, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { EMAIL_ALREADY_EXISTS, EMAIL_POLLING_TIME_OUT } from '../../../../common/constants/errors';
import { Button, Typography } from '../../../../theme/components';
import { ButtonsContainer } from '../../../../common/components/FormLayout';
import FeedbackLayout, { Content, ContentWrapper, Spacer } from '../../../../common/components/FeedbackLayout';
import { useTranslation } from 'react-i18next';
import { useRudderAnalytics } from '../../../../../config/rudderstack';
import { Graphic } from '../../../../common/components/Graphic';
import checkemailgraphic from '../../../../../assets/images/checkemail.svg';
import { useQRModal } from '../continueonapp/QRModal';
import EmailPollingTimeout from './EmailPollingTimeout';
import { useDispatch, useSelector } from 'react-redux';
import { submitUserEmail } from '../../../store';
import { ISubmitApplicationPayload, OnboardingSteps } from '../../../models/onboarding.models';
import { IRootState } from '../../../../../Store';
import EmailAlreadyExists from './EmailAlreadyExists';

const WaitForEmailVerificationStep: React.FC = () => {
  const { t } = useTranslation();
  const rudderAnalytics = useRudderAnalytics();
  const modal = useQRModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const {
    currentStep: { payload },
    error,
  } = useSelector((state: IRootState) => state.onboarding);
  const submittedEmail = useMemo(() => {
    const { email } = (payload as undefined | { email?: string }) || { email: '' };
    return email || [];
  }, [payload]);

  const handleResendEmail = useCallback(() => {
    rudderAnalytics && rudderAnalytics.track('email_confirmation_resend');
    const data: ISubmitApplicationPayload = {
      href: OnboardingSteps.VERIFY_EMAIL.href,
      payload: { email: submittedEmail },
    };
    dispatch(submitUserEmail(data));
  }, [submittedEmail]);

  const openGmail = () => {
    rudderAnalytics && rudderAnalytics.track('open_gmail_button');
    window.open('https://mail.google.com/mail/', '_blank');
  };

  const isGmail = (emailAddress: string) => {
    const emailDomain = emailAddress.lastIndexOf('@');
    return (
      emailDomain > -1 &&
      (emailAddress.slice(emailDomain + 1) === 'gmail.com' || emailAddress.slice(emailDomain + 1) === 'googlemail.com')
    );
  };

  if (!error && submittedEmail && submittedEmail.length > 0) {
    return (
      <FeedbackLayout>
        <Spacer />
        <ContentWrapper>
          <Graphic image={checkemailgraphic}></Graphic>
          <Typography variant="h2" gutterBottom>
            {t('email_verify_title')}
          </Typography>
          <Content>
            <Typography gutterBottom style={{ marginBottom: 12 }}>
              {t('email_verify_email_sent_to')}{' '}
              <Typography component="span" color="textSecondary">
                {submittedEmail}
              </Typography>
            </Typography>
            <Typography gutterBottom>{t('email_verify_descriptive_text')}</Typography>
          </Content>
        </ContentWrapper>
        {isGmail(submittedEmail as string) ? (
          <ButtonsContainer flexDirection="column" marginTop={isMobile ? 0 : 1}>
            <Button fullWidth={isMobile} onClick={openGmail} id="web-onboarding-email-is-gmail">
              {t('open_gmail')}
            </Button>
            <Button
              variant={isMobile ? 'secondary' : 'text'}
              onClick={handleResendEmail}
              fullWidth={isMobile}
              id="web-onboarding-resend-email"
            >
              {t('email_verify_resend')}
            </Button>
          </ButtonsContainer>
        ) : (
          <ButtonsContainer alignCenter>
            <Button
              variant="secondary"
              onClick={handleResendEmail}
              fullWidth={isMobile}
              id="web-onboarding-resend-email"
            >
              {t('email_verify_resend')}
            </Button>
          </ButtonsContainer>
        )}
        <Spacer disableMinHeight />
      </FeedbackLayout>
    );
  } else if (error && error.errorCode === EMAIL_ALREADY_EXISTS) {
    return <EmailAlreadyExists />;
  } else if (error && error.errorCode === EMAIL_POLLING_TIME_OUT) {
    return <EmailPollingTimeout />;
  } else {
    return null;
  }
};

export default WaitForEmailVerificationStep;
