import React, { FC } from 'react';
import { IIconProps } from './IconPropsType';

const DownloadIcon: FC<IIconProps> = ({ translateValue }) => {
  return (
    <path
      transform={`translate(${translateValue},${translateValue})`}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.69159 18.5C6.27738 18.5 5.94159 18.8358 5.94159 19.25C5.94159 19.6642 6.27738 20 6.69159 20L17.3084 20C17.7226 20 18.0584 19.6642 18.0584 19.25C18.0584 18.8358 17.7226 18.5 17.3084 18.5L6.69159 18.5ZM12.7845 13.7451L12.7845 3.75C12.7845 3.33579 12.4487 3 12.0345 3C11.6203 3 11.2845 3.33579 11.2845 3.75L11.2845 13.7451L7.30605 9.42639C7.02541 9.12174 6.57039 9.12174 6.28974 9.42639C6.00909 9.73104 6.0091 10.225 6.28974 10.5296L11.5263 16.2141C11.807 16.5187 12.262 16.5187 12.5427 16.2141L17.7793 10.5296C18.0599 10.225 18.0599 9.73103 17.7793 9.42639C17.4986 9.12174 17.0436 9.12174 16.7629 9.42639L12.7845 13.7451Z"
      fill="black"
    />
  );
};

export default DownloadIcon;
