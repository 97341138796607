import { BackdropClassKey, StyleRules } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiBackdrop: Partial<StyleRules<BackdropClassKey, {}>> = {
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

export default MuiBackdrop;
