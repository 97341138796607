import React from 'react';
import ResponsiveSimpleModal from '../../../../../theme/components/ResponsiveSimpleModal';
import { useTranslation } from 'react-i18next';

interface IIntensiveCashPopup {
  showIntensiveCashDialog: boolean;
  setShowIntensiveCashDialog: (value: boolean) => void;
  proceedToNextQuestion: () => void;
}

const IntensiveCashPopup = ({
  showIntensiveCashDialog,
  setShowIntensiveCashDialog,
  proceedToNextQuestion,
}: IIntensiveCashPopup) => {
  const { t } = useTranslation();

  const IntensiveCashPopUpBody = () => {
    return (
      <>
        <p>{t('questionnaire_select_option_dialog_desc')}</p>
        <ul>
          <li>{t('questionnaire_intensive_cash_dialog_desc_list_1')}</li>
        </ul>
        <p>{t('questionnaire_additional_docs_dialog_desc')}</p>
      </>
    );
  };
  return (
    <ResponsiveSimpleModal
      open={showIntensiveCashDialog}
      primaryButton={{
        handle: proceedToNextQuestion,
        label: t('questionnaire_continue_with_option_button'),
      }}
      secondaryButton={{
        handle: () => setShowIntensiveCashDialog(false),
        label: t('common_go_back'),
      }}
      stackButtons
      handleClose={() => setShowIntensiveCashDialog(false)}
      title={t('questionnaire_read_confirm_dialog_title')}
      body={<IntensiveCashPopUpBody />}
    />
  );
};

export default IntensiveCashPopup;
