import { TFunction } from 'react-i18next';

const requiredValidator = (t: TFunction<'translation'>, fieldName?: string) => (value: any) => {
  let error;
  const safeStringValue = typeof value === 'string' ? value : '';
  if (!value || (safeStringValue && !safeStringValue.trim())) {
    error = fieldName ? t('common_error_required_dynamic', { field: fieldName }) : t('common_required');
  }
  return error;
};

export default requiredValidator;
