import { FormGroupClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiFormGroup: Partial<StyleRules<FormGroupClassKey, {}>> = {
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),
    '& > label': {
      flex: 1,
    },
  },
};

export default MuiFormGroup;
