import { createAction } from 'typesafe-actions';
import { IErrorResponse } from '../../common/models';

export const registerUser = createAction('auth/REGISTER_USER')();

export const setAuthResponse = createAction('auth/SET_AUTH_RESPONSE')<{
  userToken: string;
  userId: string;
}>();

export const setApplicationStarted = createAction('auth/SET_APPLICATION_STARTED')<boolean>();

export const setAuthError = createAction('auth/SET_AUTH_ERROR')<IErrorResponse>();
