import React from 'react';
import {
  Autocomplete as FormikAutocomplete,
  AutocompleteProps as FormikAutocompleteProps,
} from 'formik-material-ui-lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ClearIcon from '../icons/ClearIcon';
export interface IAutocompleteOption {
  value: string;
  label: string;
}

const useStylesInput = makeStyles((theme: Theme) => ({
  error: {
    borderColor: theme.palette.error.main,
  },
}));

export type AutocompleteProps<T> = Omit<
  FormikAutocompleteProps<T, false, false, false>,
  'renderInput' | 'ref' | 'PaperComponent' | 'popupIcon' | 'closeIcon'
> & {
  placeholder?: string;
  TextFieldProps?: TextFieldProps;
};

function Autocomplete<T extends IAutocompleteOption>(props: AutocompleteProps<T>) {
  const { placeholder, TextFieldProps, ...autocompleteProps } = props;
  const classes = useStylesInput();

  return (
    <FormikAutocomplete
      getOptionLabel={(option: T) => option.label}
      {...autocompleteProps}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          {...TextFieldProps}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            ...params.InputLabelProps,
            ...TextFieldProps?.InputLabelProps,
            shrink: true,
            style: { marginTop: -8 },
          }}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps?.InputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            disableUnderline: true,
            classes,
          }}
          placeholder={placeholder}
          style={{ marginBottom: 16 }}
        />
      )}
      PaperComponent={(props) => <Paper elevation={0} {...props} />}
      popupIcon={<ArrowDownIcon />}
      closeIcon={<ClearIcon />}
    />
  );
}

export default Autocomplete;
