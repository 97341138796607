import React from 'react';
import ResponsiveSimpleModal from '../../../../../theme/components/ResponsiveSimpleModal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';

interface ICommercialEstatePopup {
  showCommercialEstateDialog: boolean;
  setShowCommercialEstateDialog: (value: boolean) => void;
  proceedToNextQuestion: () => void;
  handleOtherAnswer: () => void;
}

const CommercialEstatePopup = ({
  handleOtherAnswer,
  showCommercialEstateDialog,
  setShowCommercialEstateDialog,
  proceedToNextQuestion,
}: ICommercialEstatePopup) => {
  const { t } = useTranslation();
  const isShortScreen = useMediaQuery('(max-height:800px)');

  const CommercialEstatePopUpBody = () => {
    return (
      <>
        <p>{t('questionnaire_select_option_dialog_desc')}</p>
        <ul>
          <li>{t('questionnaire_commercial_estate_dialog_desc_list_1')}</li>
          <li>{t('questionnaire_commercial_estate_dialog_desc_list_2')}</li>
          <li>{t('questionnaire_commercial_estate_dialog_desc_list_3')}</li>
        </ul>
        {!isShortScreen && (
          <>
            <p>{t('questionnaire_additional_docs_dialog_desc')}</p>
            <p>{t('questionnaire_commercial_estate_dialog_desc_2')}</p>
          </>
        )}
      </>
    );
  };
  return (
    <ResponsiveSimpleModal
      open={showCommercialEstateDialog}
      primaryButton={{
        handle: proceedToNextQuestion,
        label: t('questionnaire_I_want_continue_button'),
      }}
      secondaryButton={{
        handle: handleOtherAnswer,
        label: t('questionnaire_other_answer'),
      }}
      stackButtons
      handleClose={() => setShowCommercialEstateDialog(false)}
      title={t('questionnaire_read_confirm_dialog_title')}
      body={<CommercialEstatePopUpBody />}
    />
  );
};

export default CommercialEstatePopup;
