export const transformURLtoLocalURL = (url: string) => {
  const agreementsLocalURLs = [
    '/static/documents/BUX-Client-Agreement-20230808-NL.pdf',
    '/static/documents/BUX-Client-Agreement-20230808-IT.pdf',
    '/static/documents/BUX-Client-Agreement-20230808-FR.pdf',
    '/static/documents/BUX-Client-Agreement-20230808-ES.pdf',
    '/static/documents/BUX-Client-Agreement-20230808-EN.pdf',
    '/static/documents/BUX-Client-Agreement-20230808-DE.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-NL-EN-20191024-v2.pdf',
    '/static/documents/Klantovereenkomst-AACB-BUX-Klant-NL-NL-20191024-v2.pdf',
    '/static/documents/Client-agreement-AACB-BUX-client-Austria-AT-EN-20191118-v1_1.pdf',
    '/static/documents/Kundenvertrag-AACB-BUX-Kunde-Austria-AT-DE-20191118-v1_1.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-Ireland-EN-20210520.pdf',
    '/static/documents/20211708-Client-agreement-AACB-BUX-client-Spain-ES.pdf',
    '/static/documents/20211708-Client-agreement-AACB-BUX-client-Spain-EN.pdf',
    '/static/documents/CTA-agreement-DE-20211115-EN-V1.1.pdf',
    '/static/documents/CTA-agreement-DE-20211115-DE-V1.1.pdf',
    '/static/documents/20220311-Client-agreement-AACB-BUX-client-Italy-EN.pdf',
    '/static/documents/20220401-Client-agreement-AACB-BUX-client-Italy-IT.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-Belgium-EN-20190701.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-Belgium-NL-20190701.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-Belgium-FR-20190701.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-France-FR-20191109.pdf',
    '/static/documents/Client-agreement-AACB-BUX-Client-France-EN-20191109.pdf',
    '/static/documents/Fiche-d-information-pre-contractuelle-AACB-BUX-FR-FR-20191107.pdf',
    '/static/documents/Pre-contractual-information-AACB-BUX-FR-EN-20191107.pdf',
  ];

  const localURL = url.replace(/^https:\/\/getbux.com/, '/static');
  if (agreementsLocalURLs.includes(localURL)) {
    return localURL;
  }
  return undefined;
};
