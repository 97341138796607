import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

const ArrowDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 20" fill="none">
      <path d="M2 8L6.5 12L11 8" fill="none" strokeWidth="2" />
    </SvgIcon>
  );
};

export default ArrowDownIcon;
