import React, { useEffect } from 'react';
import FeedbackLayout, { ContentWrapper } from '../../../../common/components/FeedbackLayout';
import { Graphic } from '../../../../common/components';
import confirmed from '../../../../../assets/images/confirmed.svg';
import { Typography } from '../../../../theme/components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getExistingApplication } from '../../../store';

const VerificationSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      dispatch(getExistingApplication());
    }, 2500);
  }, []);

  return (
    <FeedbackLayout>
      <ContentWrapper marginBottom={0}>
        <Graphic image={confirmed} />
        <Typography variant="h2" gutterBottom>
          {t('phone_verification_success')}
        </Typography>
      </ContentWrapper>
    </FeedbackLayout>
  );
};

export default VerificationSuccess;
