import styled from '@emotion/styled';
import { FC } from 'react';

interface StyledProps {
  image: string;
}

export const GraphicContainer = styled.div<StyledProps>`
  height: 160px;
  width: 160px;
  ${({ theme }) => `@media (max-width: ${theme.breakpoint.md}px)`} {
    height: 150px;
    width: 150px;
  }
  background: url(${(props: StyledProps) => props.image}) no-repeat;
  background-size: contain;
`;

interface IGraphic {
  image: string;
}

export const Graphic: FC<IGraphic> = ({ image }) => {
  return <GraphicContainer image={image} data-testid={'graphic'}></GraphicContainer>;
};
