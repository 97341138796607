import { OnboardingSteps } from '../../../onboarding/models/onboarding.models';
import {
  AddressSteps,
  continueOnAppSteps,
  documentsSteps,
  emailSteps,
  financeDetailsSteps,
  personalDetailsSteps,
  phoneSteps,
  proofOfIdSteps,
  selfieSteps,
  signedAgreementStep,
  transactionTaxSteps,
} from '../../../onboarding/components/onboardingSequence';
import FormIcon from './icons/FormIcon';
import { IIconProps } from './icons/IconPropsType';
import { FC } from 'react';
import EnvelopeIcon from './icons/EnvelopeIcon';
import PersonIcon from './icons/PersonIcon';
import HouseIcon from './icons/HouseIcon';
import DownloadIcon from './icons/DownloadIcon';
import ProofIdIcon from './icons/ProofIdIcon';
import QuestionnaireIcon from './icons/QuestionnaireIcon';
import PhoneIcon from './icons/PhoneIcon';
import { find } from 'lodash';

export interface IProgressStepConfig {
  id: string;
  name: string;
  svgIcon: FC<IIconProps>;
  subSteps: number;
  steps: Array<{ step: OnboardingSteps }>;
}

export interface IProgressPartConfig {
  partName: string;
  partDescription?: string;
  partDuration: number;
  items: IProgressStepConfig[];
}

const getProgressPartsConfig = (): IProgressPartConfig[] => [
  {
    partName: 'getting_started',
    partDescription: '',
    partDuration: 3,
    items: [
      {
        id: 'documents',
        name: 'progress_agreements_step',
        svgIcon: FormIcon,
        subSteps: documentsSteps.length,
        steps: documentsSteps,
      },
      {
        id: 'email',
        name: 'progress_email_step',
        svgIcon: EnvelopeIcon,
        subSteps: emailSteps.length,
        steps: emailSteps,
      },
    ],
  },
  {
    partName: 'personal_details',
    partDescription: '',
    partDuration: 3,
    items: [
      {
        id: 'personal_details',
        name: 'progress_personal_details_step',
        svgIcon: PersonIcon,
        subSteps: personalDetailsSteps.length,
        steps: personalDetailsSteps,
      },
      {
        id: 'address',
        name: 'progress_address',
        svgIcon: HouseIcon,
        subSteps: AddressSteps.length,
        steps: AddressSteps,
      },
    ],
  },
  {
    partName: 'id_check',
    partDescription: 'required_regs_desc',
    partDuration: 5,
    items: [
      {
        id: 'proof_of_id',
        name: 'proof_of_id_title',
        svgIcon: ProofIdIcon,
        subSteps: proofOfIdSteps.length,
        steps: proofOfIdSteps,
      },
      {
        id: 'transaction_and_tax',
        name: 'transaction_taxes_title',
        svgIcon: FormIcon,
        subSteps: transactionTaxSteps.length,
        steps: transactionTaxSteps,
      },
    ],
  },
  {
    partName: 'investing_experience',
    partDescription: '',
    partDuration: 2,
    items: [
      {
        id: 'questionnaire',
        name: 'questionnaire_title',
        svgIcon: QuestionnaireIcon,
        subSteps: financeDetailsSteps.length,
        steps: financeDetailsSteps,
      },
      {
        id: 'selfie',
        name: 'verification_title',
        svgIcon: ProofIdIcon,
        subSteps: selfieSteps.length,
        steps: selfieSteps,
      },
    ],
  },
  {
    partName: 'confirmations_title',
    partDescription: '',
    partDuration: 3,
    items: [
      {
        id: 'phone',
        name: 'phone_title',
        svgIcon: PhoneIcon,
        subSteps: phoneSteps.length,
        steps: phoneSteps,
      },
      {
        id: 'signed_agreements',
        name: 'progress_agreements_step',
        svgIcon: FormIcon,
        subSteps: signedAgreementStep.length,
        steps: signedAgreementStep,
      },
      {
        id: 'download',
        name: 'progress_download',
        svgIcon: DownloadIcon,
        subSteps: continueOnAppSteps.length,
        steps: continueOnAppSteps,
      },
    ],
  },
];

const findPart = (progressParts: IProgressPartConfig[], currentStepName: string) => {
  return find(progressParts, { items: [{ steps: [{ step: { key: currentStepName } }] }] });
};

export { getProgressPartsConfig, findPart };
