import React, { useContext, useEffect } from 'react';
import { CircularProgress } from '../../theme/components';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../Store';
import { LoadingContainer, ProgressBar } from '../../common/components';
import { OnboardingSteps } from '../models/onboarding.models';
import { getExistingApplication } from '../store';
import {
  AgreementsStep,
  ContinueOnAppStep,
  EmailCaptureStep,
  TaxNumberStep,
  TransactionStep,
  WaitForEmailVerificationStep,
} from './steps';
import useStepTracking from '../helpers/useStepTracking';
import { ErrorFallback } from '../../common/components/errors';
import PersonalDetailsProvider from './steps/personaldetails/PersonalDetailsProvider';
import { AppContext } from '../../context/AppContext';
import { Route, Switch } from 'react-router-dom';
import StepRoute from './StepRoute';
import { BOS_MAINTENANCE_ERROR, BOS_VALIDATION_ERROR, ONFIDO_ERROR } from '../../common/constants';
import { getArrayPathsWithLocale, getPathWithLocale } from '../helpers/pathsWithLocales';
import { personalDetailsStepsWithAddress } from './onboardingSequence';
import { ProofOfIdStep } from './steps/proof-of-id/ProofOfIdStep';
import WaitingVerification from './steps/proof-of-id/WaitingVerification';
import FinanceDetailsStep from './steps/finance-details/FinanceDetailsStep';
import SelfieStep from './steps/selfie/SelfieStep';
import SelfieVerification from './steps/selfie/SelfieVerification';
import AddressVerification from './steps/address-verification/AddressVerificationStep';
import PhoneNumberStep from './steps/phone-verification/PhoneNumberStep';
import PhoneVerificationStep from './steps/phone-verification/PhoneVerificationStep';
import VerificationSuccess from './steps/phone-verification/VerificationSuccess';
import SignedAgreementStep from './steps/signed-agreements/SignedAgreementStep';

export const Onboarding: React.FC = () => {
  const dispatch = useDispatch();
  const {
    currentStep: { step },
    error,
  } = useSelector((state: IRootState) => state.onboarding);
  const { applicationInProgressOnInit } = useContext(AppContext);
  useEffect(() => {
    applicationInProgressOnInit && dispatch(getExistingApplication());
  }, [applicationInProgressOnInit]);

  useStepTracking(step);

  return (
    <>
      {!(error && error.errorCode !== BOS_VALIDATION_ERROR) && step && <ProgressBar currentStep={step.toString()} />}
      <Switch>
        {error && error.errorCode === BOS_MAINTENANCE_ERROR && <Route component={ErrorFallback} />}
        {error && error.errorCode === ONFIDO_ERROR && <Route component={ErrorFallback} />}
        <Route path={getPathWithLocale(OnboardingSteps.NOT_STARTED.path)}>
          {error ? (
            <Route component={ErrorFallback} />
          ) : (
            <LoadingContainer data-testid="loading-view">
              <CircularProgress />
            </LoadingContainer>
          )}
        </Route>
        <StepRoute
          path={getPathWithLocale(OnboardingSteps.AGREEMENTS.path)}
          component={AgreementsStep}
          currentStep={OnboardingSteps.AGREEMENTS}
          nextStep={OnboardingSteps.EMAIL}
        />

        <Route path={getPathWithLocale(OnboardingSteps.EMAIL.path)} component={EmailCaptureStep} exact={true} />
        <Route path={getPathWithLocale(OnboardingSteps.VERIFY_EMAIL.path)} component={WaitForEmailVerificationStep} />
        <Route path={getArrayPathsWithLocale(personalDetailsStepsWithAddress.map((s) => s.step.path))}>
          <PersonalDetailsProvider>
            {personalDetailsStepsWithAddress.map(({ step, component }, k) => (
              <StepRoute
                path={getPathWithLocale(step.path)}
                component={component}
                previousStep={personalDetailsStepsWithAddress[k - 1]?.step}
                currentStep={step}
                nextStep={personalDetailsStepsWithAddress[k + 1]?.step || OnboardingSteps.PROOF_OF_ID}
                key={k}
              />
            ))}
          </PersonalDetailsProvider>
        </Route>
        <Route path={getPathWithLocale(OnboardingSteps.PROOF_OF_ID.path)} component={ProofOfIdStep} exact={true} />
        <Route
          path={getPathWithLocale(OnboardingSteps.PROOF_OF_ID_VERIFICATION.path)}
          component={WaitingVerification}
        />
        <Route
          path={getPathWithLocale(OnboardingSteps.TRANSACTION_REPORTING.path)}
          component={TransactionStep}
          exact={true}
        />
        <Route path={getPathWithLocale(OnboardingSteps.TAX_NUMBER.path)} component={TaxNumberStep} exact={true} />
        <Route
          path={getPathWithLocale(OnboardingSteps.FINANCE_DETAILS.path)}
          component={FinanceDetailsStep}
          exact={true}
        />
        <Route path={getPathWithLocale(OnboardingSteps.SELFIE.path)} component={SelfieStep} exact={true} />
        <Route path={getPathWithLocale(OnboardingSteps.SELFIE_VERIFICATION.path)} component={SelfieVerification} />
        <Route path={getPathWithLocale(OnboardingSteps.ADDRESS_VERIFICATION.path)} component={AddressVerification} />

        <Route path={getPathWithLocale(OnboardingSteps.PHONE_NUMBER.path)} component={PhoneNumberStep} exact={true} />
        <Route path={getPathWithLocale(OnboardingSteps.PHONE_VERIFICATION.path)} component={PhoneVerificationStep} />
        <Route
          path={getPathWithLocale(OnboardingSteps.PHONE_VERIFICATION_SUCCESS.path)}
          component={VerificationSuccess}
        />

        <Route path={getPathWithLocale(OnboardingSteps.SIGNED_AGREEMENTS.path)} component={SignedAgreementStep} />

        <Route path={getPathWithLocale(OnboardingSteps.CONTINUE_ON_APP.path)} component={ContinueOnAppStep} />
        <Route component={ErrorFallback} />
      </Switch>
    </>
  );
};

export default Onboarding;
