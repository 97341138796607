export interface ILinks {
  _links: { next: { href: string } };
}

export interface IApplicationResponse extends ILinks {
  product: string;
  completed: boolean;
}

export class OnboardingSteps {
  static readonly NOT_STARTED = new OnboardingSteps('NOT_STARTED', '/welcome', '', 'landing_page');
  static readonly AGREEMENTS = new OnboardingSteps(
    'AGREEMENTS',
    '/agreements',
    'applications/current/agreements',
    'documents',
  );
  static readonly EMAIL = new OnboardingSteps(
    'EMAIL',
    '/email',
    'applications/current/personal_details/email-verification',
    'email_entry',
  );
  static readonly VERIFY_SOCIAL_NETWORK = new OnboardingSteps(
    'VERIFY_SOCIAL',
    '/email',
    'applications/current/personal_details/social-network-verification',
    'email_entry',
  );
  static readonly VERIFY_EMAIL = new OnboardingSteps(
    'VERIFY_EMAIL',
    '/email/verify',
    'applications/current/personal_details/email-verification',
    'email_confirmation',
  );
  static readonly COUNTRY_OF_RESIDENCE = new OnboardingSteps(
    'COUNTRY_OF_RESIDENCE',
    '/personal_details/country_of_residence',
    'applications/current/personal_details',
    'country_of_residence',
  );
  static readonly NATIONALITY = new OnboardingSteps(
    'NATIONALITY',
    '/personal_details/nationality',
    'applications/current/personal_details',
    'nationality',
  );
  static readonly DUAL_NATIONALITY = new OnboardingSteps(
    'DUAL_NATIONALITY',
    '/personal_details/dual_nationality',
    'applications/current/personal_details',
    'dual_nationality',
  );
  static readonly FULLNAME = new OnboardingSteps(
    'FULLNAME',
    '/personal_details/fullname',
    'applications/current/personal_details',
    'fullname',
  );
  static readonly GENDER = new OnboardingSteps(
    'GENDER',
    '/personal_details/gender',
    'applications/current/personal_details',
    'gender',
  );
  static readonly DATE_OF_BIRTH = new OnboardingSteps(
    'DATE_OF_BIRTH',
    '/personal_details/date_of_birth',
    'applications/current/personal_details',
    'date_of_birth',
  );
  static readonly PLACE_OF_BIRTH = new OnboardingSteps(
    'PLACE_OF_BIRTH',
    '/personal_details/place_of_birth',
    'applications/current/personal_details',
    'place_of_birth',
  );
  static readonly ADDRESS = new OnboardingSteps(
    'ADDRESS',
    '/personal_details/address',
    'applications/current/addresses',
    'current_address',
  );
  static readonly PROOF_OF_ID = new OnboardingSteps(
    'proof_of_id',
    '/photo_id',
    'applications/current/proof_of_id',
    'proof_of_id',
  );
  static readonly PROOF_OF_ID_VERIFICATION = new OnboardingSteps(
    'proof_of_id_verification',
    '/photo_id/verify',
    'applications/current/proof_of_id',
    'proof_of_id_verification',
  );
  static readonly TRANSACTION_REPORTING = new OnboardingSteps(
    'transaction_reporting',
    '/document_details',
    'applications/current/transaction_reporting',
    'transaction_reporting',
  );
  static readonly TAX_NUMBER = new OnboardingSteps(
    'tax_number',
    '/tax_number',
    'applications/current/tax_number',
    'tax_number',
  );
  static readonly FINANCE_DETAILS = new OnboardingSteps(
    'financial_details',
    '/questionnaire',
    'applications/current/financial_details',
    'finance_questions',
  );
  static readonly SELFIE = new OnboardingSteps('live_selfie', '/photo', 'applications/current/live_selfie', 'selfie');
  static readonly SELFIE_VERIFICATION = new OnboardingSteps(
    'live_selfie_verification',
    '/photo/verify',
    'applications/current/live_selfie',
    'selfie_verification',
  );
  static readonly ADDRESS_VERIFICATION = new OnboardingSteps(
    'proof_of_address',
    '/address/verify',
    'applications/current/proof_of_address',
    'proof_of_address',
  );
  static readonly PHONE_NUMBER = new OnboardingSteps(
    'phone_number',
    '/phone',
    'applications/current/personal_details/phone_number',
    'phone_number',
  );
  static readonly PHONE_VERIFICATION = new OnboardingSteps(
    'phone_verification',
    '/phone/verify',
    'applications/current/phone_verification',
    'phone_verification',
  );
  static readonly PHONE_VERIFICATION_SUCCESS = new OnboardingSteps(
    'phone_verification_success',
    '/phone/success',
    'applications/current/phone_verification',
    'phone_verification_success',
  );
  static readonly SIGNED_AGREEMENTS = new OnboardingSteps(
    'signed_agreements',
    '/signed_agreements',
    'applications/current/signed_agreements',
    'signed_agreements',
  );
  static readonly CONTINUE_ON_APP = new OnboardingSteps(
    'CONTINUE_ON_APP',
    '/continue_on_app',
    'applications/current/xxx',
    'account_creation_confirmation',
  );

  private constructor(
    private readonly key: string,
    readonly path: string,
    readonly href: string,
    readonly trackingPage: string,
  ) {}
  toString() {
    return this.key;
  }
}

export interface IAgreements {
  accepted: boolean;
  optional: boolean;
  type: string;
  url: string;
}
export interface IOnboardingAgreements {
  agreements: IAgreements[];
}
export interface ISubmitApplicationPayload {
  href: string;
  payload: unknown;
  method?: 'put' | 'post';
}

export interface IEmailVerificationResponse extends ILinks {
  verified: boolean;
  _inProgress: boolean;
  email: string;
}

export interface IEmailCapturePayload extends Partial<IEmailVerificationResponse> {
  _config?: {
    socialNetworkSignUpUrl?: string | null;
  };
}

export interface INationalities {
  suggested: string[];
  other: string[];
}

export interface IPersonalDetailsConfig {
  countriesOfBirth: string[];
  countriesOfResidence: string[];
  nationalities: INationalities;
}

export interface IPersonalDetails {
  email: string;
  _config: IPersonalDetailsConfig;
}

export interface IAddressConfig {
  type: string;
  lookup: boolean;
  postcodeValidationRule: string;
  postcodeKeyboardType: string;
  postcodeExample?: string;
  cityExample?: string;
}

export interface IAddressStepPayload {
  _links: { previous: { href: string } };
  _config: IAddressConfig;
}

export interface IProofIdStepPayload {
  countryOfResidence: string;
  nationality: string;
  onfidoId: string;
  onfidoSdkToken: string;
  onfidoWebToken: string;
  _inProgress: boolean;
}

export interface ISelfieStepPayload {
  onfidoId: string;
  onfidoSdkToken: string;
  onfidoWebToken: string;
  _inProgress: boolean;
}

export interface IVerificationPayload {
  _inProgress: boolean;
}

export enum KeyBoardType {
  NUMERIC = 'NUMERIC',
  ALPHANUMERIC = 'ALPHANUMERIC',
  NUMERIC_PUNCTUATION = 'NUMERIC_PUNCTUATION',
}

export enum IdentityType {
  PASSPORT = 'PASSPORT',
  NIN = 'NIN',
  SOSE = 'SOSE',
  TXID = 'TXID',
}

export interface ITransactionsStepOption {
  displayName: string;
  example: string;
  keyboardType: KeyBoardType;
  skippable: boolean;
  type: string;
  validationRule: string;
}

export interface ITransactionValues {
  identityNumberType: string;
  identityNumber: string;
}

export interface ITaxValues {
  taxNumber: string;
  secondaryTaxNumber?: {
    taxNumberDomicile?: string;
    taxNumberValue?: string;
  };
}

export interface ITransactionsStepPayload {
  _config: {
    options: ITransactionsStepOption[];
  };
  skippedIdentityNumberTypes: string[];
  identityNumber: string;
  identityNumberType: IdentityType;
}

export interface ITaxStepConfiguration {
  validationRule: string;
  example: string;
  displayName: string;
  keyboardType: KeyBoardType;
  skippable: boolean;
}

export interface ITaxStepPayload {
  _config: ITaxStepConfiguration;
}

export interface ITopicOptionsConfig {
  id: string;
  manual: boolean;
  displayName?: string;
  displayNameTranslateKey?: string;
  range?: {
    currency: string;
    decimals: number;
    high?: string;
    low?: string;
  };
  detailedInformation?: string;
  maxLength?: number;
}

export interface IFinanceDetailsConfig {
  topicOptions: {
    NET_WORTH: ITopicOptionsConfig[];
    TYPE_OF_INVESTOR: unknown[]; //deprecated
    OWNER_DIRECTOR_SECTOR: ITopicOptionsConfig[];
    SOURCE_OF_FUNDS: ITopicOptionsConfig[];
    EXPECTED_INVESTED_FUNDS: ITopicOptionsConfig[];
  };
}

export interface IFinanceFields {
  netWorth?: string;
  ownerDirectorSector?: string;
  sourceOfFunds?: string;
  expectedInvestedFunds?: string;
  investedInShares?: boolean;
  understandsLosingFunds?: boolean;
  understandsBalanceChanges?: boolean;
  acceptsRisk?: boolean;
}

export interface IFinanceDetailsPayload extends IFinanceFields {
  _config: IFinanceDetailsConfig;
}

export interface IphoneNumberPayload {
  _config?: {
    countryOfResidence: string;
  };
  phoneNumber?: string;
}

export interface IphoneVerificationPayload {
  _config?: {
    phoneNumberEditable: boolean;
  };
  phoneNumber?: string;
  numberOfSmsLeft?: number;
  smsSentAndNotExpired?: boolean;
  _inProgress: boolean;
}

export interface IAgreementData {
  type: string;
  url: string;
  localUrl?: string;
  accepted: boolean;
}

export interface ISignedAgreementsPayload {
  agreements: IAgreementData[];
  _config: {
    countryOfResidence: string;
  };
}

/*
  Onboarding backend sends the type of address based on the residential address of the user.
  Spain is supported, however, ES_BASED is *not* sent, and DE_BASED is sent as an alternative.
 */

export enum AddressTypes {
  NL_BASED = 'NL_BASED',
  GB_BASED = 'GB_BASED',
  DE_BASED = 'DE_BASED',
  FR_BASED = 'FR_BASED',
  IT_BASED = 'IT_BASED',
  IE_BASED = 'IE_BASED',
  DEFAULT = 'DEFAULT',
}

export enum AddressFieldTypes {
  STREET = 'street',
  NUMBER = 'number',
  NUMBER_ADDITION = 'numberAddition',
  BUILDING = 'building',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  PROVINCE = 'province',
  COUNTY = 'county',
}
