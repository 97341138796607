import { TFunction } from 'react-i18next';
import { noSpecialCharsValidator, regexValidator, requiredValidator } from './index';

const transactionCodeValidator =
  (t: TFunction<'translation'>, inputExample?: string, inputValidationRule?: string) => (value: any) => {
    return (
      requiredValidator(t)(value) ||
      noSpecialCharsValidator(t)(value) ||
      (inputValidationRule && regexValidator(t, inputValidationRule, inputExample)(value))
    );
  };

export { transactionCodeValidator };
