import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { group3 } from '../animation-timeline-helper';
import * as Sentry from '@sentry/react';

export interface ILineProps {
  x1Coord: number;
  x2Coord: number;
  yCoord: number;
  isCompleted: boolean;
  isJustCompleted: boolean;
  id: string;
}

type ColoredLineProgressStyledType = {
  isCompleted: boolean;
  isJustCompleted: boolean;
  strokeDashOffset: number;
};

const animateLine = (strokeDashOffset: number) => keyframes`
  from {
   stroke-dashoffset: ${strokeDashOffset};
  }
  to {
   stroke-dashoffset: 0;
  }
`;

const ColoredLineProgress = styled.line<ColoredLineProgressStyledType>`
  stroke: ${(props) =>
    props.isCompleted || props.isJustCompleted ? props.theme.color.amaranth : props.theme.color.black40};
  animation:  ${(props) =>
    props.isJustCompleted
      ? css`
          ${animateLine(props.strokeDashOffset)} ${group3.length}s ${group3.delay}s ease-in-out forwards;
        `
      : undefined};
  }
`;

const NonColoredLineProgress = styled.line`
  stroke: ${(props) => props.theme.color.black40};
`;

const ProgressLine: FC<ILineProps> = ({ id, x1Coord, x2Coord, yCoord, isCompleted, isJustCompleted }) => {
  const lineRef = useRef<SVGLineElement>(null);
  let lineLength = 0;
  if (lineRef && lineRef.current) {
    try {
      lineLength = lineRef.current.getTotalLength();
    } catch (e) {
      Sentry.captureException(e);
    }
    lineRef.current.style.strokeDasharray = lineLength.toString();
    if (isJustCompleted) {
      lineRef.current.style.strokeDashoffset = lineLength.toString();
    } else if (isCompleted) {
      lineRef.current.style.strokeDashoffset = '0';
    }
  }
  return (
    <>
      <NonColoredLineProgress
        ref={lineRef}
        id={`${id}-line`}
        x1={x1Coord}
        y1={yCoord}
        x2={x2Coord}
        y2={yCoord}
        fill="none"
        strokeWidth="1"
      />
      <ColoredLineProgress
        ref={lineRef}
        id={`${id}-line`}
        x1={x1Coord}
        y1={yCoord}
        x2={x2Coord}
        y2={yCoord}
        fill="none"
        strokeWidth="1"
        isJustCompleted={isJustCompleted}
        isCompleted={isCompleted}
        strokeDashOffset={lineLength}
      />
    </>
  );
};

export default ProgressLine;
