import React, { useCallback, useEffect } from 'react';
import { ButtonsContainer } from '../../common/components/FormLayout';
import FeedbackLayout, { Content, ContentWrapper, Spacer } from '../../common/components/FeedbackLayout';
import { AppBar, Button, CircularProgress, Typography } from '../../theme/components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { LanguageSwitcher, LoadingContainer } from '../../common/components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../Store';
import { useRudderAnalytics } from '../../../config/rudderstack';
import { Graphic } from '../../common/components/Graphic';
import { startUserApplication } from '../store';
import { registerUser, setApplicationStarted } from '../../auth/store';

export const Welcome: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const rudderAnalytics = useRudderAnalytics();
  const { applicationStarted, loading } = useSelector((state: IRootState) => state.auth);

  useEffect(() => {
    dispatch(registerUser());
  }, []);

  useEffect(() => {
    if (!applicationStarted && rudderAnalytics) {
      rudderAnalytics && rudderAnalytics.page('web_onboarding', 'landing_page');
    }
  }, [applicationStarted, rudderAnalytics]);

  const onClickContinue = useCallback(() => {
    dispatch(setApplicationStarted(true));
    dispatch(startUserApplication());
  }, []);

  return !loading ? (
    <>
      {isMobile && (
        <AppBar>
          <span style={{ flex: 1 }} />
          <LanguageSwitcher />
        </AppBar>
      )}
      <FeedbackLayout>
        <Spacer />
        <ContentWrapper>
          <Typography variant="h2" gutterBottom>
            {t('welcome_title')}
          </Typography>
          <Content>
            <Typography gutterBottom>{t('welcome_descriptive_text')}</Typography>
            <Typography variant={'caption'} gutterBottom>
              {t('welcome_note')}
            </Typography>
          </Content>
        </ContentWrapper>
        <ButtonsContainer alignCenter={!isMobile}>
          <Button onClick={onClickContinue} fullWidth={isMobile} id="web-onboarding-register">
            {t('common_continue')}
          </Button>
        </ButtonsContainer>
        <Spacer disableMinHeight />
      </FeedbackLayout>
    </>
  ) : (
    <LoadingContainer data-testid="loading-view">
      <CircularProgress />
    </LoadingContainer>
  );
};

export default Welcome;
