import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { LoadingContainer } from '../../../../common/components';
import { CircularProgress } from '../../../../theme/components';
import PhoneNumberForm from './PhoneNumberForm';
import { IphoneNumberPayload, OnboardingSteps } from '../../../models/onboarding.models';
import { submitFormDataForOnboardingStep } from '../../../store';

export interface IPhoneNumber {
  phoneNumber: string;
}

const PhoneNumberStep = () => {
  const dispatch = useDispatch();
  const {
    currentStep: { payload },
    error,
    loading: isLoading,
  } = useSelector((state: IRootState) => state.onboarding);

  const phoneStepPayload = payload as IphoneNumberPayload;

  const onSubmitPhoneNumber = useCallback((value: IPhoneNumber) => {
    dispatch(
      submitFormDataForOnboardingStep({
        href: OnboardingSteps.PHONE_NUMBER.href,
        payload: {
          phoneNumber: value.phoneNumber,
        },
      }),
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <PhoneNumberForm
          onSubmit={onSubmitPhoneNumber}
          error={error}
          isLoading={isLoading}
          payload={phoneStepPayload}
        />
      )}
    </>
  );
};

export default PhoneNumberStep;
