import { AddressFieldTypes, AddressTypes } from '../modules/onboarding/models/onboarding.models';

/***
Configure the address fields shown to the user form based on the user's selected residential country.
 The order of the fields in the array is the order of how the fields are displayed.
***/

const countryAddressConfig: { [key in AddressTypes]?: any } = {
  [AddressTypes.FR_BASED]: [
    AddressFieldTypes.STREET,
    AddressFieldTypes.NUMBER,
    AddressFieldTypes.NUMBER_ADDITION,
    AddressFieldTypes.BUILDING,
    AddressFieldTypes.POSTAL_CODE,
    AddressFieldTypes.CITY,
  ],
  [AddressTypes.IE_BASED]: [
    AddressFieldTypes.STREET,
    AddressFieldTypes.BUILDING,
    AddressFieldTypes.CITY,
    AddressFieldTypes.POSTAL_CODE,
  ],
  [AddressTypes.DE_BASED]: [
    AddressFieldTypes.STREET,
    AddressFieldTypes.NUMBER,
    AddressFieldTypes.CITY,
    AddressFieldTypes.POSTAL_CODE,
  ],
  [AddressTypes.NL_BASED]: [
    AddressFieldTypes.STREET,
    AddressFieldTypes.NUMBER,
    AddressFieldTypes.NUMBER_ADDITION,
    AddressFieldTypes.CITY,
    AddressFieldTypes.POSTAL_CODE,
  ],
  [AddressTypes.IT_BASED]: [
    AddressFieldTypes.STREET,
    AddressFieldTypes.NUMBER,
    AddressFieldTypes.POSTAL_CODE,
    AddressFieldTypes.CITY,
    AddressFieldTypes.PROVINCE,
  ],
  [AddressTypes.DEFAULT]: [
    AddressFieldTypes.STREET,
    AddressFieldTypes.NUMBER,
    AddressFieldTypes.NUMBER_ADDITION,
    AddressFieldTypes.CITY,
    AddressFieldTypes.POSTAL_CODE,
  ],
};

const customCountryAddressFormLabels: { [key in AddressTypes]?: { [key in AddressFieldTypes]?: string } } = {
  [AddressTypes.IE_BASED]: {
    [AddressFieldTypes.STREET]: 'address_form_address_line_1',
    [AddressFieldTypes.BUILDING]: 'address_form_address_line_2',
    [AddressFieldTypes.POSTAL_CODE]: 'address_form_eir_code',
  },
  [AddressTypes.IT_BASED]: {
    [AddressFieldTypes.NUMBER]: 'address_form_house_number',
  },
};

const customAddressFullWidth: { [key in AddressTypes]?: { [key in AddressFieldTypes]?: boolean } } = {
  [AddressTypes.IT_BASED]: {
    [AddressFieldTypes.NUMBER]: true,
    [AddressFieldTypes.POSTAL_CODE]: false,
    [AddressFieldTypes.CITY]: false,
  },
};

export { countryAddressConfig, customCountryAddressFormLabels, customAddressFullWidth };
