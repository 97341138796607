import { useEffect, useState } from 'react';

const UseLoadGoogleScript = (): [boolean, boolean] => {
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const [isScriptLoadError, setIsScriptLoadError] = useState<boolean>(false);

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => {
      setIsScriptLoaded(true);
    };
    scriptTag.onerror = () => {
      setIsScriptLoadError(true);
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);
  return [isScriptLoaded, isScriptLoadError];
};

export default UseLoadGoogleScript;
