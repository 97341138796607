import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { activeCircleStrokeLength, group1, group2, group4, group5 } from '../animation-timeline-helper';

export interface ICircleProps {
  xCoord: number;
  yCoord: number;
  smallRadius: number;
  bigRadius: number;
  isActive: boolean;
  isCompleted: boolean;
  currentSubStep: number | undefined;
  totalSubSteps: number;
  circumference: number;
  isJustCompleted: boolean;
  index: number;
  id: string;
}

type ColoredProgressStyledType = {
  isCompleted: boolean;
  isActive: boolean;
  circumference: number;
  isFirstStep: boolean;
  isJustCompleted: boolean;
  smallRadius: number;
  bigRadius: number;
  isFirstCircle: boolean;
};

type NonColoredProgressStyledType = {
  isActive: boolean;
  isJustCompleted: boolean;
  smallRadius: number;
  bigRadius: number;
  isFirstCircle: boolean;
};

const scaleUp = (smallRadius: number, bigRadius: number) => keyframes`
  0% {
   r: ${smallRadius}px
  }
  100% {
   r: ${bigRadius}px
  }
`;

const scaleDown = (smallRadius: number, bigRadius: number) => keyframes`
  from {
   r: ${bigRadius}px
  }
  to {
   r: ${smallRadius}px
  }
`;

const ColoredProgress = styled.circle<ColoredProgressStyledType>`
  transform: rotate(-183deg);
  transform-origin: center;
  transform-box: fill-box;
  will-change: transform;
  stroke: ${(props) => props.theme.color.amaranth};
  stroke-dashoffset: ${(props) => props.circumference};
  stroke-dasharray: ${(props) =>
    props.isActive || props.isJustCompleted ? `${props.circumference} ${props.circumference}` : undefined};
  transition: stroke-dashoffset ${activeCircleStrokeLength} ease-out;
  transition-delay: ${(props) =>
    props.isFirstCircle
      ? `${group1.delay}s`
      : props.isActive && props.isFirstStep
      ? `${group5.delay}s`
      : `${group1.delay}s`};
  animation: ${(props) =>
    props.isJustCompleted
      ? css`
          ${scaleDown(props.smallRadius, props.bigRadius)} ${group2.length}s ${group2.delay}s ease-in-out forwards
        `
      : props.isActive
      ? css`
          ${scaleUp(props.smallRadius, props.bigRadius)} ${group4.length}s ${props.isFirstCircle
            ? '0s'
            : `${group4.delay}s`} ease-in-out forwards
        `
      : undefined};
  animation-play-state: ${(props) => (props.isJustCompleted || props.isActive ? 'running' : 'paused')};
`;

const NonColoredProgress = styled.circle<NonColoredProgressStyledType>`
  stroke: ${(props) => props.theme.color.black40};
  will-change: transform;
  animation: ${(props) =>
    props.isJustCompleted
      ? css`
          ${scaleDown(props.smallRadius, props.bigRadius)} ${group2.length}s ${group2.delay}s ease-in-out forwards
        `
      : props.isActive
      ? css`
          ${scaleUp(props.smallRadius, props.bigRadius)} ${group4.length}s ${props.isFirstCircle
            ? '0s'
            : `${group4.delay}s`} ease-in-out forwards
        `
      : undefined};
  animation-play-state: ${(props) => (props.isJustCompleted || props.isActive ? 'running' : 'paused')};
`;

const ProgressCircle: FC<ICircleProps> = ({
  id,
  xCoord,
  yCoord,
  smallRadius,
  bigRadius,
  isActive,
  currentSubStep,
  isCompleted,
  totalSubSteps,
  circumference,
  isJustCompleted,
  index,
}) => {
  const showColored = isCompleted || isJustCompleted || isActive;
  const circleRef = useRef<SVGCircleElement>(null);

  if (circleRef && circleRef.current) {
    const currentSubStepNumber = currentSubStep !== undefined ? currentSubStep : 1;
    const extraStepForSpinner = 1;
    const percent = ((currentSubStepNumber + extraStepForSpinner) / (totalSubSteps + extraStepForSpinner)) * 100;
    if (percent < 101 && percent > -1) {
      const offset = ((100 - percent) / 100) * circumference;
      circleRef.current.style.strokeDashoffset = offset.toString();
    }

    if (isJustCompleted) {
      const offset = 0;
      circleRef.current.style.strokeDashoffset = offset.toString();
    }

    if (isActive) {
      circleRef.current.addEventListener('transitionend', () => {
        circleRef && circleRef.current ? (circleRef.current.style.transitionDelay = `${group1.delay}s`) : '';
      });
    }
  }

  return (
    <>
      <NonColoredProgress
        id={`${id}-circle`}
        cx={xCoord}
        cy={yCoord}
        r={isJustCompleted ? bigRadius : smallRadius}
        fill="white"
        strokeWidth="1"
        isActive={isActive}
        isJustCompleted={isJustCompleted}
        smallRadius={smallRadius}
        bigRadius={bigRadius}
        isFirstCircle={index === 0}
      />
      {showColored && (
        <ColoredProgress
          id={`${id}-circle-stroke`}
          ref={circleRef}
          cx={xCoord}
          cy={yCoord}
          r={isJustCompleted ? bigRadius : smallRadius}
          fill={'none'}
          isCompleted={isCompleted}
          isActive={isActive}
          circumference={circumference}
          strokeWidth="1"
          isFirstStep={currentSubStep ? currentSubStep === 0 : true}
          isJustCompleted={isJustCompleted}
          smallRadius={smallRadius}
          bigRadius={bigRadius}
          isFirstCircle={index === 0}
        />
      )}
    </>
  );
};

export default ProgressCircle;
