import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../../Store';
import { LoadingContainer } from '../../../../common/components';
import { CircularProgress } from '../../../../theme/components';
import PhoneVerificationForm from './PhoneVerificationForm';
import { IphoneVerificationPayload, OnboardingSteps } from '../../../models/onboarding.models';
import { gotoStep, submitPhoneVerification } from '../../../store';

export interface IPhoneToken {
  token: string;
}

const PhoneVerificationStep = () => {
  const dispatch = useDispatch();
  const {
    currentStep: { payload },
    error,
    loading: isLoading,
  } = useSelector((state: IRootState) => state.onboarding);

  const phoneVerificationStepPayload = payload as IphoneVerificationPayload;

  const handleBack = useCallback(() => {
    dispatch(gotoStep(OnboardingSteps.PHONE_NUMBER));
  }, []);

  const onSubmitCode = useCallback((value: IPhoneToken) => {
    dispatch(
      submitPhoneVerification({
        href: OnboardingSteps.PHONE_VERIFICATION.href,
        payload: {
          token: value.token,
        },
      }),
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingContainer data-testid="loading-view">
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <PhoneVerificationForm
          initialValues={{ token: '' }}
          onSubmit={onSubmitCode}
          error={error}
          handleBack={handleBack}
          isLoading={isLoading}
          payload={phoneVerificationStepPayload}
        />
      )}
    </>
  );
};

export default PhoneVerificationStep;
