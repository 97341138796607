import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const CheckboxCheckedIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect width="24" height="24" rx="4" fill={theme.palette.primary.main} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8266 9.53033C17.1195 9.23744 17.1195 8.76256 16.8266 8.46967C16.5337 8.17678 16.0588 8.17678 15.7659 8.46967L10.4624 13.7732L8.34088 11.6517C8.04798 11.3588 7.57311 11.3588 7.28022 11.6517C6.98732 11.9446 6.98732 12.4195 7.28022 12.7124L9.75509 15.1872C10.0847 15.5168 10.587 15.5682 10.9706 15.3415L10.993 15.364L16.8266 9.53033Z"
        fill={theme.palette.common.white}
      />
    </SvgIcon>
  );
};

export default CheckboxCheckedIcon;
