import { DialogActionsClassKey, StyleRules } from '@material-ui/core';
import { theme } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
const MuiDialogActions: Partial<StyleRules<DialogActionsClassKey, {}>> = {
  root: {
    padding: theme.spacing(6),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
};

export default MuiDialogActions;
