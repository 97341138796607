import React, { useContext } from 'react';
import { Field, Form, FormikProvider } from 'formik';
import { Typography, Button, RadioGroup, RadioWithLabel } from '../../../../theme/components';
import FormLayout, {
  Content as FormContent,
  Fieldset,
  FormFieldsWrapper,
  ButtonsContainer,
} from '../../../../common/components/FormLayout';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { genderValidator } from '../../../utils/validators';
import { useFormikWithErrors } from '../../../helpers';
import ChatWithSupport from '../../../../common/components/ChatWithSupport';
import { Error } from '.';
import { PersonalDetailsContext } from './PersonalDetailsProvider';
import { StepComponent } from '../../StepRoute';

export const GenderStep: React.FC<StepComponent> = ({ onPrevious, onNext, showPrevious = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { isLoading, error, initialValues, onSubmit } = useContext(PersonalDetailsContext);

  const { touched, errors, ...formik } = useFormikWithErrors({
    initialValues,
    onSubmit: onSubmit((values: any) => onNext(values)),
    showErrors: !!error && !isLoading,
    getErrors: () => {
      if (error && error.body) {
        const errors: any = {};
        Object.keys(error.body).forEach((key) => {
          errors[key] = t('common_error_please_check_field');
        });
        return errors;
      }
    },
  });

  return (
    <FormLayout>
      <FormContent marginTop={6}>
        <FormikProvider value={{ touched, errors, ...formik }}>
          <Form>
            <FormFieldsWrapper marginTop={3}>
              {error && <Error />}
              <Fieldset>
                <Typography variant="h4" component="legend" align="center">
                  {t('personal_details_gender_title')}
                </Typography>
                <Typography align="center" gutterBottom>
                  {t('personal_details_gender_description')}
                </Typography>
                <Field
                  component={RadioGroup}
                  name="gender"
                  aria-label="gender"
                  row={!isMobile}
                  validate={genderValidator(t)}
                  style={{ marginTop: 24 }}
                >
                  <RadioWithLabel value="male" label={t('personal_details_gender_male')} />
                  <RadioWithLabel value="female" label={t('personal_details_gender_female')} />
                </Field>
                {touched.gender && errors.gender && (
                  <Typography variant="caption" color="error">
                    {errors.gender}
                  </Typography>
                )}
              </Fieldset>
            </FormFieldsWrapper>
            <ButtonsContainer>
              {showPrevious && (
                <Button variant="secondary" onClick={onPrevious}>
                  {t('common_go_back')}
                </Button>
              )}
              <Button type="submit" loading={isLoading} id="web-onboarding-personal-details">
                {t('common_continue')}
              </Button>
            </ButtonsContainer>
          </Form>
        </FormikProvider>
        <Typography component="p" variant={isMobile ? 'body2' : 'subtitle1'} color="primary" style={{ marginTop: 36 }}>
          {t('common_have_questions')} <ChatWithSupport />
        </Typography>
      </FormContent>
    </FormLayout>
  );
};

export default GenderStep;
