import React, { useEffect, useState } from 'react';
import { Box, Typography } from '../../../../../../theme/components';
import FormLayout, { ButtonsContainer, Content as FormContent } from '../../../../../../common/components/FormLayout';
import { useTranslation } from 'react-i18next';
import { OnboardingSteps } from '../../../../../models/onboarding.models';
import { useDispatch, useSelector } from 'react-redux';
import { submitFormDataForOnboardingStep } from '../../../../../store';
import { IRootState } from '../../../../../../../Store';
import TextWithSeparator from '../../../../../../theme/components/TextWithSeparator';
import GoogleSignInButton from './GoogleSignin/GoogleSignInButton';
import { IGoogleSigninOnError, IGoogleSigninOnSuccess } from './GoogleSignin/types';
import GoogleSignInError from './GoogleSignin/GoogleSignInError';
import CommonEmailInput from '../CommonEmailInput';
import * as Sentry from '@sentry/react';
import { exchangeGoogleAuthCode } from '../../../../../api/onboarding.api';
import { SOCIAL_EMAIL_ALREADY_EXISTS } from '../../../../../../common/constants';
import EmailAlreadyExists from '../../EmailAlreadyExists';
import { useRudderAnalytics } from '../../../../../../../config/rudderstack';

interface IExhangeCodePayload {
  snToken: string;
  snJwt: string;
}

export const EmailSocialCaptureStep: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rudderAnalytics = useRudderAnalytics();
  const { error } = useSelector((state: IRootState) => state.onboarding);
  const [isGoogleSignInError, setIsGoogleSignInError] = useState(false);
  const [isGoogleSignInLoading, setIsGoogleSignInLoading] = useState(false);

  useEffect(() => {
    setIsGoogleSignInLoading(false);
  }, [error]);

  const googleSignInError: IGoogleSigninOnError = (error) => {
    Sentry.captureMessage(JSON.stringify(error));
    setIsGoogleSignInLoading(false);
    setIsGoogleSignInError(true);
  };

  const googleSignInSuccess: IGoogleSigninOnSuccess = async (response) => {
    setIsGoogleSignInLoading(true);
    const { code } = response || {};
    try {
      const { snToken, snJwt } = ((await exchangeGoogleAuthCode(code)) as IExhangeCodePayload) || {};
      if (snJwt && snToken) {
        rudderAnalytics && rudderAnalytics.track('onboarding_web_social_signup_google_success');
        dispatch(
          submitFormDataForOnboardingStep({
            href: OnboardingSteps.VERIFY_SOCIAL_NETWORK.href,
            payload: {
              snType: 'GOOGLE',
              snJwt,
              snToken,
            },
            method: 'post',
          }),
        );
      }
    } catch (error: any) {
      setIsGoogleSignInLoading(false);
      setIsGoogleSignInError(true);
    }
  };

  if (error && error.errorCode === SOCIAL_EMAIL_ALREADY_EXISTS) {
    return <EmailAlreadyExists />;
  } else {
    return (
      <FormLayout>
        <Typography variant="h2" gutterBottom={true}>
          {t('email_social_title')}
        </Typography>
        <FormContent>
          {(isGoogleSignInError || error) && <GoogleSignInError />}
          <ButtonsContainer marginTop={4} marginBottom={6} alignCenter>
            <GoogleSignInButton
              isLoading={isGoogleSignInLoading}
              onSuccess={googleSignInSuccess}
              onError={googleSignInError}
              isDisabled={isGoogleSignInError}
            />
          </ButtonsContainer>
          <Box marginTop={3} marginBottom={4} width="100%">
            <TextWithSeparator>{t('email_signup_text')}</TextWithSeparator>
          </Box>
          <CommonEmailInput featureFlagOn={true} />
        </FormContent>
      </FormLayout>
    );
  }
};
