import React, { useState } from 'react';
import { Button, TextField, Typography } from '../../../../theme/components';
import FormLayout, {
  ButtonsContainer,
  Content as FormContent,
  FormFieldsWrapper,
} from '../../../../common/components/FormLayout';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IErrorResponse } from '../../../../common/models';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ITaxStepConfiguration, ITaxValues, KeyBoardType } from '../../../models/onboarding.models';
import ResponsiveSimpleModal from '../../../../theme/components/ResponsiveSimpleModal';
import { taxNumberValidator } from '../../../utils/validators/taxNumberValidator';

interface ITaxStepFormProps {
  initialValues: ITaxValues;
  onSubmit: (values: ITaxValues) => void;
  onSkip?: () => void;
  error: IErrorResponse | null;
  isLoading: boolean;
  taxConfig: ITaxStepConfiguration;
}

const useStyles = makeStyles(() => ({
  inputStyle: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const TaxInputForm = ({
  initialValues,
  onSubmit,
  onSkip,
  error,
  isLoading,
  taxConfig,
}: React.PropsWithChildren<ITaxStepFormProps>) => {
  const { inputStyle } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const formik = useFormik({ initialValues, onSubmit });

  const [showSkipModal, setShowSkipModal] = useState(false);

  const handleShowSkipConfirmation = () => {
    setShowSkipModal(true);
  };

  const closeModal = () => {
    setShowSkipModal(false);
  };

  const confirmSkip = () => {
    onSkip && onSkip();
  };

  return (
    <>
      <FormLayout>
        <Typography variant="h2" gutterBottom={true}>
          {t('transactions_question', { transactionName: taxConfig.displayName })}
        </Typography>
        <FormContent>
          <FormikProvider value={formik}>
            <Form>
              <FormFieldsWrapper>
                {error && (
                  <>
                    <Typography color="error" variant="subtitle2">
                      {t('common_error_sorry_there_is_a_problem')}
                    </Typography>
                    <Typography color="error" variant="caption" component="p" gutterBottom>
                      {t('common_error_please_try_again')}
                    </Typography>
                  </>
                )}
                <Typography gutterBottom={true}>{t('transactions_disclaimer')}</Typography>
                <Field
                  className={inputStyle}
                  component={TextField}
                  type={taxConfig.keyboardType === KeyBoardType.NUMERIC ? 'tel' : 'text'}
                  name="taxNumber"
                  id="taxNumber"
                  placeholder={taxConfig.example}
                  validate={taxNumberValidator(t, taxConfig.example, taxConfig.validationRule)}
                  fullWidth={true}
                />
              </FormFieldsWrapper>
              <ButtonsContainer flexDirection="column" marginTop={isMobile ? 0 : 1}>
                <Button fullWidth={isMobile} type="submit" loading={isLoading}>
                  {t('common_continue')}
                </Button>
                {taxConfig.skippable && (
                  <Button
                    variant={isMobile ? 'secondary' : 'text'}
                    onClick={handleShowSkipConfirmation}
                    fullWidth={isMobile}
                  >
                    {t('tax_provide_later')}
                  </Button>
                )}
              </ButtonsContainer>
            </Form>
          </FormikProvider>
        </FormContent>
      </FormLayout>
      <ResponsiveSimpleModal
        open={showSkipModal}
        primaryButton={{ handle: confirmSkip, label: t('common_skip_confirm') }}
        secondaryButton={{ handle: closeModal, label: t('common_go_back_variation') }}
        handleClose={closeModal}
        title={t('tax_step_step')}
        subtitle={t('tax_step_skip_message')}
      />
    </>
  );
};

export default TaxInputForm;
