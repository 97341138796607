import {
  CheckboxWithLabel as FormikCheckboxWithLabel,
  CheckboxWithLabelProps as FormikCheckboxWithLabelProps,
} from 'formik-material-ui';
import React from 'react';

function CheckboxWithLabel({
  label,
  ...props
}: Omit<FormikCheckboxWithLabelProps, 'Label'> & { label: React.ReactText }) {
  return <FormikCheckboxWithLabel color="primary" {...props} Label={{ label }} />;
}

export default CheckboxWithLabel;
